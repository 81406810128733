/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { ChartTypeComponent } from './chart-type/chart-type.component';
import { TagsComponent } from './tags/tags.component';
import { TemplateCardComponent } from './template-card/template-card.component';
import { TemplateSearchComponent, TemplateSearchGridListComponent } from './template-search';

export const TEMPLATE_COMPONENTS = [
  ChartTypeComponent,
  TagsComponent,
  TemplateCardComponent,
  TemplateSearchComponent,
  TemplateSearchGridListComponent,
];
