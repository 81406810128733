<!--
  Possible keys
  ACCESS_LEVEL.OWNER
  ACCESS_LEVEL.READ
  ACCESS_LEVEL.FULL
  ACCESS_LEVEL.MANAGE
-->
<span
  *ngIf="accessLevel"
  [class.label]="displayLabel"
>
  {{ 'ACCESS_LEVEL.' + accessLevel | translate }}
</span>
