/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { MutationFetchPolicy } from '@apollo/client/core/watchQueryOptions';
import { GenericObject } from '@dpa/ui-common';
import { Apollo, ApolloBase, TypedDocumentNode } from 'apollo-angular';
import { DocumentNode } from 'graphql/language/ast';
import { catchError, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * GraphqlService
 *
 * @export
 * @class GraphqlService
 */
@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  private static readonly defaultFetchPolicy: MutationFetchPolicy = 'no-cache';
  private static readonly ERROR_POLICY_ALL = 'all';

  /**
   * Creates an instance of GraphqlService.
   * @param {Apollo} apolloProvider
   * @memberof GraphqlService
   */
  constructor(private apolloProvider: Apollo) {}

  /**
   * query
   * @param {DocumentNode | TypedDocumentNode<TData, TVariables>} query
   * @param {TVariables} [variables]
   * @param {string} [clientName]
   * @returns {Observable<TData>}
   * @memberof GraphqlService
   */
  public query<TData = GenericObject, TVariables = GenericObject>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    variables?: TVariables,
    clientName?: string,
  ): Observable<TData> {
    return this.getApollo(clientName)
      .query({
        query,
        variables,
        fetchPolicy: GraphqlService.defaultFetchPolicy,
        errorPolicy: GraphqlService.ERROR_POLICY_ALL,
      })
      .pipe(
        map((response: ApolloQueryResult<TData>) => response.data),
        catchError((error: GenericObject) => {
          throw this.getErrorsObj(error);
        }),
      );
  }

  /**
   * mutate
   * @param {DocumentNode} mutation
   * @param {GenericObject} variables
   * @param {string} clientName
   * @returns {Observable<GenericObject>}
   * @memberOf GraphqlService
   */
  public mutate(mutation: DocumentNode, variables: GenericObject, clientName?: string): Observable<GenericObject> {
    return this.getApollo(clientName)
      .mutate({
        mutation,
        variables,
        fetchPolicy: GraphqlService.defaultFetchPolicy,
      })
      .pipe(
        map((response: GenericObject) => response.data),
        catchError((error: GenericObject) => {
          throw this.getErrorsObj(error);
        }),
      );
  }

  /**
   * getApollo
   * @param {string} clientName
   * @returns {ApolloBase}
   * @memberOf GraphqlService
   */
  private getApollo(clientName?: string): ApolloBase {
    return this.apolloProvider.use(clientName ?? '_default');
  }

  /**
   * getErrorsObj
   * @param {GenericObject} err
   * @returns {GenericObject}
   * @memberOf GraphqlService
   */
  private getErrorsObj(err: GenericObject): GenericObject {
    const errors = err?.graphQLErrors?.[0]?.extensions?.debugInfo?.errorBody?.errors ?? [];
    return {
      error: {
        errors,
      },
    };
  }
}
