/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Category, IntegrationCategories } from '@ws1c/intelligence-models';

/**
 * Adds a header and description to the CategorySelectorDropdownComponent
 *
 * @export
 * @class CategorySelectorComponent
 */
@Component({
  selector: 'dpa-category-selector',
  templateUrl: 'category-selector.component.html',
  styleUrls: ['category-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategorySelectorComponent {
  @Input() public header: string;
  @Input() public description?: string;
  @Input() public tooltip?: string;
  @Input() public selectedCategory: Category;
  @Input() public integrationCategories: IntegrationCategories[];
  @Output() public selectedCategoryChange = new EventEmitter<Category>();
}
