/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { DateTimeFormat } from '@dpa/ui-common';
import { isNaN, isString, toNumber } from 'lodash-es';
import moment from 'moment';

/**
 * Pipe to check and format date or number
 * If date string is passed like '2021-03-15T09:20:21.495Z' it'll be converted based on passed format
 * If primitive number will be passed like 34123412, it'll be converted using number pipe like 34,123,412
 * otherwise return the passed value
 *
 * @export
 * @class CheckForDateOrNumberPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'checkForDateOrNumber' })
export class CheckForDateOrNumberPipe implements PipeTransform {
  public decimalPipe: DecimalPipe;

  /**
   * Initializes instance of CheckForDateOrNumberPipe
   * @param {string} locale - Translate Service instance
   * @memberof CheckForDateOrNumberPipe
   */
  constructor(@Inject(LOCALE_ID) private locale: string) {
    this.decimalPipe = new DecimalPipe(this.locale);
  }

  /**
   * transform
   * @param {string | number | Date} value
   * @param {string} format
   * @returns {string | number | Date}
   * @memberOf CheckForDateOrNumberPipe
   */
  public transform(value: string | number | Date, format: string = DateTimeFormat.MOMENT_DATE_FORMAT): string | number | Date {
    if (isString(value) && isNaN(toNumber(value)) && !isNaN(Date.parse(value as string))) {
      return moment(value).format(format);
    } else if (!isString(value) && !isNaN(toNumber(value))) {
      return this.decimalPipe.transform(value as number);
    }
    return value;
  }
}
