/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { Column, ColumnIndex, ColumnToggleFilter } from '@ws1c/intelligence-models';

/**
 * ColumnLabelPipe
 * @export
 * @class ColumnLabelPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'columnLabel' })
export class ColumnLabelPipe implements PipeTransform {
  /**
   * transform
   * @param {string} columnName
   * @param {ColumnIndex} allColumnsByName
   * @param {Record<string, ColumnToggleFilter>} columnToggleFilterMap
   * @returns {string}
   * @memberof ColumnLabel
   */
  public transform(columnName: string, allColumnsByName: ColumnIndex, columnToggleFilterMap: Record<string, ColumnToggleFilter>): string {
    const column: Column = allColumnsByName[columnName];
    if (Object.keys(columnToggleFilterMap ?? {}).includes(columnName)) {
      const normalizedColumnName: string =
        columnToggleFilterMap[column?.attribute.fullyQualifiedName]?.normalizedColumn.attribute.fullyQualifiedName;
      const normalizedColumnLabel: string = columnToggleFilterMap[column?.attribute.fullyQualifiedName]?.normalizedColumn?.label;
      return normalizedColumnLabel ?? normalizedColumnName;
    } else if (Object.keys(columnToggleFilterMap ?? {}).includes(column?.rawAttributeName)) {
      const normalizedColumnName: string = columnToggleFilterMap[column?.rawAttributeName]?.normalizedColumn.attribute.fullyQualifiedName;
      const normalizedColumnLabel: string = columnToggleFilterMap[column?.rawAttributeName]?.normalizedColumn?.label;
      return normalizedColumnLabel ?? normalizedColumnName;
    }
    return column?.label;
  }
}
