/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Directive, Input, TemplateRef } from '@angular/core';

import { HomePageSectionType } from '@ws1c/intelligence-models';

/**
 * Directive
 * @export
 * @class HomePageCommonDirective
 */
@Directive({
  selector: '[dpaHomePageSection]',
})
export class HomePageSectionDirective {
  @Input() public sectionIdentifier: HomePageSectionType;
  @Input() public sectionTitle: string;

  /**
   * Constructor
   * @param {TemplateRef<any>} templateRef
   * @memberOf HomePageSectionDirective
   */
  constructor(public templateRef: TemplateRef<any>) {}
}
