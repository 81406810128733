/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';

import { INTELLIGENCE_COMMON_PIPES } from './index';

/**
 * PipesModule
 * @exports PipesModule
 */
@NgModule({
  declarations: [INTELLIGENCE_COMMON_PIPES],
  exports: [INTELLIGENCE_COMMON_PIPES],
})
export class PipesModule {}
