<div
  [class.boxed]="isBoxed"
  [class.selected]="isSelected"
  [class.compact]="isCompact"
  [class.bordered]="hasBottomBorder"
  [class.clickable]="!actionsTemplate"
  [class.has-footer]="footerTemplate"
  [class.hoverable]="isHoverable"
  [attr.tabindex]="actionsTemplate ? '-1' : '0'"
  [attr.role]="actionsTemplate ? '' : 'button'"
  (click)="itemClick.emit()"
  (keyup.space)="itemClick.emit()"
  (keyup.enter)="itemClick.emit()"
  class="list-item ph-x2"
>
  <div class="icon-content">
    <cds-icon
      *ngIf="iconShape"
      [attr.shape]="iconShape"
      size="22"
      class="mr-x2"
    ></cds-icon>
    <div
      *ngIf="iconTemplate"
      class="mr-x2"
    >
      <ng-template [ngTemplateOutlet]="iconTemplate"></ng-template>
    </div>
  </div>
  <div class="header-content">
    <dpa-ellipsis-with-tooltip>
      <span
        [dpaInnerHtmlLight]="label"
        class="matching-text"
      ></span>
    </dpa-ellipsis-with-tooltip>
  </div>
  <div
    *ngIf="!isCompact"
    class="desc-content p3 mt-0"
  >
    <dpa-ellipsis-with-tooltip>
      <span [dpaInnerHtmlLight]="description"></span>
    </dpa-ellipsis-with-tooltip>
  </div>
  <div class="actions-content">
    <div
      *ngIf="actionsTemplate"
      class="pl-x2"
    >
      <ng-template
        [ngTemplateOutlet]="actionsTemplate"
        [ngTemplateOutletContext]="{ item: item }"
      ></ng-template>
    </div>
    <div
      *ngIf="arrowText"
      class="p5 mt-0"
    >
      {{ arrowText }}
    </div>
    <cds-icon
      *ngIf="hasArrow"
      shape="angle"
      direction="right"
      size="20"
      class="ml-x1"
    ></cds-icon>
  </div>
  <div
    *ngIf="footerTemplate"
    class="footer-content"
  >
    <ng-template
      [ngTemplateOutlet]="footerTemplate"
      [ngTemplateOutletContext]="{ item: item }"
    ></ng-template>
  </div>
</div>
