/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, ElementRef, Input, ViewChild } from '@angular/core';

/**
 * TooltipTagComponent
 * @export
 * @class TooltipTagComponent
 */
@Component({
  selector: 'dpa-tooltip-tag',
  templateUrl: 'tooltip-tag.component.html',
  styleUrls: ['tooltip-tag.component.scss'],
})
export class TooltipTagComponent {
  @ViewChild('tooltipTemplate') public tooltipTemplate: ElementRef;
  @Input() public labelText: string;
  @Input() public tooltipText: string;
  @Input() public iconSize?: number = 20;
  @Input() public iconShape?: string = 'info-circle';
}
