<clr-dropdown>
  <button
    clrDropdownTrigger
    [attr.aria-label]="'COMMON_ACTIONS.CARD_OPTIONS' | translate"
    class="btn-sm btn-link connection-ellipsis ph-0"
  >
    <cds-icon
      shape="ellipsis-horizontal"
      size="24"
    ></cds-icon>
  </button>
  <clr-dropdown-menu
    *clrIfOpen
    [clrPosition]="CLARITY_TOOLTIP_POSITION.TOP_RIGHT"
  >
    <ng-container *ngFor="let option of dropdownOptions">
      <button
        *ngIf="!option.isHidden"
        clrDropdownItem
        [disabled]="option.isDisabled"
        [ngClass]="option.textClass"
        (click)="option.action()"
      >
        {{ option.labelKey | translate }}
        <dpa-info-tooltip
          *ngIf="option.infoTooltipTextKey"
          [tooltipAriaLabel]="option.infoTooltipTextKey | translate"
          [tooltipText]="option.infoTooltipTextKey | translate"
        ></dpa-info-tooltip>
      </button>
    </ng-container>
  </clr-dropdown-menu>
</clr-dropdown>
