/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { ColumnToggleFilter } from '@ws1c/intelligence-models';

/**
 * CheckIsAttributeRawPipe
 * @export
 * @class CheckIsAttributeRawPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'isAttributeRaw' })
export class CheckIsAttributeRawPipe implements PipeTransform {
  /**
   * transform
   * @param {string} attributeName
   * @param {Record<string, ColumnToggleFilter>} [columnToggleFilterMap={}]
   * @returns {boolean}
   * @memberof CheckIsAttributeRawPipe
   */
  public transform(attributeName: string, columnToggleFilterMap: Record<string, ColumnToggleFilter> = {}): boolean {
    return Object.keys(columnToggleFilterMap).includes(attributeName);
  }
}
