/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { Subject } from 'rxjs';

import { AccordionWizardConfig } from '@ws1c/intelligence-common/models';

/**
 * AccordionWizardService
 * @export
 * @class AccordionWizardService
 */
@Injectable()
export class AccordionWizardService {
  public accordionWizardConfig: AccordionWizardConfig;
  public accordionWizardConfig$ = new Subject<AccordionWizardConfig>();
  public completedAccordionStep$ = new Subject<number>();

  /**
   * setAccordionWizardConfig
   * @param {AccordionWizardConfig} accordionWizardConfig
   */
  public setAccordionWizardConfig(accordionWizardConfig: AccordionWizardConfig) {
    this.accordionWizardConfig = accordionWizardConfig;
  }

  /**
   * resetSteps
   * @memberof AccordionWizardService
   * @param {number} stepCount
   */
  public resetSteps(stepCount: number) {
    const nextConfig = new AccordionWizardConfig();
    nextConfig.stepCount = stepCount;
    nextConfig.openStepIndices = new Set([0]);
    nextConfig.enabledStepIndices = new Set([0]);
    nextConfig.finishedStepIndices = new Set();
    this.accordionWizardConfig$.next(nextConfig);
  }

  /**
   * continueStepIndex
   * @param {number} stepIndex
   * @memberof AccordionWizardService
   */
  public continueStepIndex(stepIndex: number) {
    const nextConfig = cloneDeep(this.accordionWizardConfig);
    nextConfig.finishedStepIndices.add(stepIndex);
    nextConfig.openStepIndices = new Set([stepIndex + 1]);
    nextConfig.enabledStepIndices.add(stepIndex + 1);
    this.accordionWizardConfig$.next(nextConfig);
    this.completedAccordionStep$.next(stepIndex);
  }

  /**
   * toggleStepByIndex
   * @param {number} stepIndex
   * @memberof AccordionWizardService
   */
  public toggleStepByIndex(stepIndex: number) {
    const nextConfig = cloneDeep(this.accordionWizardConfig);
    if (!nextConfig.enabledStepIndices.has(stepIndex)) {
      return;
    }
    if (nextConfig.openStepIndices.has(stepIndex)) {
      nextConfig.openStepIndices.delete(stepIndex);
    } else {
      nextConfig.openStepIndices.add(stepIndex);
    }
    this.accordionWizardConfig$.next(nextConfig);
  }
}
