/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component, ContentChildren, TemplateRef } from '@angular/core';

import { TimelineContentDirective } from './timeline-content.directive';

/**
 * TimelineComponent
 * @exports
 * @class TimelineComponent
 */
@Component({
  selector: 'dpa-timeline',
  templateUrl: 'timeline.component.html',
  styleUrls: ['timeline.component.scss'],
})
export class TimelineComponent {
  @ContentChildren(TimelineContentDirective, { read: TemplateRef }) public templates: Array<TemplateRef<any>>;
}
