<div class="control-container">
  <div class="selection-type ph-x4 pv-x2">
    <div
      *ngIf="isCustomTemplate"
      class="selection-type-header custom-template"
    >
      <div>{{ 'WORKFLOWS.AVAILABLE_DATA_SOURCES' | translate: { count: customGroupCollection.groups.length } }}</div>
      <div
        *ngIf="showTemplates"
        dpaTabEvent
        (click)="onCustomTemplateToggle(false)"
        (keyup.enter)="onCustomTemplateToggle(false)"
        class="clickable nav-control mr-x2 text-blue"
      >
        <div class="explore-templates-label">{{ 'WORKFLOWS.EXPLORE_TEMPLATES' | translate }}</div>
        <cds-icon
          shape="arrow"
          direction="right"
          size="22"
        ></cds-icon>
      </div>
      <ng-container
        *ngIf="headerActionTemplate"
        [ngTemplateOutlet]="headerActionTemplate"
      ></ng-container>
    </div>
    <div
      *ngIf="!isCustomTemplate"
      class="selection-type-header predefined-template"
    >
      <div
        dpaTabEvent
        (click)="onCustomTemplateToggle(true)"
        (keyup.enter)="onCustomTemplateToggle(true)"
        class="nav-control"
      >
        <cds-icon
          shape="arrow"
          direction="left"
          size="24"
          class="text-blue clickable"
        ></cds-icon>
        <div class="left-border pl-x2 ml-x1">{{ 'WORKFLOWS.AVAILABLE_TEMPLATES' | translate: { count: templates?.length } }}</div>
      </div>
    </div>
    <div
      *ngIf="description"
      class="selection-type-description"
    >
      {{ 'WORKFLOWS.SELECT_DATASOURCE_MSG' | translate }}
    </div>
  </div>
  <div class="list-container">
    <dpa-grouped-list
      *ngIf="isCustomTemplate"
      [groupCollections]="[customGroupCollection]"
      [searchPlaceholder]="'TEMPLATE.SEARCH_DATA_SOURCES_ENTITIES' | translate"
      [groupsTitleByGroupBy]="labelByGroupBy"
      [groupIconTemplate]="groupIconTemplate"
      [isSinglePane]="false"
      [hideGroupPaneTitle]="true"
      [hasCompactItems]="true"
      [showListDivider]="true"
      [showSearch]="false"
      [showArrowWithCount]="false"
      [applyBoxStyling]="false"
      (itemSelect)="onSelect($event)"
    ></dpa-grouped-list>

    <ng-container *ngIf="!isCustomTemplate">
      <ng-container *ngIf="areTemplatesLoaded; else spinner">
        <dpa-generic-list
          [isSearchable]="true"
          [isFilterable]="true"
          [items]="templates"
          [filterTags]="filterTags"
          [searchPlaceholder]="'TEMPLATE.SEARCH_TEMPLATES' | translate"
          [showListDivider]="false"
          (searchQueryChange)="onSearchQueryChange($event)"
          (filterChange)="onFilterChange($event)"
        >
          <ng-template
            let-item="item"
            let-last="last"
          >
            <div
              dpaTabEvent
              (click)="onPreview(item)"
              (keyup.enter)="onPreview(item)"
            >
              <ng-container
                [ngTemplateOutlet]="templateSummaryListItem"
                [ngTemplateOutletContext]="{ item: item }"
              ></ng-container>
            </div>
          </ng-template>
        </dpa-generic-list>
      </ng-container>
      <ng-template #spinner>
        <div class="spinner spinner-md templates-spinner"></div>
      </ng-template>
    </ng-container>
  </div>
</div>
<ng-template
  #groupIconTemplate
  let-group="group"
  let-groupCollection="groupCollection"
>
  <div
    *ngIf="groupCollection?.groupBy === TemplateGroupBy.INTEGRATION; else customIcon"
    [attr.data-service-type]="group?.name | uppercase"
    class="service-icon connection-icon"
  ></div>
  <ng-template #customIcon>
    <cds-icon
      [attr.shape]="group?.iconShape ?? ''"
      size="24"
    ></cds-icon>
  </ng-template>
</ng-template>
