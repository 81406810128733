/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { isEmpty } from 'lodash-es';

import { CLARITY_TOOLTIP_POSITION, TemplateAction } from '@ws1c/intelligence-models';

/**
 * TemplateCardComponent
 * @export
 * @class TemplateCardComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-template-card',
  templateUrl: 'template-card.component.html',
  styleUrls: ['template-card.component.scss'],
})
export class TemplateCardComponent implements OnChanges {
  @Input() public template: any;
  @Input() public selectedTempalteId: string;
  @Input() public styledString: string;
  @Input() public tags: string[];
  @Input() public customCardBlock: TemplateRef<any>;
  @Input() public tagsTemplate: TemplateRef<any>;
  @Input() public bookmarksEnabled?: boolean;
  @Input() public bookmarkInProgress?: boolean;
  @Input() public actions?: TemplateAction[] = [];
  @Input() public dropdownActions?: TemplateAction[] = [];
  @Input() public tagLabels?: Record<string, string>;
  @Input() public hideFooter?: boolean = false;

  @Output() public toggleBookmark: EventEmitter<any> = new EventEmitter();

  public readonly CLARITY_TOOLTIP_POSITION = CLARITY_TOOLTIP_POSITION;

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof TemplateCardComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.tagLabels?.currentValue) {
      this.tags = this.getTemplateTags();
    }
  }

  /**
   * onToggleBookmark
   * @param {any} template
   * @memberof TemplateCardComponent
   */
  public onToggleBookmark(template: any) {
    this.toggleBookmark.emit(template);
  }

  /**
   * getTemplateTags
   * @returns {string[]}
   * @memberof TemplateCardComponent
   */
  public getTemplateTags(): string[] {
    const tags = [];
    if (!isEmpty(this.template.tags)) {
      tags.push(...(this.template?.templateTags ?? this.template?.original?.templateTags)?.map((tag) => this.tagLabels[tag.toUpperCase()]));
    }
    return tags;
  }

  /**
   * trackByActionLabel
   * @param {number} _index
   * @param {TemplateAction} action
   * @returns {string}
   * @memberof TemplateCardComponent
   */
  public trackByActionLabel(_index: number, action: TemplateAction): string {
    return action.label;
  }
}
