/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

/**
 * TagsComponent
 * @export
 * @class TagsComponent
 */
@Component({
  selector: 'dpa-tags',
  templateUrl: 'tags.component.html',
  styleUrls: ['tags.component.scss'],
})
export class TagsComponent {
  @Input() public tags: string[];
  @Input() public position: string;
  @Input() public combineTags: boolean = true;

  /**
   * trackByTagName
   * @param {number} _index
   * @param {string} tag
   * @returns {string}
   * @memberof TagsComponent
   */
  public trackByTagName(_index: number, tag: string): string {
    return tag;
  }
}
