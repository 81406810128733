/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { CheckIsAttributeNormalizedPipe, CheckIsAttributeRawPipe } from '@ws1c/intelligence-common/pipes';
import { I18NService } from '@ws1c/intelligence-common/services';
import { Column, ColumnIndex, ColumnToggleFilter } from '@ws1c/intelligence-models';

/**
 * getColumnTooltipBadgeText
 * @export
 * @param {Column} column
 * @param {ColumnIndex} allColumnsByName
 * @param {Record<string, ColumnToggleFilter>} columnToggleFilterMap
 * @param {I18NService} i18nService
 * @returns {string}
 */
export function getColumnTooltipBadgeText(
  column: Column,
  allColumnsByName: ColumnIndex,
  columnToggleFilterMap: Record<string, ColumnToggleFilter>,
  i18nService: I18NService,
): string {
  const isAttributeNormalizedPipe = new CheckIsAttributeNormalizedPipe();
  const isAttributeRawPipe = new CheckIsAttributeRawPipe();
  if (isAttributeNormalizedPipe.transform(column?.attribute?.fullyQualifiedName, allColumnsByName, columnToggleFilterMap)) {
    return i18nService.translate('COMMON_MESSAGES.NORMALIZED_PARENTHESIS');
  }
  if (isAttributeRawPipe.transform(column?.attribute?.fullyQualifiedName, columnToggleFilterMap)) {
    return i18nService.translate('COMMON_MESSAGES.RAW_PARENTHESIS');
  }
  return '';
}
