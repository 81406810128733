/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * AccordionWizardConfig
 * @export
 * @class AccordionWizardConfig
 */
export class AccordionWizardConfig {
  public openStepIndices = new Set([0]);
  public enabledStepIndices = new Set([0]);
  public finishedStepIndices = new Set();
  public stepCount: number = 0;

  /**
   * constructor
   * @param {Array<Partial<AccordionWizardConfig>>} args
   * @memberof AccordionWizardConfig
   */
  constructor(...args: Array<Partial<AccordionWizardConfig>>) {
    Object.assign(this, ...args);
  }

  /**
   * isFinished
   * @readonly
   * @type {boolean}
   * @memberof AccordionWizardConfig
   */
  public get isFinished(): boolean {
    return this.finishedStepIndices.size >= this.stepCount;
  }

  /**
   * isInProgress
   * @readonly
   * @type {boolean}
   * @memberof AccordionWizardConfig
   */
  public get isInProgress(): boolean {
    return this.finishedStepIndices.size < this.stepCount && this.finishedStepIndices.size > 0;
  }
}
