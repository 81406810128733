<dpa-accordion
  *ngIf="accordionWizardConfig"
  [class.single-accordion-step]="accordionSteps.length === 1"
>
  <div
    *ngFor="let accordionStep of accordionSteps; trackBy: trackByIndex; let stepIndex = index"
    dpaAccordionRow
    [title]="accordionStep.title"
    [description]="accordionStep.description"
    [expanded]="accordionWizardConfig.openStepIndices.has(stepIndex)"
    [disabled]="!accordionWizardConfig.enabledStepIndices.has(stepIndex)"
    (click)="toggleStepByIndex(stepIndex)"
    (keyup.enter)="toggleStepByIndex(stepIndex)"
  >
    <ng-template dpaAccordionRowContent>
      <ng-container *ngIf="preMountContent || accordionWizardConfig.openStepIndices.has(stepIndex)">
        <ng-template [ngTemplateOutlet]="accordionStep.contentTemplate"></ng-template>
      </ng-container>
    </ng-template>
  </div>
</dpa-accordion>
