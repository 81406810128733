/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { each, last } from 'lodash-es';

/**
 * Create an input that is similar to boot strap's type ahead
 * @export
 * @class InfinityTableComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-infinity-table',
  templateUrl: 'infinity-table.component.html',
  styleUrls: ['infinity-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfinityTableComponent implements OnChanges, OnInit, AfterContentInit {
  public static readonly BOTTOM_BUFFER = 50;

  @Input() public rows?: any[] = [];
  @Input() public loading: boolean;
  @Output() public requestMore: EventEmitter<any> = new EventEmitter<any>();

  public rowTemplate: TemplateRef<any>;
  public headerTemplate: TemplateRef<any>;
  @ContentChildren(TemplateRef) public childTemplates: QueryList<TemplateRef<any>>;
  @ViewChild('defaultRowTemplate', { static: true }) public defaultRowTemplate: TemplateRef<any>;
  @ViewChild('defaultHeaderTemplate', { static: true }) public defaultHeaderTemplate: TemplateRef<any>;
  @ViewChild('tableContainer', { static: true }) public tableContainer: ElementRef;
  public areRowsFresh: boolean = true;

  /**
   * constructor
   * @param {Renderer2} renderer
   * @memberof InfinityTableComponent
   */
  constructor(public renderer: Renderer2) {}

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof InfinityTableComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.rows) {
      this.areRowsFresh = true;
      setTimeout(() => {
        if (this.isTableScrolledToBottom()) {
          this.throttledRequestMore();
        }
      }, 0);
    }
  }

  /**
   * ngOnInit
   * @memberof InfinityTableComponent
   */
  public ngOnInit() {
    this.onScrollHandler();
  }

  /**
   * ngAfterContentInit
   * @memberof InfinityTableComponent
   */
  public ngAfterContentInit() {
    this.headerTemplate = this.childTemplates.toArray()[0] || this.defaultHeaderTemplate;
    this.rowTemplate = this.childTemplates.toArray()[1] || this.defaultRowTemplate;
  }

  /**
   * throttledRequestMore
   * @memberof InfinityTableComponent
   */
  public throttledRequestMore() {
    if (this.areRowsFresh) {
      this.areRowsFresh = false;
      this.requestMore.emit(last(this.rows));
    }
  }

  /**
   * onScrollHandler
   * @memberof InfinityTableComponent
   */
  public onScrollHandler() {
    if (this.isTableScrolledToBottom()) {
      this.throttledRequestMore();
    }
  }

  /**
   * [isTableScrolledToBottom
   * @param {number = 0} buffer
   * @returns {boolean}
   * @memberof InfinityTableComponent
   */
  public isTableScrolledToBottom(buffer: number = InfinityTableComponent.BOTTOM_BUFFER): boolean {
    const element = this.tableContainer.nativeElement;
    return element.scrollHeight <= element.scrollTop + element.clientHeight + buffer;
  }

  /**
   * trackByIndex
   * @param {number} index
   * @returns {number}
   * @memberof InfinityTableComponent
   */
  public trackByIndex(index: number): number {
    return index;
  }

  /**
   * setStyles
   * @param {HTMLElement} htmlElement
   * @param {any} styles
   * @memberof InfinityTableComponent
   */
  public setStyles(htmlElement: HTMLElement, styles: any) {
    each(styles, (value, key) => {
      this.renderer.setStyle(htmlElement, key, value);
    });
  }
}
