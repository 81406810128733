/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Icon, icon, IconOptions, map as leafletMap, marker as leafletMarker, tileLayer } from 'leaflet';

import { DpaMap, DpaMapCoordinates } from '@ws1c/intelligence-models';

const BASEMAP_URL = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
const MAP_MARKER_ASSET_PATH = './assets/icons/map/marker';

/**
 * Injectable map service for interfacing with external map libraries
 *
 * @export
 * @class MapService
 */
@Injectable({
  providedIn: 'root',
})
export class MapService {
  /**
   * Creates and returns a map instance
   *
   * @export
   * @param {HTMLElement} element - a reference to the map HTML element
   * @param {DpaMapCoordinates} coordinates - the initial center coordinates [lat, long] for the map
   * @param {number} zoom - the initial zoom level of the map (optional, defaults to 10)
   * @param {string} popup - the optional popup to display over the map marker
   * @returns {DpaMap}
   */
  public createMap(element: HTMLElement, coordinates: DpaMapCoordinates, zoom?: number, popup?: string): DpaMap {
    const mapOptions = {
      center: coordinates,
      zoom: zoom || 10,
      attributionControl: false,
    };

    const map = leafletMap(element, mapOptions);

    const baseMapOptions = {
      maxZoom: 18,
      minZoom: 3,
    };

    tileLayer(BASEMAP_URL, baseMapOptions).addTo(map);

    MapService.addMapMarker(map, coordinates, popup);

    return map;
  }

  /**
   * Adds a marker with optional popup to a map reference
   *
   * @export
   * @param {DpaMap} map - reference to the map instance
   * @param {DpaMapCoordinates} coordinates - the [lat, long] coordinates for the map marker
   * @param {string} popup - the optional popup to display over the map marker
   */
  public static addMapMarker(map: DpaMap, coordinates: DpaMapCoordinates, popup?: string) {
    const options = {
      icon: MapService.createMarkerIcon(),
    };

    const marker = leafletMarker(coordinates, options).addTo(map);

    if (popup) {
      marker.bindPopup(popup).openPopup();
    }
  }

  /**
   * Creates and returns a map marker icon
   *
   * @export
   * @returns {Leaflet.Icon<Leaflet.IconOptions>}
   */
  public static createMarkerIcon(): Icon<IconOptions> {
    const getUrlPath = (name) => `${MAP_MARKER_ASSET_PATH}/${name}.png`;

    return icon({
      ...Icon.Default.prototype.options,
      iconUrl: getUrlPath('icon'),
      iconRetinaUrl: getUrlPath('icon-2x'),
      shadowUrl: getUrlPath('shadow'),
    });
  }
}
