/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

/**
 * SolutionConfigWizardComponent
 * @export
 * @class SolutionConfigWizardComponent
 */
@Component({
  selector: 'dpa-solution-config-wizard',
  templateUrl: 'solution-config-wizard.component.html',
  styleUrls: ['solution-config-wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionConfigWizardComponent {
  @Input() public title: string;
  @Input() public description: string;
  @Input() public isSaveIndicatorVisible?: boolean = false;
  @Input() public accordionWizardTemplate: TemplateRef<any>;
  @Output() public onBackClick: EventEmitter<void> = new EventEmitter<void>();
}
