<div class="container ph-4">
  <dpa-local-search
    *ngIf="isSearchable"
    [placeHolderText]="searchPlaceholder"
    (onSearch)="searchQueryChange.emit($event)"
    iconClass="search"
    class="mt-x3"
  ></dpa-local-search>
  <dpa-filter-tags
    *ngIf="isFilterable && filterTags?.length"
    [filterTags]="filterTags"
    (onFilterChange)="filterChange.emit($event)"
    class="mv-x1"
  ></dpa-filter-tags>
</div>
<div
  [class.top-border]="showListDivider"
  class="scroll-container"
>
  <div
    *ngIf="items?.length; else noResultsFound"
    class="scroll-content-wrapper"
  >
    <div class="scroll-content ph-x4">
      <div *ngFor="let item of items; last as last">
        <ng-template
          [ngTemplateOutlet]="itemTemplate"
          [ngTemplateOutletContext]="{ item, last }"
        ></ng-template>
      </div>
    </div>
  </div>

  <ng-template #noResultsFound>
    <dpa-no-search-results
      [message]="'COMMON_MESSAGES.NO_RESULTS_FOUND' | translate"
      class="no-search-results mv-6"
    ></dpa-no-search-results>
  </ng-template>
</div>
