/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Directive, HostBinding, HostListener, Input } from '@angular/core';

/**
 * TabEventDirective
 * HTML tags/DOM elements other than buttons and anchors-with-href and having click event with them does not
 * comes under default tab order. This directive is added to add tabindex and conditional keyboard enter and
 * space key events to all those tags/elements which are having click event bindings.
 * Note: 1) Anchor without hrefs are covered in a separate directive named AnchorTabEventDirective.
 *       2) Role will ne boe added as the value may be different based omn usage.
 *
 * @export
 * @class TabEventDirective
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[dpaTabEvent]',
})
export class TabEventDirective {
  @HostBinding('attr.tabindex') public tabindex = '0';
  @Input() public bindTabEvent: boolean = false;
  /**
   * onKeyDown
   * @param {*} e
   * @memberOf TabEventDirective
   */
  @HostListener('keydown', ['$event'])
  public onKeyDown(e: any) {
    if (this.bindTabEvent && (e.which === 13 || e.which === 32)) {
      e.preventDefault();
      e.target.click();
    }
  }
}
