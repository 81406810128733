/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

/**
 * getColumnKey
 * @param {any} column
 * @returns {string}
 */
export function getColumnKey(column: any): string {
  return `${column.entity}:${column.name}`;
}
