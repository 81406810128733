/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * CardFooterComponent
 * @export
 * @class CardFooterComponent
 */
@Component({
  selector: 'dpa-card-footer',
  templateUrl: 'card-footer.component.html',
})
export class CardFooterComponent {}
