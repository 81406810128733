/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

/**
 * TemperatureUnit
 * @export
 * @enum {number}
 */
export enum TemperatureUnit {
  Fahrenheit,
  Celsius,
}
