/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */
import { isUndefined } from 'lodash-es';
import { BucketingAttribute, TrendResult } from '@ws1c/intelligence-models';

/**
 * Filters trend results with missing bucketing attribute values
 *
 * @param {TrendResult[]} trendResults
 * @returns {TrendResult[]}
 */
export const filterInvalidTrendResults = (trendResults: TrendResult[]): TrendResult[] => {
  return (
    trendResults?.filter((trendResult: TrendResult) =>
      trendResult?.bucketingAttributes?.every((attr: BucketingAttribute) => !isUndefined(attr.value)),
    ) ?? []
  );
};
