/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FuzzyItem, FuzzyService, GenericObject } from '@dpa/ui-common';
import { intersection } from 'lodash-es';

import {
  Category,
  IntegrationCategories,
  ItemGroupCollection,
  ReportTemplateType,
  Tag,
  TAGS_TYPE,
  Template,
  TemplateGroup,
  TemplateGroupBy,
} from '@ws1c/intelligence-models';

/**
 * SelectTemplateComponent
 * @export
 * @class SelectTemplateComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-select-template',
  templateUrl: 'select-template.component.html',
  styleUrls: ['select-template.component.scss'],
})
export class SelectTemplateComponent implements OnChanges {
  @Input() public labelByGroupBy: Record<string, string>;
  @Input() public filterTags: Tag[];
  @Input() public availableTemplates: Template[];
  @Input() public areTemplatesLoaded: boolean = false;
  @Input() public availableIntegrations: IntegrationCategories[];
  @Input() public itemFooterTemplate: TemplateRef<any>;
  @Input() public templateSummaryListItem: TemplateRef<any>;
  @Input() public showTemplates: boolean = true;
  @Input() public headerActionTemplate: TemplateRef<any>;
  @Input() public description?: string;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() public select = new EventEmitter<Category>();
  @Output() public preview = new EventEmitter<Template>();

  public isCustomTemplate: boolean = true;
  public customGroupCollection: ItemGroupCollection;
  public filteredTemplates: Template[];
  public queriedTemplates: Template[];
  public searchQuery: string;

  public readonly TemplateGroupBy = TemplateGroupBy;

  /**
   * Creates an instance of SelectTemplateComponent.
   * @param {FuzzyService} fuzzyService
   * @memberof SelectTemplateComponent
   */
  constructor(private fuzzyService: FuzzyService) {}

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof SelectTemplateComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.availableIntegrations?.currentValue) {
      this.customGroupCollection = new ItemGroupCollection({
        groupBy: TemplateGroupBy.INTEGRATION,
        groupByLabel: this.labelByGroupBy?.[TemplateGroupBy.INTEGRATION],
        groups: TemplateGroup.buildCategoriesByIntegration(changes?.availableIntegrations?.currentValue),
      });
    }
  }

  /**
   * templates
   * @readonly
   * @type {Template}
   * @memberof SelectTemplateComponent
   */
  public get templates(): Template[] {
    return this.queriedTemplates ?? this.filteredTemplates ?? this.availableTemplates;
  }

  /**
   * onCustomTemplateToggle
   * @param {boolean} isCustomTemplate
   * @memberof SelectTemplateComponent
   */
  public onCustomTemplateToggle(isCustomTemplate: boolean) {
    this.isCustomTemplate = isCustomTemplate;
  }

  /**
   * onSelect
   * @param {GenericObject} item
   * @memberof SelectTemplateComponent
   */
  public onSelect(item: GenericObject) {
    this.select.emit(new Category({ ...item }));
  }

  /**
   * onPreview
   * @param {Template} item
   * @memberof SelectTemplateComponent
   */
  public onPreview(item: Template) {
    this.preview.emit(item);
  }

  /**
   * onSearchQueryChange
   * @param {string} query
   * @memberof SelectTemplateComponent
   */
  public onSearchQueryChange(query: string) {
    this.searchQuery = query;
    if (!query) {
      this.queriedTemplates = this.filteredTemplates;
      return;
    }
    const templates = this.filteredTemplates ?? this.availableTemplates;
    const fuzzyResults = this.fuzzyService.sortWithKeys(query, templates, ['label']);
    this.queriedTemplates = fuzzyResults.map((fuzzyItem: FuzzyItem) => ({
      ...fuzzyItem.original,
      [fuzzyItem.matchedKey]: fuzzyItem.styledString,
      original: fuzzyItem.original,
    }));
  }

  /**
   * onFilterChange
   * @param {GenericObject} filterTags
   * @memberof ItemListComponent
   */
  public onFilterChange(filterTags: GenericObject) {
    if (Object.keys(filterTags).length) {
      this.filteredTemplates = [];
      Object.entries(filterTags).forEach(([key, filter]: [string, GenericObject]) => {
        this.filteredTemplates.push(
          ...this.availableTemplates.filter((item: Template) => {
            switch (filter.type) {
              case TAGS_TYPE.INTEGRATION:
                return filter.selectedSubTags.includes(item.integration);
              case TAGS_TYPE.BOOLEAN:
                return item[filter.name];
              case TAGS_TYPE.CUSTOM_TEMPLATE:
                return item[filter.name] === ReportTemplateType.CUSTOM;
              default:
                return item.tags?.[key] && intersection(item.tags[key], filter.selectedSubTags).length > 0;
            }
          }),
        );
      });
    } else {
      this.filteredTemplates = this.availableTemplates;
    }
    this.onSearchQueryChange(this.searchQuery);
  }
}
