/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

import { AccessType } from '@ws1c/intelligence-models';

/**
 * AccessLevelLabelComponent
 * @export
 * @class AccessLevelLabelComponent
 */
@Component({
  selector: 'dpa-access-level-label',
  templateUrl: 'access-level-label.component.html',
  styleUrls: ['access-level-label.component.scss'],
})
export class AccessLevelLabelComponent {
  @Input() public accessLevel: AccessType;
  @Input() public displayLabel?: boolean = false;
}
