/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */
// This is a copy from https://github.com/vdolek/angular-resize-event/tree/master/projects/angular-resize-event/src/lib

import { Directive, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { dpaDebounce, DpaDebounceOptions } from '@dpa/ui-common';

import { ResizedEvent } from './resized.event';

/**
 * ResizedDirective
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @export
 * @class ResizedDirective
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[resized]',
})
export class ResizedDirective implements OnInit, OnDestroy {
  @Output() public readonly resized = new EventEmitter<ResizedEvent>();

  private observer: ResizeObserver;
  private oldRect?: DOMRectReadOnly;

  /**
   * constructor
   * @param {ElementRef} element
   * @param {NgZone} zone
   * @memberof ResizedDirective
   */
  constructor(
    private readonly element: ElementRef,
    private readonly zone: NgZone,
  ) {
    this.resized = new EventEmitter<ResizedEvent>();
    this.observer = new ResizeObserver((entries) => this.zone.run(() => this.observe(entries)));
  }

  /**
   * ngOnInit
   * @memberof ResizedDirective
   */
  public ngOnInit() {
    this.observer?.observe(this.element.nativeElement);
  }

  /**
   * ngOnDestroy
   * @memberof ResizedDirective
   */
  public ngOnDestroy() {
    this.observer?.disconnect();
  }

  /**
   * observe
   * @param {ResizeObserverEntry[]} entries
   * @memberof ResizedDirective
   */
  @dpaDebounce(DpaDebounceOptions.END_OF_CURRENT_TICK)
  private observe(entries: ResizeObserverEntry[]) {
    if (!entries?.length) {
      return;
    }
    const domSize = entries[0];
    const resizedEvent = new ResizedEvent(domSize.contentRect, this.oldRect);
    this.oldRect = domSize.contentRect;
    this.resized.emit(resizedEvent);
  }
}
