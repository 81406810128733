<h4>
  {{ header }}
  <dpa-tooltip
    *ngIf="tooltip"
    [tooltipText]="tooltip"
  >
    <cds-icon
      shape="info-circle"
      class="is-dark-midtone-gray"
      size="24"
    ></cds-icon>
  </dpa-tooltip>
</h4>
<dpa-category-selector-dropdown
  [selectedCategory]="selectedCategory"
  [integrationCategories]="integrationCategories"
  (selectedCategoryChange)="selectedCategoryChange.emit($event)"
></dpa-category-selector-dropdown>
<p *ngIf="description">
  {{ description }}
</p>
