<clr-alert
  *ngIf="!showEditDeleteConfirmModal && deletingComment?.id === commentId"
  clrAlertType="warning"
  (clrAlertClosedChange)="onCancelDelete()"
  class="mb-x2"
>
  <clr-alert-item>
    <span class="alert-text">{{ 'COMMON_MESSAGES.DELETE_COMMENT_CONFIRMATION' | translate }}</span>
    <div class="alert-actions">
      <div class="alert-action dropdown">
        <a
          (click)="onConfirmDelete()"
          class="alert-action clickable text-danger"
        >
          {{ 'COMMON_MESSAGES.DELETE' | translate }}
        </a>
        <a
          (click)="onCancelDelete()"
          class="alert-action clickable"
        >
          {{ 'COMMON_ACTIONS.CANCEL' | translate }}
        </a>
      </div>
    </div>
  </clr-alert-item>
</clr-alert>
<div class="comment">
  <div class="avatar">
    <!-- linthtml-configure img-req-src="false" -->
    <img
      *ngIf="avatarUrl; else noAvatarUrl"
      [src]="avatarUrl"
      alt="{{ 'DEEM_COMMENTS.PROFILE_IMAGE' | translate }}"
      class="profile-icon"
    />
    <!-- linthtml-configure img-req-src="true" -->
    <ng-template #noAvatarUrl>
      <cds-icon
        class="profile-icon"
        shape="dpa-blank-avatar"
      ></cds-icon>
    </ng-template>
  </div>
  <div class="content">
    <div class="content-header">
      <div class="author">
        <a class="btn btn-link author-link">
          {{ userDetails | userInfo }}
        </a>
      </div>
      <div
        *ngIf="isActionButtonsVisible()"
        class="actions"
      >
        <cds-icon
          (click)="onEditClick()"
          (keydown.enter)="onEditClick()"
          shape="note"
        ></cds-icon>
        <cds-icon
          (click)="onDeleteClick()"
          (keydown.click)="onDeleteClick()"
          shape="trash"
        ></cds-icon>
      </div>
    </div>
    <div
      *ngIf="editingComment?.id !== commentId; else editor"
      class="comment-content"
    >
      <p [outerHTML]="text"></p>
      <div class="metadata">
        <dpa-from-now [timestamp]="lastUpdatedAt"></dpa-from-now>
      </div>
    </div>
    <ng-template #editor>
      <div *ngIf="isEditingComment">
        <form [formGroup]="editCommentForm">
          <quill-editor
            dpaRichTextEditorConfig
            formControlName="text"
          ></quill-editor>
        </form>
        <div class="edit-button-group">
          <button
            [disabled]="isSavingEditComment"
            (click)="onCancelEdit()"
            class="btn btn-sm btn-link"
            type="button"
          >
            {{ 'COMMON_ACTIONS.CANCEL' | translate }}
          </button>
          <button
            [disabled]="isSavingEditComment"
            (click)="onSaveEdit()"
            class="btn btn-sm btn-primary save-button"
            type="button"
          >
            {{ 'COMMON_ACTIONS.SAVE' | translate }}
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</div>
