/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { UserDetails } from '@ws1c/intelligence-models';

/**
 * Pipe to show `userInfo` from details object
 *
 * @export
 * @class userInfoPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'userInfo' })
export class UserInfoPipe implements PipeTransform {
  /**
   * transform
   * @param {UserDetails} userDetails
   * @returns {string}
   * @memberOf {UserInfoPipe}
   */
  public transform(userDetails: UserDetails): string {
    return userDetails ? userDetails.userInfo : '';
  }
}
