/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

import { AccordionWizardStepService } from '@ws1c/intelligence-common/accordion-wizard/services/accordion-wizard-step.service';

/**
 * AccordionWizardStepComponent
 * @export
 * @class AccordionWizardStepComponent
 */
@Component({
  selector: 'dpa-accordion-wizard-step',
  templateUrl: 'accordion-wizard-step.component.html',
  styleUrls: ['accordion-wizard-step.component.scss'],
  providers: [AccordionWizardStepService],
})
export class AccordionWizardStepComponent {
  @Input() public title: string;
  @Input() public description?: string;
  @ViewChild('contentTemplate', { static: true }) public contentTemplate: TemplateRef<any>;

  /**
   * constructor
   * @param {AccordionWizardStepService} accordionWizardStepService
   * @memberof AccordionWizardStepService
   */
  constructor(private accordionWizardStepService: AccordionWizardStepService) {}

  /**
   * setStepIndex
   * @param {number} index
   * @memberof AccordionWizardStepService
   */
  public setStepIndex(index: number) {
    this.accordionWizardStepService.stepIndex = index;
  }

  /**
   * getStepIndex
   * @returns {number}
   * @memberof AccordionWizardStepService
   */
  public getStepIndex(): number {
    return this.accordionWizardStepService.stepIndex;
  }
}
