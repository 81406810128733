/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Optional, Output, TemplateRef } from '@angular/core';

/**
 * PanelComponent
 * @export
 * @class PanelComponent
 */
@Component({
  selector: 'dpa-panel',
  templateUrl: 'panel.component.html',
  styleUrls: ['panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PanelComponent {
  @Input() public headerTemplate: TemplateRef<any>;
  @Input() public headerMainText: string;
  @Input() public headerSubText: string;
  @Input() public expanded?: boolean = false;
  @Input() public toggleable?: boolean = false;

  @Output() public expandChange = new EventEmitter<boolean>();

  /**
   * Creates an instance of PanelComponent.
   * @param {{ onPanelExpand: (panel: PanelComponent) => void }} parentPanelsComponent
   * @memberof PanelComponent
   */
  constructor(
    @Optional() @Inject('EnclosingPanelsComponent') public parentPanelsComponent: { onPanelExpand: (panel: PanelComponent) => void },
  ) {}

  /**
   * toggleExpand
   * @memberof PanelComponent
   */
  public toggleExpand = () => {
    if (!this.toggleable) {
      return;
    }

    this.expanded = !this.expanded;
    this.expandChange.emit(this.expanded);
    if (this.expanded) {
      this.parentPanelsComponent?.onPanelExpand(this);
    }
  };

  /**
   * showHeader
   * @returns {boolean}
   * @memberof PanelComponent
   */
  public get showHeader(): boolean {
    return !!(this.headerMainText || this.headerSubText || this.headerTemplate);
  }
}
