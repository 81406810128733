/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { CLARITY_TOOLTIP_POSITION } from '@ws1c/intelligence-models';

/**
 * TagFilterComponent
 * @export
 * @class TagFilterComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-tag-filter',
  templateUrl: 'tag-filter.component.html',
  styleUrls: ['tag-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagFilterComponent implements OnChanges, OnInit {
  @Input() public isMinimized?: boolean = false;
  @Input() public tagLabels: Record<string, string>;
  @Input() public filtersDefaultStatus?: Record<string, boolean> = {};
  @Output() public filterChange: EventEmitter<Record<string, boolean>> = new EventEmitter<Record<string, boolean>>();

  public filterList: string[] = [];
  public filtersStatus: Record<string, boolean> = {};
  public isDropdownOpen: boolean = false;
  public selectedFilterCount: number = 0;

  public readonly CLARITY_TOOLTIP_POSITION = CLARITY_TOOLTIP_POSITION;

  /**
   * ngOnInit
   * @memberof TagFilterComponent
   */
  public ngOnInit() {
    this.filterList = Object.keys(this.tagLabels);
    this.filterList.forEach((filterName: string) => {
      this.filtersStatus[filterName] = this.filtersDefaultStatus[filterName] ?? false;
    });
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof TagFilterComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.tagLabels) {
      this.filterList = Object.keys(this.tagLabels);
      this.filtersStatus = this.filtersDefaultStatus;
    }
  }

  /**
   * toggleFilter - filter emit
   * @param {string} filterName
   * @memberof TagFilterComponent
   */
  public toggleFilter(filterName: string) {
    const change = {
      [filterName]: !this.filtersStatus[filterName],
    };
    this.filtersStatus = {
      ...this.filtersStatus,
      ...change,
    };

    this.updateSelectedFilterCount();
    this.filterChange.emit(change);
  }

  /**
   * updateSelectedFilterCount
   * @private
   * @memberof TagFilterComponent
   */
  private updateSelectedFilterCount() {
    this.selectedFilterCount = this.filterList.filter((filterName: string) => this.filtersStatus[filterName]).length;
  }
}
