/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

import { AppConfig } from '@ws1c/intelligence-common/constants';

/**
 * Custom Tag View
 * @export
 * @class CustomTagComponent
 */
@Component({
  selector: 'dpa-custom-tag',
  templateUrl: 'custom-tag.component.html',
  styleUrls: ['custom-tag.component.scss'],
})
export class CustomTagComponent {
  @Input() public bgColorClass?: string = AppConfig.DEFAULT_TAG_BG_COLOR_CLASS;
  @Input() public text: string;
}
