/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ClrIconModule } from '@clr/angular';

import { InnerHtmlLightDirective } from '@dpa/ui-common/inner-html-light';
import { TooltipComponent } from '@dpa/ui-common/tooltip';
import { TranslatePipe } from '@dpa/ui-common/translate';

/**
 * ColumnSelectorDetailComponent
 *
 * @export
 * @class ColumnSelectorDetailComponent
 */
@Component({
  selector: 'dpa-column-selector-detail',
  templateUrl: 'column-selector-detail.component.html',
  styleUrls: ['column-selector-detail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, ClrIconModule, TooltipComponent, TranslatePipe, InnerHtmlLightDirective],
})
export class ColumnSelectorDetailComponent {
  @Input() public label: string = '';
  @Input() public detail?: {
    tooltip?: string;
    isNormalized?: boolean;
    error?: boolean;
  };

  /**
   * getTooltipText
   * @returns {string}
   * @memberof ColumnSelectorDetailComponent
   */
  public getTooltipText(): string {
    return this.detail?.error ? 'COMMON_MESSAGES.COLUMNS_REMOVED' : this.detail?.tooltip;
  }
}
