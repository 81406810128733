/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

/**
 * ContentType
 * @export
 * @enum {string}
 */
export enum ContentType {
  HTML = 'html',
  STYLE = 'style',
  SCRIPT = 'script',
  URL = 'url',
  RESOURCE_URL = 'resourceUrl',
}
