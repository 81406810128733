/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { GenericObject, PagedRequest, PagedResponse, SortOn } from '@dpa/ui-common';
import { each, get, orderBy, set, some } from 'lodash-es';
import moment from 'moment';

import { Column, DataGridColumn, DataType, LocalDataGridSettings, LodashSortOrder, ReportColumnMapper } from '@ws1c/intelligence-models';

/**
 * LocalDataGridComponent
 * @export
 * @class LocalDataGridComponent
 */
@Component({
  selector: 'dpa-local-data-grid',
  templateUrl: 'local-data-grid.component.html',
  styleUrls: ['local-data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocalDataGridComponent implements OnChanges {
  @Input() public availableColumns: Column[];
  @Input() public formattersByColumnName: Record<string, (flatTrendValue: any, flatTrendResult?: any) => string> = {};
  @Input() public responseData: any;
  @Input() public localDataGridSettings?: LocalDataGridSettings = {};
  @Input() public columnWidthByKey?: { [key: string]: number } = {};
  @Input() public loading?: boolean = false;
  @Input() public filterSearchKeys: string[];
  @Input() public cellTemplatesByColumnValue: Record<string, TemplateRef<any>> = {};
  @Input() public expandedRowTemplate?: TemplateRef<any>;
  @Input() public noResultsText: string;
  @Input() public noResultsSubtext: string;
  @Input() public showDetailView?: boolean = false;
  @Input() public enablePagination?: boolean = true;
  @Input() public detailHeaderTemplate?: TemplateRef<any>;
  @Input() public detailBodyTemplate?: TemplateRef<any>;
  @Input() public selectable = {
    single: false,
    enabled: false,
  };
  @Output() public localDataGridSettingsChange = new EventEmitter<LocalDataGridSettings>();
  @Output() public onRefresh = new EventEmitter<any>();
  @Output() public selectionChange: EventEmitter<GenericObject[]> = new EventEmitter();

  public localPagedResponse: PagedResponse;
  public defaultedDataGridSettings: LocalDataGridSettings = this.getDefaultLocalDatagridSettings();
  public dataGridColumns: DataGridColumn[];
  public dateColumns: string[] = [DataType[DataType.DATETIME], DataType[DataType.DATE_AS_STRING]];
  private formattedByValueByColumnName: Record<string, Record<string, string>> = {};

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof LocalDataGridComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.responseData || changes.localDataGridSettings || changes.filterSearchKeys) {
      this.defaultedDataGridSettings = Object.assign(this.getDefaultLocalDatagridSettings(), this.localDataGridSettings);
      this.localPagedResponse = this.getFilterSortPagedResponse(this.responseData, this.defaultedDataGridSettings, this.filterSearchKeys);
    }

    if (changes.availableColumns && this.availableColumns) {
      this.dataGridColumns = ReportColumnMapper.getDataGridColumns(this.availableColumns);
    }
  }

  /**
   * onSelectionChange
   * @param {GenericObject[]} value
   * @memberof LocalDataGridComponent
   */
  public onSelectionChange(value: GenericObject[]) {
    this.selectionChange.emit(value);
  }

  /**
   * onSortOnsChange
   * @param {SortOn[]} sortOns
   * @memberof LocalDataGridComponent
   */
  public onSortOnsChange(sortOns: SortOn[]) {
    this.localDataGridSettingsChange.emit(
      Object.assign(new LocalDataGridSettings(), {
        ...this.defaultedDataGridSettings,
        sortOns,
      }),
    );
  }

  /**
   * onPageChange
   * @param {PagedRequest} pagedRequest
   * @memberof LocalDataGridComponent
   */
  public onPageChange(pagedRequest: PagedRequest) {
    this.localDataGridSettingsChange.emit(
      Object.assign(new LocalDataGridSettings(), {
        ...this.defaultedDataGridSettings,
        pagedRequest,
      }),
    );
  }

  /**
   * getFilterSortPagedResponse
   * @param {any} originalResponse
   * @param {LocalDataGridSettings} localDataGridSettings
   * @param {string[]} filterSearchKeys
   * @returns {PagedResponse}
   * @memberof LocalDataGridComponent
   */
  public getFilterSortPagedResponse(
    originalResponse: any,
    localDataGridSettings: LocalDataGridSettings,
    filterSearchKeys: string[],
  ): PagedResponse {
    if (!originalResponse) {
      return;
    }

    const nextSearchResponse = {
      ...originalResponse,
      ...localDataGridSettings.pagedRequest,
      total: originalResponse.results.length,
    } as PagedResponse;

    // Order here is important
    // Sort, format, filter, then paginate
    if (localDataGridSettings.sortOns) {
      localDataGridSettings.sortOns.forEach((sortOn: SortOn) => {
        const sortColumn = this.availableColumns.find((column: Column) => column.name === sortOn.by);
        nextSearchResponse.results = orderBy(
          nextSearchResponse.results,
          (item: GenericObject) => {
            if (this.dateColumns.includes(sortColumn?.dataType)) {
              return moment(item[sortOn.by]);
            }
            return get(item, sortOn.by);
          },
          sortOn.reverse ? LodashSortOrder.DESC : LodashSortOrder.ASC,
        );
      });
    }
    if (this.formattersByColumnName) {
      nextSearchResponse.results = nextSearchResponse.results.map((result: any) => {
        // results are sometimes instances of things like "AppCrashGroup" and their "getLabel" functions are used elsewhere
        // this is needed to allow continued use of those functions
        // avoids using cloneDeep for performance reasons
        const prototype = Object.getPrototypeOf(result);
        const resultClone = Object.assign(Object.create(prototype), result);

        each(this.formattersByColumnName, (formatter: (cellValue: string, row: any) => string, columnName: string) => {
          const cellValue = resultClone[columnName];

          // Caches formatted results for performance
          let formatted = get(this.formattedByValueByColumnName, [columnName, cellValue]);
          if (!formatted) {
            formatted = formatter ? formatter(resultClone[columnName], resultClone) : resultClone[columnName];
            set(this.formattedByValueByColumnName, [columnName, cellValue], formatted);
          }
          resultClone[columnName] = formatted;
        });
        return resultClone;
      });
    }
    if (localDataGridSettings.filter) {
      const regExp = new RegExp(localDataGridSettings.filter, 'i');
      const defaultedFilterSearchKeys = filterSearchKeys || this.availableColumns.map((column: Column) => column.name);
      nextSearchResponse.results = nextSearchResponse.results.filter((event: any) => {
        return some(defaultedFilterSearchKeys, (searchKey: string) => regExp.test(event[searchKey]));
      });
      nextSearchResponse.total = nextSearchResponse.results.length;
    }
    if (localDataGridSettings.pagedRequest) {
      nextSearchResponse.results = nextSearchResponse.results.slice(
        localDataGridSettings.pagedRequest.from,
        localDataGridSettings.pagedRequest.from + localDataGridSettings.pagedRequest.size,
      );
    }
    return nextSearchResponse;
  }

  /**
   * getDefaultLocalDatagridSettings
   * @returns {LocalDataGridSettings}
   * @memberof LocalDataGridComponent
   */
  public getDefaultLocalDatagridSettings(): LocalDataGridSettings {
    return new LocalDataGridSettings({
      filter: '',
      sortOns: [],
      pagedRequest: Object.assign(new PagedRequest(), {
        from: 0,
        size: 25,
      }),
    });
  }

  /**
   * onRefreshClick
   * @memberof LocalDataGridComponent
   */
  public onRefreshClick() {
    this.onRefresh.emit();
  }
}
