/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';

import { IntelligenceCommonModule } from '@ws1c/intelligence-common/intelligence-common.module';
import { UserTouchDetailsComponent } from './user-touch-details.component';

/**
 * UserTouchDetailsModule
 * @exports UserTouchDetailsModule
 */
@NgModule({
  declarations: [UserTouchDetailsComponent],
  imports: [IntelligenceCommonModule],
  exports: [UserTouchDetailsComponent],
})
export class UserTouchDetailsModule {}
