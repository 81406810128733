{{ displayValue }}
<dpa-tooltip
  *ngIf="remainingValuesButtonLabel"
  [attr.aria-label]="tooltipText"
  [tooltipText]="tooltipText"
  aria-haspopup="true"
>
  <button
    (click)="onRemainingValuesBtnClick()"
    class="btn btn-link btn-sm remaining-values-btn"
  >
    {{ remainingValuesButtonLabel }}
  </button>
</dpa-tooltip>
