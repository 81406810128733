/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createAction } from '@ngrx/store';

/**
 * IncidentAutomationActions
 * @exports
 * @class IncidentAutomationActions
 */
export class IncidentAutomationActions {
  public static readonly CATEGORY = '[INCIDENT_AUTOMATION]';

  // wizard url management
  public static requestWizardUrls = createAction(`${IncidentAutomationActions.CATEGORY} REQUEST_WIZARD_URLS`);

  // navigation (for setting custom breadcrumbs)
  public static navigateToAutomationView = createAction(`${IncidentAutomationActions.CATEGORY} NAVIGATE_TO_AUTOMATION_VIEW`);
  public static navigateAwayFromAutomationView = createAction(`${IncidentAutomationActions.CATEGORY} NAVIGATE_AWAY_FROM_AUTOMATION_VIEW`);
}
