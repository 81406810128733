/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { ColumnIndex, ColumnToggleFilter } from '@ws1c/intelligence-models';

/**
 * CheckIsAttributeNormalizedPipe
 * @export
 * @class CheckIsAttributeNormalizedPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'isAttributeNormalized' })
export class CheckIsAttributeNormalizedPipe implements PipeTransform {
  /**
   * transform
   * @param {string} attributeName
   * @param {ColumnIndex} allColumnsByName
   * @param {Record<string, ColumnToggleFilter>} [columnToggleFilterMap={}]
   * @returns {boolean}
   * @memberof CheckIsAttributeNormalizedPipe
   */
  public transform(
    attributeName: string,
    allColumnsByName: ColumnIndex,
    columnToggleFilterMap: Record<string, ColumnToggleFilter> = {},
  ): boolean {
    const columnName: string = allColumnsByName[attributeName]?.rawAttributeName;
    return Object.keys(columnToggleFilterMap).includes(columnName);
  }
}
