<div
  [class.expanded]="expanded"
  class="panel"
>
  <ng-container *ngIf="showHeader">
    <ng-template
      [ngTemplateOutlet]="headerTemplate || headerDefaultTemplate"
      [ngTemplateOutletContext]="{
        toggleExpand: toggleExpand,
        expanded: expanded,
        toggleable: toggleable
      }"
    ></ng-template>
  </ng-container>
  <div
    *dpaDelayRender="1"
    class="panel-body"
  >
    <ng-container *ngIf="expanded">
      <ng-content></ng-content>
    </ng-container>
  </div>
</div>

<ng-template #headerDefaultTemplate>
  <div
    dpaTabEvent
    [class.toggleable]="toggleable"
    (click)="toggleExpand()"
    (keyup.enter)="toggleExpand()"
    class="panel-header"
  >
    <div class="header-labels">
      <span
        [textContent]="headerMainText"
        class="header-main-text"
      ></span>
      <span
        [textContent]="headerSubText"
        class="header-sub-text"
      ></span>
    </div>
    <div
      *ngIf="toggleable"
      class="header-actions"
    >
      <cds-icon
        shape="angle"
        size="20"
        direction="down"
      ></cds-icon>
    </div>
  </div>
</ng-template>
