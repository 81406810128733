/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * PageChangerComponent
 * @export
 * @class PageChangerComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-page-changer',
  templateUrl: 'page-changer.component.html',
  styleUrls: ['page-changer.component.scss'],
})
export class PageChangerComponent {
  @Input() public pageNumber?: number = 0;
  @Input() public pageCount?: number = 0;
  @Output() public pageNumberChange: EventEmitter<number> = new EventEmitter<number>();

  /**
   * onPageChange
   * @param {number} change
   * @class PageChangerComponent
   */
  public onPageChange(change: number) {
    this.pageNumber = (this.pageNumber + this.pageCount + change) % this.pageCount;
    this.pageNumberChange.emit(this.pageNumber);
  }
}
