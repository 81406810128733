/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, PagedRequest, PagedResponse } from '@dpa/ui-common';

/**
 * Updates and returns paged response object based on paged request
 * @param {PagedRequest} pagedRequest - paged request object
 * @param {GenericObject[]} results - results list
 * @type {(pagedRequest: PagedRequest, results: GenericObject[]) => PagedResponse}
 */
export const paginationHelper = (pagedRequest: PagedRequest, results: GenericObject[]): PagedResponse => {
  const total: number = results?.length || 0;
  const from: number = total <= pagedRequest.from ? 0 : pagedRequest.from;
  return {
    from,
    size: pagedRequest.size,
    results: results.slice(from, from + pagedRequest.size),
    total,
  };
};
