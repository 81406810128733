/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DashboardConfig } from '@ws1c/intelligence-models';

/**
 * SeverityIndicatorComponent
 * @export
 * @class SeverityIndicatorComponent
 */
@Component({
  selector: 'dpa-severity-indicator',
  templateUrl: 'severity-indicator.component.html',
  styleUrls: ['severity-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeverityIndicatorComponent {
  @Input() public severity: string;

  public readonly DashboardConfig = DashboardConfig;
}
