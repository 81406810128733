/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

import { Leaderboard } from '@ws1c/intelligence-models';

/**
 * WidgetLeaderboardsCardComponent
 * @export
 * @class WidgetLeaderboardsCardComponent
 */
@Component({
  selector: 'dpa-widget-leaderboards-card',
  templateUrl: 'widget-leaderboards-card.component.html',
  styleUrls: ['widget-leaderboards-card.component.scss'],
})
export class WidgetLeaderboardsCardComponent {
  @Input() public leaderboards?: Leaderboard[] = [];
}
