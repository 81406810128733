/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

/**
 * Empty state component
 * @export
 * @class EmptyStateComponent
 */
@Component({
  selector: 'dpa-empty-state',
  templateUrl: 'empty-state.component.html',
  styleUrls: ['empty-state.component.scss'],
})
export class EmptyStateComponent {
  @Input() public bgImageClass?: string = '';
  @Input() public description?: string = '';
  @Input() public descriptionTemplate?: TemplateRef<any>;
  @Input() public actionName?: string = '';
  @Input() public showAction?: boolean = true;
  @Output() public onClickAction?: EventEmitter<void> = new EventEmitter<void>();

  /**
   * onClick handler
   * @memberof EmptyStateComponent
   */
  public onClick() {
    this.onClickAction.emit();
  }
}
