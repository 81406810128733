/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

const typeCache: { [label: string]: boolean } = {};

/**
 * This function coerces a string into a string literal type.
 * Using tagged union types in TypeScript 2.0, this enables
 * powerful typechecking of our reducers.
 *
 * Since every action label passes through this function it
 * is a good place to ensure all of our action labels
 * are unique.
 * @export
 * @template T
 * @param {(T)} label
 * @returns {T}
 */
export function type<T extends string>(label: T): T {
  if (typeCache[label]) {
    throw new Error(`Action type "${label}" is not unique"`);
  }

  typeCache[label] = true;

  return label;
}
