/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { OverlayScrollbars, PartialOptions } from 'overlayscrollbars';

/**
 *
 * ScrollbarsDirective - Wrapper for OverlayScrollbars
 * @export
 * @class ScrollbarsDirective
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Directive({
  selector: '[dpaScrollbars]',
})
export class ScrollbarsDirective implements AfterViewInit, OnDestroy {
  @Input() public useFlexbox?: boolean = false;
  public overlayScrollbars;
  public osTarget: ElementRef;
  public osInstance!: OverlayScrollbars;
  public osOptions: PartialOptions = {
    scrollbars: {
      visibility: 'visible',
    },
  };

  /**
   * @param {ElementRef} hostEl
   * @memberof ScrollbarsDirective
   */
  constructor(private hostEl: ElementRef) {
    this.osTarget = this.hostEl;
    this.overlayScrollbars = OverlayScrollbars;
  }

  /**
   * ngAfterViewInit
   *
   * @memberof ScrollbarsDirective
   */
  public ngAfterViewInit() {
    // https://kingsora.github.io/OverlayScrollbars/#!documentation/flexbox
    if (this.useFlexbox) {
      this.osTarget.nativeElement.classList.add('os-host-flexbox');
    }
    this.osInstance = this.overlayScrollbars(this.osTarget.nativeElement, this.osOptions);
  }

  /**
   * ngOnDestroy
   *
   * @memberof ScrollbarsDirective
   */
  public ngOnDestroy() {
    if (this.osInstance && this.osInstance.destroy) {
      this.osInstance.destroy();
    }
  }
}
