/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

import { AccountRole } from '@ws1c/intelligence-models';

/**
 * Component to display the icons for the roles passed
 * @export
 * @class RoleIconsComponent
 */
@Component({
  selector: 'dpa-role-icons',
  templateUrl: 'role-icons.component.html',
  styleUrls: ['role-icons.component.scss'],
})
export class RoleIconsComponent {
  @Input() public roles?: AccountRole[] = [];
}
