/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeFormat, GenericObject } from '@dpa/ui-common';
import moment from 'moment';

import { I18NService } from '@ws1c/intelligence-common/services/i18n.service';
import { Frequency } from '@ws1c/intelligence-models';

/**
 * ScheduleSummaryPipe
 *
 * @export
 * @class ScheduleSummaryPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'scheduleSummaryPipe' })
export class ScheduleSummaryPipe implements PipeTransform {
  private static readonly weekdays = {
    sun: 'SCHEDULE_CUSTOMIZE.WEEKDAY.SUNDAY',
    mon: 'SCHEDULE_CUSTOMIZE.WEEKDAY.MONDAY',
    tue: 'SCHEDULE_CUSTOMIZE.WEEKDAY.TUESDAY',
    wed: 'SCHEDULE_CUSTOMIZE.WEEKDAY.WEDNESDAY',
    thu: 'SCHEDULE_CUSTOMIZE.WEEKDAY.THURSDAY',
    fri: 'SCHEDULE_CUSTOMIZE.WEEKDAY.FRIDAY',
    sat: 'SCHEDULE_CUSTOMIZE.WEEKDAY.SATURDAY',
  };

  /**
   * constructor
   * @param {I18NService} i18nService
   * @memberof ScheduleSummaryPipe
   */
  constructor(private i18nService: I18NService) {}

  /**
   * transform
   * @param {GenericObject} cronExpressionDetail
   * @returns {string}
   * @memberof ScheduleSummaryPipe
   */
  public transform(cronExpressionDetail: GenericObject): string {
    if (!cronExpressionDetail) {
      return this.i18nService.translate('AUTOMATION_ACTIONS.SCHEDULE');
    }
    const time: string =
      cronExpressionDetail.frequency !== Frequency.YEARLY
        ? moment(cronExpressionDetail.startTimeOfDay).format(DateTimeFormat.MOMENT_TIME_FORMAT)
        : '';
    switch (cronExpressionDetail.frequency) {
      case Frequency.ONCE:
        return this.i18nService.translate('AUTOMATION_ACTIONS.SCHEDULE_ONCE');
      case Frequency.HOURLY:
        return this.i18nService.translate('AUTOMATION_ACTIONS.SCHEDULE_HOURLY', {
          interval: cronExpressionDetail.hourly.interval,
          time,
        });
      case Frequency.DAILY:
        return this.i18nService.translate('AUTOMATION_ACTIONS.SCHEDULE_DAILY', {
          time,
        });
      case Frequency.WEEKLY:
        const days = Object.keys(cronExpressionDetail.weekly).reduce((daysAcc: string[], key: string) => {
          if (cronExpressionDetail.weekly[key]) {
            daysAcc.push(this.i18nService.translate(ScheduleSummaryPipe.weekdays[key]));
          }
          return daysAcc;
        }, []);
        if (days.length === 7) {
          return this.i18nService.translate('AUTOMATION_ACTIONS.SCHEDULE_DAILY', {
            time,
          });
        }
        return this.i18nService.translate('AUTOMATION_ACTIONS.SCHEDULE_WEEKLY', {
          days: days.join(', '),
          time,
        });
      case Frequency.MONTHLY:
        return this.i18nService.translate('AUTOMATION_ACTIONS.SCHEDULE_MONTHLY', {
          day: cronExpressionDetail.monthly.dayOfMonth,
          time,
        });
      case Frequency.YEARLY:
        return this.i18nService.translate('AUTOMATION_ACTIONS.SCHEDULE_YEARLY');
    }
  }
}
