<ul class="item-list">
  <li
    *ngFor="let tmpl of templates || []; let last = last"
    class="item"
  >
    <div class="separator">
      <div class="dot"></div>
      <div
        *ngIf="!last"
        class="connector"
      ></div>
    </div>
    <ng-container [ngTemplateOutlet]="tmpl"></ng-container>
  </li>
</ul>
