<dpa-tooltip
  *ngIf="tooltip"
  [tooltipText]="tooltip.textKey | translate"
  [focusEnabled]="true"
>
  <ng-container [ngSwitch]="tooltip.severity">
    <cds-icon
      *ngSwitchCase="TooltipSeverity.INFO"
      class="is-info"
      shape="info-circle"
      size="24"
    ></cds-icon>
    <cds-icon
      *ngSwitchCase="TooltipSeverity.WARNING"
      status="warning"
      shape="exclamation-triangle"
      size="24"
    ></cds-icon>
    <cds-icon
      *ngSwitchCase="TooltipSeverity.DANGER"
      status="danger"
      shape="exclamation-triangle"
      size="24"
    ></cds-icon>
  </ng-container>
</dpa-tooltip>
