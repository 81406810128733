<div class="empty-state-container">
  <div [ngClass]="['bg-image', 'empty-state-img', bgImageClass]"></div>
  <div class="empty-state-info">
    <p *ngIf="description">
      {{ description }}
    </p>
    <ng-template *ngTemplateOutlet="descriptionTemplate"></ng-template>
    <p *ngIf="actionName && showAction">
      <button
        (click)="onClick()"
        class="btn btn-primary"
      >
        {{ actionName }}
      </button>
    </p>
  </div>
</div>
