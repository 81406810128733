/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { GenericObject } from '@dpa/ui-common';
import { isEmpty } from 'lodash-es';

import { I18NService } from '@ws1c/intelligence-common/services';
import {
  Column,
  COLUMN_NAMES,
  IntegrationCategories,
  NameLabel,
  Tag,
  Template,
  TEMPLATE_TYPE,
  TemplateAction,
  TemplateBookmark,
} from '@ws1c/intelligence-models';

/**
 * Template search for Widgets and Reports Add flow
 * @export
 * @class TemplateSearchComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-template-search',
  templateUrl: 'template-search.component.html',
  styleUrls: ['template-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplateSearchComponent implements OnInit, OnChanges {
  @ViewChild('actionsTemplate', { static: true }) public actionsTemplate: TemplateRef<any>;

  @Input() public templateType: TEMPLATE_TYPE;
  @Input() public templates: Template[];
  @Input() public selectedTemplate: Template;
  @Input() public availableIntegrations: IntegrationCategories[];
  @Input() public tags: Tag[];
  @Input() public selectedTag: string;
  @Input() public bookmarksEnabled?: boolean = true;
  @Input() public bookmarkInProgress?: boolean = false;
  @Input() public customTemplatesEnabled?: boolean = false;
  @Input() public searchExact?: boolean = false;
  @Input() public searchOn?: string = 'name';
  @Input() public templateTableColumns: Column[];
  @Input() public customCardBlock: TemplateRef<any>;
  @Input() public cellTemplatesByColumnValue?: Record<string, TemplateRef<any>> = {};
  @Input() public columnWidthByKey?: { [key: string]: number } = {};
  @Input() public noResultsTemplate?: TemplateRef<any>;
  @Input() public updateUrlLayoutParam?: boolean = false;
  @Input() public actions?: TemplateAction[] = [];
  @Input() public dropdownActions?: TemplateAction[] = [];
  @Input() public showCustomContentTemplate?: boolean = true;
  @Input() public tableName?: string;
  @Output() public clickCustomTemplate: EventEmitter<void> = new EventEmitter();
  @Output() public toggleBookmark: EventEmitter<TemplateBookmark> = new EventEmitter<TemplateBookmark>();

  public orderByArray: NameLabel[];
  private integrationLabels: Record<string, string> = {};
  private tagLabels: Record<string, string> = {};

  /**
   * Creates an instance of TemplateSearchComponent.
   * @param {I18NService} i18nService
   * @memberof TemplateSearchComponent
   */
  constructor(private i18nService: I18NService) {
    this.orderByArray = [
      new NameLabel({
        name: COLUMN_NAMES.byName.name,
        label: this.i18nService.translate('COMMON_MESSAGES.NAME'),
      }),
    ];
  }

  /**
   * ngOnInit
   * @memberof TemplateSearchComponent
   */
  public ngOnInit() {
    this.cellTemplatesByColumnValue = {
      ...this.cellTemplatesByColumnValue,
      actions: this.actionsTemplate,
    };
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof TemplateSearchComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    const availableIntegrations = changes.availableIntegrations?.currentValue;
    if (availableIntegrations) {
      this.integrationLabels = availableIntegrations.reduce(
        (accum: GenericObject, integrationCategories: IntegrationCategories) => ({
          ...accum,
          [integrationCategories.integration.name]: integrationCategories.integration.label,
        }),
        {},
      );
    }

    if (!changes.tags) {
      return;
    }
    const tags = changes.tags.currentValue;
    if (tags) {
      const integrationTag = tags?.find((tag: Tag) => tag.name === COLUMN_NAMES.byName.integration);
      this.integrationLabels =
        integrationTag?.subtags.reduce(
          (accum: GenericObject, integration: NameLabel) => ({
            ...accum,
            [integration.name]: integration.label,
          }),
          {},
        ) ?? {};
      tags.forEach((tag: Tag) => {
        tag.subtags?.forEach((subtag: NameLabel) => {
          this.tagLabels[`${tag.name}_${subtag.name}`] = subtag.label;
        });
      });
    } else {
      this.tagLabels = {};
      this.integrationLabels = {};
    }
  }

  /**
   * getTemplateTags
   * @param {any} template
   * @returns {string[]}
   * @memberof TemplateSearchComponent
   */
  public getTemplateTags(template: any): string[] {
    const tags = [];
    if (template.trendDefinition) {
      template.trendDefinition.integrations.forEach((integration: string) => {
        tags.push(this.integrationLabels[integration]);
      });
    } else if (this.integrationLabels[template.integration]) {
      tags.push(this.integrationLabels[template.integration]);
    }
    if (!isEmpty(this.tags)) {
      tags.push(...template.templateTags.map((tag) => this.tagLabels[tag]));
    }
    return tags;
  }

  /**
   * onToggleBookmark
   * @param {Template} template
   * @memberof TemplateSearchComponent
   */
  public onToggleBookmark(template: Template) {
    this.toggleBookmark.emit({
      templateId: template.id,
      bookmarked: !template.bookmarked,
    });
  }
}
