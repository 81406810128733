/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

/**
 * InfoTooltipComponent
 * @export
 * @class InfoTooltipComponent
 */
@Component({
  selector: 'dpa-info-tooltip',
  templateUrl: 'info-tooltip.component.html',
  styleUrls: ['info-tooltip.component.scss'],
})
export class InfoTooltipComponent {
  @Input() public tooltipAriaLabel: string;
  @Input() public tooltipText: string;
  @Input() public iconSize?: number = 24;
}
