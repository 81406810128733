/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { Category, CLARITY_TOOLTIP_POSITION, I18nMetadataHolder, IntegrationCategories } from '@ws1c/intelligence-models';

/**
 * Display List of Categories as button group so user can select on
 *
 * @export
 * @class CategorySelectorDropdownComponent
 */
@Component({
  selector: 'dpa-category-selector-dropdown',
  templateUrl: 'category-selector-dropdown.component.html',
  styleUrls: ['category-selector-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategorySelectorDropdownComponent {
  @Input() public selectedCategory: Category;
  @Input() public integrationCategories: IntegrationCategories[];
  @Output() public selectedCategoryChange = new EventEmitter<Category>();

  public isMainMenuOpen: boolean = false;

  public readonly CLARITY_TOOLTIP_POSITION = CLARITY_TOOLTIP_POSITION;

  private subMenuStatus = new Map();

  /**
   * onSelectedCategoryChange
   * @param {Category} category
   * @memberof CategorySelectorDropdownComponent
   */
  public onSelectedCategoryChange(category: Category) {
    if (category.isSameAs(this.selectedCategory)) {
      return;
    }
    this.selectedCategoryChange.emit(category);
  }

  /**
   * isIntegrationHighlighted
   * @param {I18nMetadataHolder} integration
   * @returns {boolean}
   * @memberof CategorySelectorDropdownComponent
   */
  public isIntegrationHighlighted(integration: I18nMetadataHolder): boolean {
    if (!this.selectedCategory) {
      return false;
    }
    // Don't highlight the selected category (integration) if a sub-menu is already open
    if (Array.from(this.subMenuStatus.values()).some(Boolean)) {
      return false;
    }
    return integration.name === this.selectedCategory.integration.name;
  }

  /**
   * isSubMenuOpen
   * @param {I18nMetadataHolder} integration
   * @returns {boolean}
   * @memberof CategorySelectorDropdownComponent
   */
  public isSubMenuOpen(integration: I18nMetadataHolder): boolean {
    return !!this.subMenuStatus.get(integration.name);
  }

  /**
   * onMainMenuOpenChange
   * @param {boolean} isOpen
   * @memberof CategorySelectorDropdownComponent
   */
  public onMainMenuOpenChange(isOpen: boolean) {
    this.isMainMenuOpen = isOpen;
    if (!isOpen) {
      this.subMenuStatus.clear();
    }
  }

  /**
   * onSubMenuOpenChange
   * @param {boolean} isOpen
   * @param {I18nMetadataHolder} integration
   * @memberof CategorySelectorDropdownComponent
   */
  public onSubMenuOpenChange(isOpen: boolean, integration: I18nMetadataHolder) {
    this.subMenuStatus.set(integration.name, isOpen);
  }
}
