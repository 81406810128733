/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { createAction, props } from '@ngrx/store';

/**
 * IncidentDashboardActions
 * @exports
 * @class IncidentDashboardActions
 */
export class IncidentDashboardActions {
  public static readonly CATEGORY = '[INCIDENT_DASHBOARD]';

  public static setIncidentId = createAction(`${IncidentDashboardActions.CATEGORY} SET_INCIDENT_ID`, props<{ incidentId: string }>());
  public static clearIncidentId = createAction(`${IncidentDashboardActions.CATEGORY} CLEAR_INCIDENT_ID`);
  public static requestRootUrl = createAction(`${IncidentDashboardActions.CATEGORY} REQUEST_ROOT_URL`);
  public static requestRootUrlAck = createAction(`${IncidentDashboardActions.CATEGORY} REQUEST_ROOT_URL_ACK`, props<{ rootUrl: string }>());
  public static resetRootUrl = createAction(`${IncidentDashboardActions.CATEGORY} RESET_ROOT_URL`);
  public static hideDeemHeader = createAction(`${IncidentDashboardActions.CATEGORY} HIDE_DEEM_HEADER`);
  public static showDeemHeader = createAction(`${IncidentDashboardActions.CATEGORY} SHOW_DEEM_HEADER`);
}
