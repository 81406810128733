<dpa-full-page [isOpen]="true">
  <div class="page-container">
    <div class="wizard-container pv-x8">
      <div class="back-button-container">
        <button
          (click)="onBackClick.emit()"
          class="btn btn-link back-button"
        >
          <cds-icon
            class="mr-x1"
            shape="angle"
            direction="left"
          ></cds-icon>
          <span>{{ 'COMMON_ACTIONS.BACK' | translate }}</span>
        </button>
        <span
          [ngClass]="{ visible: isSaveIndicatorVisible }"
          class="saved-text p3 mt-0"
        >
          {{ 'SOLUTIONS.PROGRESS_SAVED' | translate }}
          <cds-icon
            solid
            status="success"
            class="mh-x1"
            shape="success-standard"
          ></cds-icon>
        </span>
      </div>
      <h2>
        {{ title }}
      </h2>
      <span
        [innerHTML]="description"
        class="p1 mt-0"
      ></span>
      <div class="accordion-container">
        <ng-template [ngTemplateOutlet]="accordionWizardTemplate"></ng-template>
      </div>
    </div>
  </div>
</dpa-full-page>
