/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { DashboardConfig } from '@ws1c/intelligence-models';

/**
 * Returns platform icon class name
 * @export
 * @param {string} platform
 * @returns {string} icon class
 */
export function getIconClassByPlatform(platform: string): string {
  const iconClassByPlatform = DashboardConfig.iconClassByPlatform;
  return iconClassByPlatform[platform] || 'webapp';
}
