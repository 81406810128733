<div
  *ngIf="tags"
  class="dpa-card-tags"
>
  <ng-container *ngIf="tags.length === 1 || !combineTags; else multiTags">
    <div
      *ngFor="let tag of tags; trackBy: trackByTagName"
      class="tag label mt-x1"
    >
      {{ tag }}
    </div>
  </ng-container>
  <ng-template #multiTags>
    <div class="tags">
      <clr-dropdown>
        <button clrDropdownTrigger>
          <span class="label">{{ 'WIDGET_TEMPLATE.NUM_OF_TAGS' | translate: { num: tags.length } }}</span>
        </button>
        <clr-dropdown-menu
          *clrIfOpen
          [clrPosition]="position"
          class="ph-x2"
        >
          <ng-container *ngFor="let tag of tags; index as i; trackBy: trackByTagName">
            <div class="label m-x1">
              {{ tag }}
            </div>
          </ng-container>
        </clr-dropdown-menu>
      </clr-dropdown>
    </div>
  </ng-template>
</div>
