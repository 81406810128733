/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { FeatureControl } from '@ws1c/intelligence-common/constants/feature-control.enum';

/**
 * RoutePage
 * @export
 * @class RoutePage
 */
export class RoutePage {
  public titleKey?: string;
  public associatedTab?: any;
  public featureControls?: FeatureControl[];
  public hideOnAnyFeatureControl?: boolean;
  public disableLink?: boolean;
  public overrideParent?: string;

  /**
   * constructor
   * @param {Array<Partial<RoutePage>>} args
   * @memberof RoutePage
   */
  constructor(...args: Array<Partial<RoutePage>>) {
    Object.assign(this, ...args);
    return this;
  }

  /**
   * definePage
   * @param {string} titleKey
   * @param {Omit<RoutePage, 'titleKey'>} [extras={}]
   * @returns {RoutePage}
   * @memberof RoutePage
   */
  public static definePage(titleKey: string, extras: Omit<RoutePage, 'titleKey'> = {}): RoutePage {
    return new RoutePage({ titleKey, ...extras });
  }

  /**
   * defineChildPage
   * @param {string} titleKey
   * @param {any} associatedTab
   * @param {Omit<RoutePage, 'titleKey' | 'associatedTab'>} [extras={}]
   * @returns {RoutePage}
   * @memberof RoutePage
   */
  public static defineChildPage(
    titleKey: string,
    associatedTab: any,
    extras: Omit<RoutePage, 'titleKey' | 'associatedTab'> = {},
  ): RoutePage {
    return new RoutePage({ titleKey, associatedTab, ...extras });
  }
}
