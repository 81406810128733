<div class="solution-header mb-x8">
  <div class="left-side">
    <div
      [ngClass]="imageClass"
      class="bg-image deem-purple-book-man"
    ></div>
  </div>
  <div class="right-header">
    <h2 class="mt-0">
      {{ title }}
    </h2>
    <p class="mt-x2">
      {{ description }}
    </p>
  </div>
</div>

<div
  *ngIf="introTitle || introDescription"
  class="review-prereq"
>
  <h5 class="review-prereq-header mt-0">
    {{ introTitle }}
  </h5>
  <p class="review-prereq-desc p5 mt-1">
    {{ introDescription }}
  </p>
  <div class="mt-x2">
    <ng-template [ngTemplateOutlet]="introContentTemplate"></ng-template>
  </div>
</div>
<div class="step-card-container">
  <div
    *ngFor="let overviewStep of overviewStepsView"
    [class.disabled-card]="overviewStep.disabled || !isPrerequisitesComplete"
    class="step-card"
  >
    <cds-icon
      *ngIf="!overviewStep.incomplete"
      solid
      status="success"
      shape="success-standard"
    ></cds-icon>
    <div class="step-left-side">
      <p class="step-label mt-0">
        {{ overviewStep.label }}
      </p>
    </div>
    <div class="step-right-side">
      <span
        *ngIf="overviewStep.progressText"
        class="p5 mt-0"
      >
        {{ overviewStep.progressText }}
      </span>
      <button
        [routerLink]="overviewStep.route"
        [disabled]="isEditDisabled(overviewStep)"
        (click)="overviewStep.click && overviewStep.click()"
        queryParamsHandling="preserve"
        class="btn btn-outline btn-sm"
      >
        {{ (overviewStep.pristine ? 'COMMON_ACTIONS.START' : 'COMMON_ACTIONS.EDIT') | translate }}
      </button>
    </div>
  </div>
</div>

<div>
  <button
    [routerLink]="successRoute"
    [disabled]="isLaunchDisabled"
    (click)="finishClick.emit()"
    class="btn btn-primary mt-x4"
  >
    {{ finishButtonText || 'COMMON_ACTIONS.VIEW_DASHBOARD' | translate }}
  </button>
</div>
