/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { select } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { ExtendedNavigationExtras, RouterExtensionsBase } from '@ws1c/intelligence-models';

/**
 * RouterExtensions
 *
 * @export
 * @class RouterExtensions
 * @implements {RouterExtensions}
 */
@Injectable()
export class RouterExtensions implements RouterExtensionsBase {
  public router$: BehaviorSubject<Router> = new BehaviorSubject(this.router);
  public url$: Observable<string>;
  public previousUrl: string;

  /**
   * Creates an instance of RouterExtensions.
   * @param {Router} router
   * @param {LocationStrategy} locationStrategy
   * @memberof RouterExtensions
   */
  constructor(
    private router: Router,
    private locationStrategy: LocationStrategy,
  ) {
    this.previousUrl = this.router.url;
    this.router.events.subscribe(() => {
      this.router$.next(this.router);
    });
    this.url$ = this.router$.pipe(select((currentRouter: Router) => currentRouter.url));
  }

  /**
   *
   * @param {any[]} commands
   * @param {ExtendedNavigationExtras} [extras]
   * @returns {Promise<boolean>}
   * @memberof RouterExtensions
   */
  public navigate(commands: any[], extras?: ExtendedNavigationExtras): Promise<boolean> {
    this.previousUrl = this.router.url;
    return this.router.navigate(commands, extras);
  }

  /**
   * getNavigationUrl
   * Returns the URL as a string
   * @param {any[]} commands
   * @param {ExtendedNavigationExtras} [extras]
   * @returns {string>}
   * @memberof RouterExtensions
   */
  public getNavigationUrl(commands: any[], extras?: ExtendedNavigationExtras): string {
    const tree: UrlTree = this.router.createUrlTree(commands, extras);
    return this.router.serializeUrl(tree);
  }

  /**
   *
   * @param {(string | UrlTree)} url
   * @param {ExtendedNavigationExtras} [_options]
   * @returns {Promise<boolean>}
   * @memberof RouterExtensions
   */
  public navigateByUrl(url: string | UrlTree, _options?: ExtendedNavigationExtras): Promise<boolean> {
    this.previousUrl = this.router.url;
    return this.router.navigateByUrl(url);
  }

  /**
   * navigateToPreviousUrl
   * @returns {Promise<boolean>}
   * @memberof RouterExtensions
   */
  public navigateToPreviousUrl(): Promise<boolean> {
    return this.router.navigateByUrl(this.previousUrl);
  }

  /**
   *
   * @memberof RouterExtensions
   */
  public back() {
    this.locationStrategy.back();
  }
}
