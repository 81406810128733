/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * CardBlockHeaderComponent
 * @export
 * @class CardBlockHeaderComponent
 */
@Component({
  selector: 'dpa-card-block-header',
  templateUrl: 'card-block-header.component.html',
})
export class CardBlockHeaderComponent {}
