<div
  *ngIf="showSearch || groupCollections?.length > 1"
  class="control-container ph-x3 pb-x3"
>
  <div
    *ngIf="showSearch"
    class="ph-x1 pt-x2"
  >
    <h6
      *ngIf="searchTitle"
      class="mt-1"
    >
      {{ searchTitle }}
    </h6>
    <div class="clr-row mt-x1">
      <dpa-local-search
        [placeHolderText]="searchPlaceholder ?? ''"
        (onSearch)="onSearchQueryChange($event)"
        iconClass="search"
        class="clr-col"
      ></dpa-local-search>
    </div>
  </div>
  <div
    *ngIf="groupCollections?.length > 1"
    class="group-by mt-x3 ph-x1"
  >
    <h6 class="small-header mr-1">{{ 'COMMON_ACTIONS.GROUP_BY' | translate | titlecase }}:</h6>
    <clr-dropdown>
      <button
        clrDropdownTrigger
        type="button"
        class="btn-sm btn-link"
      >
        <span class="p3 mt-0">
          {{ selectedGroupCollection?.groupByLabel | translate }}
        </span>
        <cds-icon
          direction="down"
          shape="angle"
        ></cds-icon>
      </button>
      <clr-dropdown-menu
        *clrIfOpen
        [clrPosition]="dropdownPosition"
      >
        <button
          *ngFor="let groupCollection of groupCollections"
          clrDropdownItem
          (click)="onGroupCollectionChange(groupCollection)"
        >
          {{ groupCollection.groupByLabel | translate }}
        </button>
      </clr-dropdown-menu>
    </clr-dropdown>
  </div>
</div>
<div
  *ngIf="!isResultsVisible; else detailsView"
  [class.top-border]="showListDivider"
  class="list-wrapper"
>
  <div
    [class.divider]="!isSinglePane"
    class="list-container"
  >
    <div
      [class.details-list-visible]="selectedGroup && isSinglePane"
      class="list-container-inner"
    >
      <div class="group-list p-x4">
        <h6
          *ngIf="!isSinglePane && groupsTitleByGroupBy && !hideGroupPaneTitle"
          class="small-header mb-2"
        >
          {{ groupsTitleByGroupBy?.[selectedGroupCollection?.groupBy] | translate }}
        </h6>
        <ng-container *ngFor="let group of selectedGroupCollection?.groups; last as last">
          <dpa-list-item
            [isCompact]="true"
            [isBoxed]="applyBoxStyling"
            [isHoverable]="true"
            [isSelected]="!isSinglePane && selectedGroup?.name === group.name"
            [label]="group.label"
            [hasArrow]="showArrowWithCount"
            [arrowText]="showArrowWithCount ? group.items.length + '' : undefined"
            [iconTemplate]="iconTemplate"
            (itemClick)="setSelectedGroup(group)"
          ></dpa-list-item>
          <ng-template #iconTemplate>
            <ng-template
              [ngTemplateOutlet]="groupIconTemplate"
              [ngTemplateOutletContext]="{
                group,
                groupCollection: selectedGroupCollection
              }"
            ></ng-template>
          </ng-template>
        </ng-container>
      </div>
      <div
        *ngIf="isSinglePane"
        class="details-list"
      >
        <ng-template [ngTemplateOutlet]="detailsView"></ng-template>
      </div>
    </div>
  </div>
  <div
    *ngIf="!isSinglePane"
    class="details-list"
  >
    <ng-template [ngTemplateOutlet]="detailsView"></ng-template>
  </div>
</div>
<ng-template #detailsView>
  <div
    [class.top-border]="showListDivider && isResultsVisible"
    class="scroll-container"
  >
    <dpa-no-search-results
      *ngIf="isResultsVisible"
      [message]="'COMMON_MESSAGES.NO_RESULTS_FOUND' | translate"
      class="no-search-results mv-6"
    ></dpa-no-search-results>
    <div
      *ngIf="!isResultsVisible"
      class="scroll-content p-x4"
    >
      <div
        *ngIf="isSinglePane"
        class="canvas-details-header pv-x2"
      >
        <button
          (click)="selectedGroup = undefined"
          class="btn btn-sm btn-icon btn-link"
        >
          <cds-icon
            shape="arrow"
            direction="left"
            size="24"
          ></cds-icon>
        </button>
        <ng-template
          [ngTemplateOutlet]="groupIconTemplate"
          [ngTemplateOutletContext]="{
            group: selectedGroup,
            groupCollection: selectedGroupCollection
          }"
        ></ng-template>
        <h6 class="mt-0">
          {{ selectedGroup?.label }}
        </h6>
      </div>
      <h6
        *ngIf="!isSinglePane && itemsTitle"
        class="small-header pt-2"
      >
        {{ itemsTitle }}
      </h6>
      <div *ngFor="let item of searchResults ?? selectedGroup?.items; last as last">
        <dpa-list-item
          [isCompact]="hasCompactItems"
          [isHoverable]="hasCompactItems"
          [label]="(isResultsVisible ? item?.groupLabel + ' > ' : '') + item?.label"
          [description]="item?.description || 'Description goes here'"
          [actionsTemplate]="itemActionsTemplate"
          [item]="item"
          (click)="itemSelect.emit(item)"
          (keyup.enter)="itemSelect.emit(item)"
        ></dpa-list-item>
      </div>
    </div>
  </div>
</ng-template>
