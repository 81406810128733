/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { unsubscribe } from '@dpa/ui-common';
import { uniqueId } from 'lodash-es';
import { Subscription } from 'rxjs';

import { I18NService } from '@ws1c/intelligence-common/services';
import { ChronoUnit } from '@ws1c/intelligence-models';

/**
 * TimeUnitSelectorOption
 * @interface TimeUnitSelectorOption
 */
interface TimeUnitSelectorOption {
  unit: string;
  label: string;
}

/**
 * TimeUnitSelectorComponent
 * @implements {OnChanges}
 * @implements {OnDestroy}
 * @export
 * @class TimeUnitSelectorComponent
 */
@Component({
  selector: 'dpa-time-unit-selector',
  templateUrl: 'time-unit-selector.component.html',
  styleUrls: ['time-unit-selector.component.scss'],
})
export class TimeUnitSelectorComponent implements OnChanges, OnDestroy {
  @Input() public timeUnit: string;
  @Input() public readonly: boolean;
  @Output() public timeUnitChange: EventEmitter<string> = new EventEmitter<string>();

  public formControl: UntypedFormControl;
  public subs: Subscription[];

  public readonly options: TimeUnitSelectorOption[] = [
    {
      unit: ChronoUnit[ChronoUnit.MINUTES],
      label: this.i18nService.translate('COMMON_MESSAGES.MINUTES'),
    },
    {
      unit: ChronoUnit[ChronoUnit.SECONDS],
      label: this.i18nService.translate('COMMON_MESSAGES.SECONDS'),
    },
    {
      unit: ChronoUnit[ChronoUnit.MILLIS],
      label: this.i18nService.translate('COMMON_MESSAGES.MILLISECONDS'),
    },
  ];
  // setting name attributes to a uniqueId prevents radio buttons from interfering with other time selector components
  public readonly uniqueId: string = uniqueId();

  /**
   * constructor
   * @param {I18NService} i18nService
   */
  constructor(private i18nService: I18NService) {
    this.formControl = new UntypedFormControl(this.options[0]);
    this.subs = [
      this.formControl.valueChanges.subscribe(() => {
        this.onOptionSelect(this.formControl.value);
      }),
    ];
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof TimeUnitSelectorComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.timeUnit) {
      const selectedOption = this.options.find((option: TimeUnitSelectorOption) => option.unit === this.timeUnit);
      this.formControl.patchValue(selectedOption, { emitEvent: false });
    }
  }

  /**
   * ngOnDestroy
   * @memberof TimeUnitSelectorComponent
   */
  public ngOnDestroy() {
    unsubscribe(this.subs);
  }

  /**
   * onOptionSelect
   * @param {any} option
   * @memberof TimeUnitSelectorComponent
   */
  public onOptionSelect(option: TimeUnitSelectorOption) {
    this.timeUnitChange.emit(option ? option.unit : undefined);
  }
}
