/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to transform given URL string to a CSS 'url()' property
 *
 * @export
 * @class UrlPropertyPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'urlProperty',
  pure: true,
})
export class UrlPropertyPipe implements PipeTransform {
  /**
   * transform
   * @param {string} url
   * @returns {string}
   * @memberOf UrlPropertyPipe
   */
  public transform(url: string): string {
    return url ? `url(${url})` : null;
  }
}
