/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { FacetCount, MarketplaceResourceTag } from '@ws1c/intelligence-models';

/**
 * MarketplaceFilterTagsComponent
 * @export
 * @class MarketplaceFilterTagsComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-marketplace-filter-tags',
  templateUrl: 'marketplace-filter-tags.component.html',
  styleUrls: ['marketplace-filter-tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MarketplaceFilterTagsComponent implements OnChanges {
  @Input() public selectedTags: MarketplaceResourceTag[];
  @Input() public facetCounts: FacetCount[];
  @Output() public filterChange: EventEmitter<MarketplaceResourceTag[]> = new EventEmitter<MarketplaceResourceTag[]>();

  public selectedTagsMap: Map<string, Set<string>> = new Map<string, Set<string>>();

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   */
  public ngOnChanges(changes: SimpleChanges) {
    const selectedTags = changes.selectedTags?.currentValue;
    if (selectedTags) {
      this.selectedTagsMap.clear();
      selectedTags.forEach((selectedTag: MarketplaceResourceTag) => {
        this.selectedTagsMap.set(selectedTag.category, new Set(selectedTag.subCategories));
      });
    }
  }

  /**
   * isTagSelected
   * @param {string} category
   * @param {string} subCategory
   * @returns {boolean}
   * @memberof MarketplaceFilterTagsComponent
   */
  public isTagSelected(category: string, subCategory?: string): boolean {
    return subCategory ? !!this.selectedTagsMap.get(category)?.has(subCategory) : this.selectedTagsMap.has(category);
  }

  /**
   * toggleTag
   * @param {string} category
   * @memberof MarketplaceFilterTagsComponent
   */
  public toggleTag(category: string) {
    if (this.selectedTagsMap.has(category)) {
      this.selectedTagsMap.delete(category);
    } else {
      this.selectedTagsMap.set(category, new Set<string>());
    }
    this.filterChange.emit(this.selectedResourceTags);
  }

  /**
   * toggleSubTag
   * @param {string} category
   * @param {string} subCategory
   * @memberof MarketplaceFilterTagsComponent
   */
  public toggleSubTag(category: string, subCategory: string) {
    const tag = this.selectedTagsMap.get(category);
    if (!tag) {
      this.selectedTagsMap.set(category, new Set<string>([subCategory]));
    } else if (tag.has(subCategory)) {
      tag.delete(subCategory);
    } else {
      tag.add(subCategory);
    }
    // delete tag entry if no sub tags selected
    if (tag?.size === 0) {
      this.selectedTagsMap.delete(category);
    }
    this.filterChange.emit(this.selectedResourceTags);
  }

  /**
   * selectAllSubTags
   * @param {string} category
   * @memberof MarketplaceFilterTagsComponent
   */
  public selectAllSubTags(category: string) {
    const subFacets = this.facetCounts.find((facetCount: FacetCount) => facetCount.fieldValue === category)?.subFacetCounts;
    if (subFacets?.length) {
      this.selectedTagsMap.set(category, new Set<string>(subFacets.map((subFacet: FacetCount) => subFacet.fieldValue)));
    }
    this.filterChange.emit(this.selectedResourceTags);
  }

  /**
   * clearAllSubTags
   * @param {string} category
   * @param {Event} e
   * @memberof MarketplaceFilterTagsComponent
   */
  public clearAllSubTags(category: string, e?: Event) {
    e?.stopPropagation();
    this.selectedTagsMap.delete(category);
    this.filterChange.emit(this.selectedResourceTags);
  }

  /**
   * selectedResourceTags
   * @readonly
   * @type {MarketplaceResourceTag[]}
   * @memberof MarketplaceFilterTagsComponent
   */
  public get selectedResourceTags(): MarketplaceResourceTag[] {
    const tags = [];
    this.selectedTagsMap.forEach((subTags: Set<string>, category: string) => {
      tags.push(
        new MarketplaceResourceTag({
          category,
          subCategories: [...subTags],
        }),
      );
    });
    return tags;
  }

  /**
   * trackByFacetValue
   * @param {number} _index
   * @param {FacetCount} facetCount
   * @returns {string}
   * @memberof MarketplaceFilterTagsComponent
   */
  public trackByFacetValue(_index: number, facetCount: FacetCount): string {
    return facetCount.fieldValue;
  }
}
