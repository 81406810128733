/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

/**
 * Orientation
 * @export
 * @enum {string}
 */
export enum Orientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}
