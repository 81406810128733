/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, TemplateRef } from '@angular/core';

/**
 * AccordionWizardStepActionsComponent
 * @export
 * @class AccordionWizardStepActionsComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-accordion-wizard-step-actions',
  templateUrl: 'accordion-wizard-step-actions.component.html',
  styleUrls: ['accordion-wizard-step-actions.component.scss'],
})
export class AccordionWizardStepActionsComponent implements OnChanges, OnInit {
  @Input() public isStandaloneMode?: boolean;
  @Input() public isFinalStep?: boolean;
  @Input() public isReadOnlyMode?: boolean;

  @Input() public isNextLoading?: boolean;
  @Input() public isNextDisabled?: boolean;
  @Input() public isResetLoading?: boolean;
  @Input() public isResetDisabled?: boolean;

  @Input() public nextModalTitle?: string;
  @Input() public nextModalDesc?: string;
  @Input() public resetModalTitle?: string;
  @Input() public resetModalDesc?: string;

  @Input() public textTemplate?: TemplateRef<any>;

  @Output() public actionNext: EventEmitter<void> = new EventEmitter<void>();
  @Output() public actionReset: EventEmitter<void> = new EventEmitter<void>();

  public showReset: boolean = false;
  public isNextModalOpen: boolean = false;
  public isResetModalOpen: boolean = false;

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof AccordionWizardStepActionsComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes?.isResetLoading) {
      const { previousValue, currentValue }: Partial<SimpleChange> = { ...changes.isResetLoading };
      if (previousValue && !currentValue) {
        this.isResetModalOpen = false;
      }
    }
    if (changes?.isNextLoading) {
      const { previousValue, currentValue }: Partial<SimpleChange> = { ...changes.isNextLoading };
      if (previousValue && !currentValue) {
        this.isNextModalOpen = false;
      }
    }
  }

  /**
   * ngOnInit
   * @memberof AccordionWizardStepActionsComponent
   */
  public ngOnInit() {
    this.showReset = this.actionReset.observers.length > 0;
  }

  /**
   * showNextModal
   * @param {boolean} isOpen
   * @memberof AccordionWizardStepActionsComponent
   */
  public showNextModal(isOpen: boolean) {
    this.isNextModalOpen = isOpen;
  }

  /**
   * showResetModal
   * @param {boolean} isOpen
   * @memberof AccordionWizardStepActionsComponent
   */
  public showResetModal(isOpen: boolean) {
    this.isResetModalOpen = isOpen;
  }

  /**
   * onNext
   * @memberof AccordionWizardStepActionsComponent
   */
  public onNext() {
    this.actionNext.emit();
  }

  /**
   * onReset
   * @memberof AccordionWizardStepActionsComponent
   */
  public onReset() {
    this.actionReset.emit();
  }

  /**
   * onResetClick
   * @memberof AccordionWizardStepActionsComponent
   */
  public onResetClick() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.resetModalTitle || this.resetModalDesc ? this.showResetModal(true) : this.onReset();
  }

  /**
   * onNextClick
   * @memberof AccordionWizardStepActionsComponent
   */
  public onNextClick() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.nextModalTitle || this.nextModalDesc ? this.showNextModal(true) : this.onNext();
  }
}
