<div class="roles">
  <div
    *ngFor="let role of roles"
    [ngClass]="role.getNameLowercase()"
  >
    <dpa-tooltip
      [tooltipText]="role.label"
      [focusEnabled]="true"
    >
      <cds-icon
        [attr.shape]="role.getNameLowercase() | dpaIconName: '' : '-solid'"
        class="role-icon mr-x1"
      ></cds-icon>
    </dpa-tooltip>
  </div>
</div>
