/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * CardIconComponent
 * @export
 * @class CardIconComponent
 */
@Component({
  selector: 'dpa-card-icon',
  templateUrl: 'card-icon.component.html',
})
export class CardIconComponent {}
