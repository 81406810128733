/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash-es';

import { TemperatureUnit } from '@ws1c/intelligence-common/models/temperature-unit.enum';
import { I18NService } from '@ws1c/intelligence-common/services/i18n.service';

/**
 * FormattedTemperaturePipe
 * @export
 * @class FormattedTemperaturePipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'formattedTemperature' })
export class FormattedTemperaturePipe implements PipeTransform {
  public locale: string;

  /**
   * Creates an instance of FormattedTemperaturePipe.
   * @param {I18NService} i18nService
   * @memberof FormattedTemperaturePipe
   */
  constructor(private i18nService: I18NService) {
    this.locale = this.i18nService.getCurrentLocale();
  }

  /**
   * transform
   * @param {number} inputInFahrenheit
   * @param {number} [precision=2]
   * @param {number} [outputFormat=TemperatureUnit.Fahrenheit]
   * @returns {string}
   * @memberof FormattedTemperaturePipe
   */
  public transform(inputInFahrenheit: number, precision: number = 2, outputFormat: number = TemperatureUnit.Fahrenheit): string {
    if (!isNumber(inputInFahrenheit)) {
      return;
    }

    let numberInOutputUnit: number = inputInFahrenheit;
    let unit;
    switch (outputFormat) {
      case TemperatureUnit.Fahrenheit:
        unit = '\u00B0F';
        break;
      case TemperatureUnit.Celsius:
        numberInOutputUnit = (inputInFahrenheit - 32) / 1.8;
        unit = '\u00B0C';
        break;
    }

    return Number(numberInOutputUnit).toLocaleString(this.locale, { maximumFractionDigits: precision }) + unit;
  }
}
