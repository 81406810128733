/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */
// This is a copy from https://github.com/vdolek/angular-resize-event/tree/master/projects/angular-resize-event/src/lib

/**
 * ResizedEvent
 * @export
 * @class ResizedEvent
 */
export class ResizedEvent {
  public newRect: DOMRectReadOnly;
  public oldRect?: DOMRectReadOnly;
  public isFirst: boolean;

  /**
   * constructor
   * @param {DOMRectReadOnly} newRect
   * @param {DOMRectReadOnly} oldRect
   * @memberof ResizedDirective
   */
  constructor(newRect: DOMRectReadOnly, oldRect: DOMRectReadOnly | undefined) {
    this.newRect = newRect;
    this.oldRect = oldRect;
    this.isFirst = oldRect == null;
  }
}
