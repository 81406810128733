/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DateTimeFormat } from '@dpa/ui-common';

import { ContentType } from '@ws1c/intelligence-common/models';
import { IntegrationDetails, IntegrationHealthReport, OrgHealthMetric } from '@ws1c/intelligence-models';

/**
 * SolutionDatasourceUemComponent
 * display datasource status, domain, modified for the first of integration details by integration type
 * @export
 * @class SolutionDatasourceUemComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-solution-datasource-uem',
  templateUrl: 'solution-datasource-uem.component.html',
  styleUrls: ['solution-datasource-uem.component.scss'],
})
export class SolutionDatasourceUemComponent implements OnChanges {
  @Input() public datasource: IntegrationDetails;
  @Input() public syncStatus: IntegrationHealthReport;
  @Input() public learnMoreLink: string;
  @Input() public isStandaloneMode?: boolean = false;
  @Input() public isReadOnlyMode?: boolean = false;
  @Input() public isFinalStep?: boolean = false;
  @Output() public stepAccordion: EventEmitter<void> = new EventEmitter<void>();

  public etlHost: OrgHealthMetric;
  public lastCheckin: OrgHealthMetric;
  public readonly DateTimeFormat = DateTimeFormat;
  public readonly HOST_NAME = 'integration.statistics.etl.host.name';
  public readonly LAST_CHECKIN_TIME = 'integration.statistics.last.checkin.time';
  public readonly ContentType = ContentType;

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof SolutionDatasourceUemComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.syncStatus?.currentValue) {
      this.etlHost = changes.syncStatus.currentValue.metrics?.filter((m: OrgHealthMetric) => m.key === this.HOST_NAME)?.[0];
      this.lastCheckin = changes.syncStatus.currentValue.metrics?.filter((m: OrgHealthMetric) => m.key === this.LAST_CHECKIN_TIME)?.[0];
    }
  }

  /**
   * onButtonClick
   * @memberof SolutionDatasourceUemComponent
   */
  public onButtonClick() {
    this.stepAccordion.emit();
  }
}
