<form
  [formGroup]="schedulerForm"
  novalidate
  class="clr-form clr-form-horizontal"
>
  <span class="clr-required-mark">{{ 'COMMON_MESSAGES.REQUIRED' | translate }}</span>
  <div formGroupName="cronExpressionDetail">
    <div class="form-container">
      <div class="left-column">
        <div class="clr-form-control form-group">
          <label
            for="scheduleFrequency"
            class="clr-control-label required clr-required-mark"
          >
            {{ 'SCHEDULE_CUSTOMIZE.RECURRENCE' | translate }}
          </label>
          <dpa-type-ahead
            [selectedItem]="getTypeaheadSelectedItem('cronExpressionDetail.frequency', scheduleFrequencyDropdown)"
            [searchableItems]="scheduleFrequencyDropdown"
            [customFormatter]="formatter"
            [labelFormatter]="formatter"
            [keyBy]="keyBy"
            [isRequired]="true"
            [typeAheadId]="'scheduleFrequency'"
            (selectedItemChange)="onSelectFrequencyAttribute($event)"
            id="scheduleFrequency"
          ></dpa-type-ahead>
        </div>
        <ng-container [ngSwitch]="schedulerForm.get('cronExpressionDetail.frequency').value">
          <div
            *ngSwitchCase="frequencyEnum.HOURLY"
            class="clr-form-control form-group"
          >
            <label
              for="scheduleHours"
              class="clr-control-label required clr-required-mark"
            >
              {{ 'SCHEDULE_CUSTOMIZE.RECURRENCE_EVERY' | translate }}
            </label>
            <dpa-type-ahead
              [selectedItem]="getTypeaheadSelectedItem('cronExpressionDetail.hourly.interval', intervalDropdown)"
              [searchableItems]="intervalDropdown"
              [customFormatter]="formatter"
              [labelFormatter]="formatter"
              [keyBy]="keyBy"
              [isRequired]="true"
              [typeAheadId]="'scheduleHours'"
              (selectedItemChange)="onSelectIntervalAttribute($event)"
              id="scheduleHours"
            ></dpa-type-ahead>
          </div>
          <div
            *ngSwitchCase="frequencyEnum.WEEKLY"
            class="clr-form-control form-group"
            role="group"
            aria-labelledby="daysOfTheWeek"
          >
            <div
              class="clr-control-label required clr-required-mark"
              id="daysOfTheWeek"
            >
              {{ 'SCHEDULE_CUSTOMIZE.RECURRENCE_DAYS_OF_THE_WEEK' | translate }}
            </div>
            <div
              class="btn-group btn-sm"
              formGroupName="weekly"
            >
              <div
                *ngFor="let weekday of weekdays"
                class="checkbox btn"
              >
                <input
                  [attr.aria-label]="'SCHEDULE_CUSTOMIZE.WEEKDAY.' + weekday.label | translate"
                  [formControlName]="weekday.abbr"
                  [checked]="schedulerForm.get('cronExpressionDetail.weekly').value[weekday.abbr]"
                  (click)="onWeeklyIntervalChange(weekday.abbr, !schedulerForm.get('cronExpressionDetail.weekly').value[weekday.abbr])"
                  type="checkbox"
                  id="weekday-{{ weekday.abbr }}"
                  required
                  role="checkbox"
                />
                <label for="weekday-{{ weekday.abbr }}">
                  {{ 'SCHEDULE_CUSTOMIZE.WEEKDAY_FIRST_LETTER.' + weekday.label | translate }}
                </label>
              </div>
            </div>
          </div>
          <div
            *ngSwitchCase="frequencyEnum.MONTHLY"
            class="clr-form-control form-group"
          >
            <label
              for="scheduleMonthDays"
              class="clr-control-label required clr-required-mark"
            >
              {{ 'SCHEDULE_CUSTOMIZE.RECURRENCE_DAYS_OF_THE_MONTH' | translate }}
            </label>
            <ng-container *ngLet="getTypeaheadSelectedItem('cronExpressionDetail.monthly.dayOfMonth', dayOfMonthDropdown) as dayOfMonth">
              <dpa-type-ahead
                *ngIf="showDayOfMonthDropdownForOutOfRange || dayOfMonth; else outOfRangeDate"
                [selectedItem]="dayOfMonth"
                [searchableItems]="dayOfMonthDropdown"
                [customFormatter]="formatter"
                [labelFormatter]="formatter"
                [keyBy]="keyBy"
                [isRequired]="true"
                [typeAheadId]="'scheduleMonthDays'"
                (selectedItemChange)="onSelectDayOfMonthAttribute($event)"
                id="scheduleMonthDays"
              ></dpa-type-ahead>
              <ng-template #outOfRangeDate>
                <div>
                  {{ schedulerForm.get('cronExpressionDetail.monthly.dayOfMonth').value }}
                  <dpa-tooltip [tooltipText]="'REPORT_SCHEDULE.DIFFERENT_LOCALE' | translate">
                    <cds-icon
                      shape="info-circle"
                      size="16"
                    ></cds-icon>
                  </dpa-tooltip>
                  <a
                    (click)="showDayOfMonthDropdown()"
                    (keydown.enter)="showDayOfMonthDropdown()"
                    class="ml-x1 link-normal"
                  >
                    {{ 'COMMON_ACTIONS.CHANGE' | translate }}
                  </a>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="right-column">
        <div class="clr-form-control form-group">
          <label
            for="scheduleStartsAt"
            class="clr-control-label required clr-required-mark"
          >
            {{ getStartsAtLabel() }}
          </label>
          <div class="schedule-input">
            <dpa-date-time-picker
              *ngIf="cronExpressionDetail"
              [time]="schedulerForm.get('cronExpressionDetail.startTimeOfDay').value"
              [pickerType]="DATE_TIME_PICKER_TYPE.TIME"
              [placeholderText]="getStartsAtLabel()"
              [inputId]="'scheduleStartsAt'"
              (onSelectDate)="onTimeChange($event)"
              id="scheduleStartsAt"
            ></dpa-date-time-picker>
          </div>
        </div>
        <div
          class="clr-form-control form-group"
          role="radiogroup"
          aria-labelledby="recurrence-ends"
        >
          <div
            class="clr-control-label required clr-required-mark"
            id="recurrence-ends"
          >
            {{ 'SCHEDULE_CUSTOMIZE.RECURRENCE_ENDS' | translate }}
          </div>
          <div class="btn-group radio-date-group btn-sm schedule-input clr-row">
            <div class="radio btn clr-col-sm schedule-no-end-date-container">
              <input
                [value]="false"
                type="radio"
                formControlName="requiredEnDate"
                id="scheduleNoEndDate"
                checked
                required
              />
              <label
                (click)="onClearEnd()"
                (keyup.enter)="onClearEnd()"
                class="schedule-no-end-date-label"
                for="scheduleNoEndDate"
              >
                <dpa-ellipsis-with-tooltip>
                  {{ 'SCHEDULE_CUSTOMIZE.RECURRENCE_NO_END_DATE' | translate }}
                </dpa-ellipsis-with-tooltip>
              </label>
            </div>
            <div class="radio btn clr-col-sm schedule-no-end-date-container">
              <input
                [value]="true"
                type="radio"
                formControlName="requiredEnDate"
                id="endDateInput"
                required
              />
              <label
                class="end-date-label"
                for="endDateInput"
              >
                {{ endByText }}
              </label>
              <dpa-date-time-picker
                [date]="endDate"
                [pickerType]="DATE_TIME_PICKER_TYPE.DATE"
                [pickerClass]="'btn only-trigger'"
                [customText]="true"
                [placeholderText]="endByText"
                [inputId]="'endDateInputCalendar'"
                [min]="getMinEndBy()"
                (onSelectDate)="onEndByChange($event)"
              ></dpa-date-time-picker>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
