/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeType } from '@dpa/ui-common';

import { TreeViewItem } from '@ws1c/intelligence-models';

/**
 * TreeNodeComponent
 * @export
 * @class TreeNodeComponent
 */
@Component({
  selector: 'dpa-tree-node',
  templateUrl: './tree-node.component.html',
  styleUrls: ['./tree-node.component.scss'],
})
export class TreeNodeComponent {
  @Input() public treeViewItems?: TreeViewItem[] = [];
  @Input() public selectOnlyLeafNode?: boolean = true;
  @Input() public treeType?: TreeType = TreeType.SELECT;
  @Output() public selectedChange = new EventEmitter<TreeViewItem>();
  @Output() public expandedChange = new EventEmitter<TreeViewItem>();

  public TREE_TYPE = TreeType;
  /**
   * selectItem
   * @param {TreeViewItem} target
   * @memberof TreeNodeComponent
   */
  public selectItem(target: TreeViewItem) {
    this.selectedChange.emit(target);
  }

  /**
   * changeExpanded
   * @param {TreeViewItem} target
   * @memberof TreeNodeComponent
   */
  public changeExpanded(target: TreeViewItem) {
    this.expandedChange.emit(target);
  }

  /**
   * setExpanded
   * @param {TreeViewItem} target
   * @param {boolean} isExpanded
   * @memberof TreeNodeComponent
   */
  public setExpanded(target: TreeViewItem, isExpanded: boolean) {
    target.isExpanded = isExpanded;
    this.expandedChange.emit(target);
  }
}
