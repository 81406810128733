/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

/**
 * FileDragDropDirective
 * @export
 * @class FileDragDropDirective
 */
@Directive({
  selector: '[dpaFileDragDrop]',
})
export class FileDragDropDirective {
  @Input() public allowMultiple: boolean = false;
  @Input() public dragOverClass?: string = 'filedragover';
  @Input() public fileOfType?: string;
  @Output() public onTooManyFiles: EventEmitter<number> = new EventEmitter();
  @Output() public onWrongFileType: EventEmitter<string[]> = new EventEmitter();
  @Output() public onFileDrop?: EventEmitter<File[]> = new EventEmitter();
  private element: ElementRef;
  private fileDragFlag: boolean = false;

  /**
   * constructor
   * @param {ElementRef} el
   * @memberof FileDragDropDirective
   */
  constructor(el: ElementRef) {
    this.element = el;
  }

  /**
   * onDragOver
   * @param {any} e
   * @memberof FileDragDropDirective
   */
  @HostListener('dragover', ['$event'])
  public onDragOver(e: any) {
    e.preventDefault();
  }

  /**
   * onDragEnter
   * @memberof FileDragDropDirective
   */
  @HostListener('dragenter')
  public onDragEnter() {
    this.fileDragOver(true);
  }

  /**
   * onDragLeave
   * @memberof FileDragDropDirective
   */
  @HostListener('dragleave')
  public onDragLeave() {
    this.fileDragOver(false);
  }

  /**
   * onDrop
   * @param {*} e
   * @memberof FileDragDropDirective
   */
  @HostListener('drop', ['$event'])
  public onDrop(e: any) {
    e.preventDefault();
    this.onDragLeave();
    if (!this.onFileDrop.observers.length) {
      return;
    }
    const fileCount = (e.dataTransfer.items || e.dataTransfer.files).length;
    if (!fileCount) {
      return;
    }
    if (!this.allowMultiple && fileCount > 1) {
      this.onTooManyFiles.emit(fileCount);
      return;
    }
    let fileList: File[];
    if (e.dataTransfer.items) {
      // Edge, Chrome, Safari, Firefox
      fileList = Array.from(e.dataTransfer.items)
        .filter((item: any) => item && item.kind === 'file')
        .map((item: any) => item.getAsFile());
    } else {
      // IE11
      fileList = Array.from(e.dataTransfer.files);
    }
    if (this.fileOfType) {
      const fileTypes: string[] = fileList.map((file: File) => file.type);
      const allOfType = fileTypes.every((fileType: string) => fileType === this.fileOfType);
      if (!allOfType) {
        this.onWrongFileType.emit(fileTypes);
        return;
      }
    }
    this.onFileDrop.emit(fileList);
  }

  /**
   * fileDragOver
   * @param {boolean} isDragEnter
   * @memberof FileDragDropDirective
   */
  private fileDragOver(isDragEnter: boolean) {
    if (this.fileDragFlag !== isDragEnter) {
      const classList = this.element.nativeElement.classList;
      if (isDragEnter) {
        classList.add(this.dragOverClass);
      } else {
        classList.remove(this.dragOverClass);
      }
    }
    this.fileDragFlag = !this.fileDragFlag;
  }
}
