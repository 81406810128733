/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * SplitViewListItemLoadingComponent
 *
 * @export
 * @class SplitViewListItemLoadingComponent
 */
@Component({
  selector: 'dpa-split-view-list-item-loading',
  templateUrl: 'split-view-list-item-loading.component.html',
  styleUrls: ['split-view-list-item-loading.component.scss'],
})
export class SplitViewListItemLoadingComponent {}
