/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeFormat } from '@dpa/ui-common';
import moment from 'moment';

import { I18NService } from '@ws1c/intelligence-common/services/i18n.service';
import { TrendDateRange } from '@ws1c/intelligence-models';

/**
 * TrendDateRangeToString
 * @class TrendDateRangeToString
 * @export
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'trendDateRangeToString',
  pure: false,
})
export class TrendDateRangeToStringPipe implements PipeTransform {
  public labelKeysByDateUnit: Record<string, string> = {
    HOURS: 'COMMON_MESSAGES.LAST_COUNT_HOUR_S',
    DAYS: 'COMMON_MESSAGES.LAST_COUNT_DAY_S',
    WEEKS: 'COMMON_MESSAGES.LAST_COUNT_WEEK_S',
    MONTHS: 'COMMON_MESSAGES.LAST_COUNT_MONTH_S',
  };
  // frequence
  public labelKeysByFreqUnit: Record<string, string> = {
    HOURS: 'COMMON_MESSAGES.EVERY_COUNT_HOUR_S',
    DAYS: 'COMMON_MESSAGES.EVERY_COUNT_DAY_S',
    WEEKS: 'COMMON_MESSAGES.EVERY_COUNT_WEEK_S',
    MONTHS: 'COMMON_MESSAGES.EVERY_COUNT_MONTH_S',
  };
  public moment = moment;

  /**
   * constructor
   * @param {I18NService} i18nService
   * @memberof TrendDateRangeToString
   */
  constructor(public i18nService: I18NService) {}

  /**
   * transform
   * @param {TrendDateRange} trendDateRange
   * @param {boolean} showTime
   * @param {string} customDateRangeKey
   * @param {boolean} showFrequency
   * @returns {any}
   * @memberof TrendDateRangeToString
   */
  public transform(
    trendDateRange: TrendDateRange,
    showTime: boolean = false,
    customDateRangeKey: string = 'FILTER_VALUE.DATE_TO_DATE',
    showFrequency: boolean = false,
  ): any {
    if (!trendDateRange) {
      return;
    }
    if (trendDateRange.trendSpan) {
      const labelKey = showFrequency
        ? this.labelKeysByFreqUnit[trendDateRange.samplingFrequency.unit]
        : this.labelKeysByDateUnit[trendDateRange.trendSpan.unit];
      const count = showFrequency ? trendDateRange.samplingFrequency.duration : trendDateRange.trendSpan.duration;
      return this.i18nService.translate(labelKey, { count });
    } else {
      const date1 = this.getDateString(trendDateRange.startDateMillis, showTime);
      const date2 = this.getDateString(trendDateRange.endDateMillis, showTime);
      return this.i18nService.translate(customDateRangeKey, { date1, date2 });
    }
  }

  /**
   * getDateString
   * @param {number} timestamp
   * @param {boolean} showTime
   * @returns {string}
   * @memberof TrendDateRangeToString
   */
  public getDateString(timestamp: number, showTime: boolean = false): string {
    const formatDate = moment.localeData().longDateFormat(DateTimeFormat.MOMENT_DATE_FORMAT);
    const dateStr = this.moment(timestamp).format(formatDate);

    if (showTime) {
      const formatTime = moment.localeData().longDateFormat(DateTimeFormat.MOMENT_TIME_FORMAT);
      const timeStr = this.moment(timestamp).format(formatTime);

      return `${dateStr} ${timeStr}`;
    }

    return dateStr;
  }
}
