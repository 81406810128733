/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * CardComponent
 * @export
 * @class CardComponent
 */
@Component({
  selector: 'dpa-card',
  templateUrl: 'card.component.html',
  styleUrls: ['card.component.scss'],
})
export class CardComponent {
  @Input() public header: string;
  @Input() public onlyHeader?: boolean = false;
  @Input() public allowBookmark?: boolean = false;
  @Input() public description?: string;
  @Input() public descriptionMaxLines?: number = 5;
  @Input() public hasBookmark?: boolean = false;
  @Input() public selected?: boolean = false;
  @Input() public hasCustomLabel?: boolean = false;
  @Input() public tags?: string[] = [];
  @Output() public toggleBookmark: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Emits toggleBookmark event with toggled hasBookmark value.
   * @memberof CardComponent
   */
  public onToggleBookmark() {
    this.toggleBookmark.emit(!this.hasBookmark);
  }
}
