<div class="p5 mt-0">
  <cds-icon
    [attr.shape]="chartType | dpaIconName: '' : '-chart'"
    [attr.data-chart-type]="chartType"
    class="chart-type sm"
  ></cds-icon>
  <!--
    WIDGET_CUSTOMIZE.WIDGET_CHART_HORIZONTAL
    WIDGET_CUSTOMIZE.WIDGET_CHART_VERTICAL
    WIDGET_CUSTOMIZE.WIDGET_CHART_DONUT
    WIDGET_CUSTOMIZE.WIDGET_CHART_LINE
    WIDGET_CUSTOMIZE.WIDGET_CHART_METRIC
    WIDGET_CUSTOMIZE.WIDGET_CHART_TABLE
  -->
  <span>
    <strong>
      {{ 'WIDGET_CUSTOMIZE.WIDGET_CHART_' + chartType | translate }}
    </strong>
    <ng-container *ngIf="trendMode">
      &nbsp;-&nbsp;
      <!-- Possible Values
        'WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_HISTORICAL'
        'WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_SNAPSHOT'
      -->
      {{ 'WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_MODE_' + trendMode | translate }}
    </ng-container>
  </span>
</div>
