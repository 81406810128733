/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Directive, ViewContainerRef } from '@angular/core';

/**
 * dpaHostContainerDirective
 * @export
 * @class HostContainerDirective
 */
@Directive({
  selector: '[dpaHostContainer]',
})
export class HostContainerDirective {
  /**
   * Creates an instance of HostContainerDirective
   * @param {ViewContainerRef} viewContainerRef
   * @memberOf HostContainerDirective
   */
  constructor(public viewContainerRef: ViewContainerRef) {}
}
