/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Tooltip, TooltipSeverity } from '@ws1c/intelligence-models';

/**
 * TooltipViewerComponent
 * @export
 * @class TooltipViewerComponent
 */
@Component({
  selector: 'dpa-tooltip-viewer',
  templateUrl: 'tooltip-viewer.component.html',
  styleUrls: ['tooltip-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipViewerComponent {
  public TooltipSeverity = TooltipSeverity;

  @Input() public tooltip?: Tooltip;
}
