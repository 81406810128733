/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { ContentType } from '@ws1c/intelligence-common/models';

/**
 * Pipe to treat the content as safe
 *
 * @export
 * @class SafeContentPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'safe',
  pure: true,
})
export class SafeContentPipe implements PipeTransform {
  /**
   * Creates an instance of SafeContentPipe.
   * @param {DomSanitizer} sanitizer
   * @memberof SafeContentPipe
   */
  constructor(protected sanitizer: DomSanitizer) {}

  /**
   * **WARNING:** calling this method with untrusted user data exposes your application to XSS security risks!
   * NEVER use this for any user-input data.
   *
   * @param {string} value
   * @param {ContentType} type
   * @returns {(SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl)}
   * @memberof SafeContentPipe
   */
  public transform(value: string, type: ContentType): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case ContentType.HTML:
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case ContentType.STYLE:
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case ContentType.SCRIPT:
        return this.sanitizer.bypassSecurityTrustScript(value);
      case ContentType.URL:
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case ContentType.RESOURCE_URL:
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`SafeContentPipe unable to bypass security for invalid type: ${type}`);
    }
  }
}
