/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

/**
 * ListItemComponent
 * @export
 * @class ListItemComponent
 */
@Component({
  selector: 'dpa-list-item',
  templateUrl: 'list-item.component.html',
  styleUrls: ['list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
  @Input() public isCompact?: boolean = false;
  @Input() public isBoxed?: boolean = false;
  @Input() public isSelected?: boolean = false;
  @Input() public isHoverable?: boolean = false;
  @Input() public hasBottomBorder?: boolean = false;
  @Input() public label: string;
  @Input() public description?: string;
  @Input() public hasArrow?: boolean = false;
  @Input() public arrowText?: string;
  @Input() public item?: any;
  @Input() public iconShape?: string;
  @Input() public iconTemplate?: TemplateRef<any>;
  @Input() public actionsTemplate?: TemplateRef<any>;
  @Input() public footerTemplate?: TemplateRef<any>;
  @Output() public itemClick: EventEmitter<void> = new EventEmitter<void>();
}
