<div
  #tableContainer
  (scroll)="onScrollHandler()"
  class="table-container"
>
  <table
    class="table"
    role="presentation"
  >
    <thead>
      <ng-template
        [ngTemplateOutlet]="headerTemplate"
        [ngTemplateOutletContext]="{}"
      ></ng-template>
    </thead>
    <tbody>
      <ng-template
        *ngFor="let row of rows; trackBy: trackByIndex"
        [ngTemplateOutlet]="rowTemplate"
        [ngTemplateOutletContext]="{ item: row }"
      ></ng-template>
    </tbody>
  </table>
  <div
    *ngIf="!loading && !rows.length"
    class="no-results-container"
  >
    <div class="no-results-message">
      {{ 'WORKFLOWS.NO_WORKFLOW_LOGS_FOUND' | translate }}
    </div>
  </div>
  <div
    *ngIf="loading"
    class="spinner-container"
  >
    <span class="spinner"></span>
  </div>
</div>

<ng-template #defaultHeaderTemplate>
  <tr></tr>
</ng-template>

<ng-template
  #defaultRowTemplate
  let-item="item"
>
  <tr></tr>
</ng-template>
