/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { CookiesService } from './cookies.service';
import { DownloadService } from './download.service';
import { HttpService } from './http.service';
import { I18NService } from './i18n.service';
import { I18NToCommonService } from './i18nToCommon.service';
import { MapService } from './map.service';
import { RouterExtensions } from './router-extensions.service';

export const INTELLIGENCE_COMMON_PROVIDERS: any[] = [
  CookiesService,
  DownloadService,
  HttpService,
  I18NService,
  I18NToCommonService,
  MapService,
  RouterExtensions,
];

export * from './cookies.service';
export * from './download.service';
export * from './graphql.service';
export * from './http.service';
export * from './i18n.service';
export * from './i18nToCommon.service';
export * from './map.service';
export * from './router-extensions.service';
