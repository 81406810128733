/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { FuzzyItem, FuzzyService, GenericObject } from '@dpa/ui-common';
import { isEqual } from 'lodash-es';

import { CLARITY_TOOLTIP_POSITION, ItemGroup, ItemGroupCollection } from '@ws1c/intelligence-models';

/**
 * GroupedListComponent
 * A searchable list of grouped items, with single and dual pane configurations
 * @export
 * @class GroupedListComponent
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'dpa-grouped-list',
  templateUrl: 'grouped-list.component.html',
  styleUrls: ['grouped-list.component.scss'],
})
export class GroupedListComponent implements OnChanges, OnDestroy {
  @Input() public groupCollections: ItemGroupCollection[];
  @Input() public itemActionsTemplate?: TemplateRef<any>;
  @Input() public groupIconTemplate?: TemplateRef<any>;
  @Input() public searchTitle?: string;
  @Input() public searchPlaceholder?: string;
  @Input() public groupsTitleByGroupBy?: Record<string, string>;
  @Input() public itemsTitle?: string;
  @Input() public isSinglePane?: boolean = false;
  @Input() public hideGroupPaneTitle?: boolean = false;
  @Input() public hasCompactItems?: boolean = false;
  @Input() public showListDivider?: boolean = false;
  @Input() public showSearch?: boolean = true;
  @Input() public showArrowWithCount?: boolean = true;
  @Input() public applyBoxStyling?: boolean = true;

  @Output() public itemSelect = new EventEmitter<GenericObject>();

  public selectedGroupCollection: ItemGroupCollection;
  public selectedGroup: ItemGroup;
  public searchResults: GenericObject[];
  public isResultsVisible: boolean = false;
  public currentSearchQuery: string = '';
  public dropdownPosition: string = CLARITY_TOOLTIP_POSITION.TOP_RIGHT;

  private timer: ReturnType<typeof setTimeout>;

  /**
   * Creates an instance of GroupedListComponent.
   * @param {FuzzyService} fuzzyService
   * @param {ElementRef} elRef
   * @memberof GroupedListComponent
   */
  constructor(
    private fuzzyService: FuzzyService,
    private elRef: ElementRef,
  ) {}

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof GroupedListComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.groupCollections?.currentValue) {
      this.refreshSelectedValues();
    }
  }

  /**
   * ngOnDestroy
   * @memberof GroupedListComponent
   */
  public ngOnDestroy() {
    clearTimeout(this.timer);
  }

  /**
   * onGroupCollectionChange
   * @param {TemplateGroupBy} groupCollection
   * @memberof GroupedListComponent
   */
  public onGroupCollectionChange(groupCollection: ItemGroupCollection) {
    this.refreshSelectedValues(groupCollection);
  }

  /**
   * refreshSelectedValues
   * @param {ItemGroupCollection} [groupCollection]
   * @memberof GroupedListComponent
   */
  public refreshSelectedValues(groupCollection?: ItemGroupCollection) {
    this.selectedGroupCollection =
      this.groupCollections?.find((collection: ItemGroupCollection) => {
        return isEqual(collection, groupCollection);
      }) ?? this.groupCollections[0];
    this.selectedGroup = !this.isSinglePane ? this.selectedGroupCollection.groups[0] : undefined;
    if (this.currentSearchQuery) {
      this.onSearchQueryChange(this.currentSearchQuery);
    }
  }

  /**
   * onSearchQueryChange
   * @param {string} query
   * @memberof GroupedListComponent
   */
  public onSearchQueryChange(query: string) {
    this.currentSearchQuery = query;
    this.isResultsVisible = !!query;
    if (!query) {
      this.searchResults = undefined;
      return;
    }
    const items = this.selectedGroupCollection.groups.reduce(
      (accum: GenericObject[], group: ItemGroup) => [
        ...accum,
        ...group.items.map((item: GenericObject) => {
          const label = item.label;
          return {
            ...item,
            groupLabel: group.label,
            label,
          };
        }),
      ],
      [],
    );
    const fuzzyResults = this.fuzzyService.sortWithKeys(query, items, ['label', 'groupLabel']);
    this.searchResults = fuzzyResults.map((fuzzyItem: FuzzyItem) => ({
      ...fuzzyItem.original,
      [fuzzyItem.matchedKey]: fuzzyItem.styledString,
    }));
  }

  /**
   * setSelectedGroup
   * @param {ItemGroup} group
   * @memberof GroupedListComponent
   */
  public setSelectedGroup(group: ItemGroup) {
    this.selectedGroup = group;
    if (!this.isSinglePane) {
      return;
    }
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const node = this.elRef.nativeElement.querySelector('.canvas-details-header');
      node?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 100);
  }
}
