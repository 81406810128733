/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WebError } from '@dpa/ui-common';

/**
 * WebErrorComponent
 * @export
 * @class WebErrorComponent
 */
@Component({
  selector: 'dpa-web-error',
  templateUrl: 'web-error.component.html',
  styleUrls: ['web-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebErrorComponent {
  @Input() public webError: WebError;
}
