/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ClrSelectedState } from '@clr/angular';
import { breadthFirstBy, FuzzyItem, FuzzyService, reverseBreadthFirstBy, TreeType } from '@dpa/ui-common';
import { cloneDeep, find, findIndex, remove } from 'lodash-es';

import { TreeViewItem } from '@ws1c/intelligence-models';

/**
 * Tree View Component
 * Usage
 *  <dpa-old-tree-view [treeObj]="items" [(selectedColumns)]="newColumns" displaySearch="true"></dpa-old-tree-view>
 * TreeViewComponent
 * @export
 * @class TreeViewComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-old-tree-view',
  templateUrl: 'tree-view.component.html',
  styleUrls: ['tree-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreeViewComponent implements OnChanges {
  @Input() public treeObj?: TreeViewItem[] = [];
  @Input() public selectedColumns?: TreeViewItem[] = [];
  @Input() public displaySearch?: boolean = true;
  @Input() public searchString?: string = '';
  @Input() public selectOnlyLeafNode?: boolean = true;
  @Input() public treeType?: TreeType = TreeType.SELECT;
  @Output() public selectedChange = new EventEmitter<TreeViewItem[]>();
  @Output() public expandedChange = new EventEmitter<TreeViewItem>();

  private treeObjCopy: TreeViewItem[] = [];

  /**
   * constructor
   * @param {FuzzyService} fuzzyService
   * @memberof TreeViewComponent
   */
  constructor(private fuzzyService: FuzzyService) {}

  /**
   * Reset selectedColumns and take new copy of the tree object
   * @param {SimpleChanges} changes
   * @memberof TreeViewComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.treeObj) {
      this.treeObjCopy = cloneDeep(this.treeObj);
    }
    if (changes.selectedColumns) {
      this.selectedColumns.splice(0, this.selectedColumns.length);
    }
    if (changes.searchString) {
      this.onSearchAvailableColumns(this.searchString);
    }
  }

  /**
   * checkForChanges
   * @param {TreeViewItem} target
   * @memberof TreeViewComponent
   */
  public checkForChanges(target: TreeViewItem) {
    const fromSelectedColumn = find(this.selectedColumns, (newColumn) => newColumn.name === target.name);
    if (target.selected === ClrSelectedState.SELECTED) {
      // Don't allow duplicates
      if (!this.selectOnlyLeafNode && fromSelectedColumn) {
        return;
      }
      this.selectedColumns.push(target);
    } else if (fromSelectedColumn) {
      this.selectedColumns.splice(
        findIndex(this.selectedColumns, (node) => node === target),
        1,
      );
    }
  }

  /**
   * onSearchAvailableColumns
   * @param {string} query
   * @memberof TreeViewComponent
   */
  public onSearchAvailableColumns(query: string) {
    this.searchString = query;
    this.treeObj = cloneDeep(this.treeObjCopy);
    if (query) {
      this.filterItems(this.treeObj, query);
    }
  }

  /**
   * filterItems - fuzzy search w/ sorting
   * @param {TreeViewItem[]} treeItems
   * @param {string} query
   * @memberof TreeViewComponent
   */
  public filterItems(treeItems: TreeViewItem[], query: string) {
    const treeRoot = { children: treeItems } as TreeViewItem;
    const flatTreeItems = [];
    breadthFirstBy(treeRoot, 'children', (node: TreeViewItem) => flatTreeItems.push(node));

    const treeNodesToSkipRemoving = new Set<TreeViewItem>();
    const fuzzyItemsByOriginal = new Map<TreeViewItem, FuzzyItem>();
    this.fuzzyService
      .filter(query, flatTreeItems, (item) => item.label || '')
      .forEach((fuzzyItem: FuzzyItem) => {
        treeNodesToSkipRemoving.add(fuzzyItem.original);
        fuzzyItem.original.styledString = fuzzyItem.styledString;
        fuzzyItemsByOriginal.set(fuzzyItem.original, fuzzyItem);
      });

    reverseBreadthFirstBy(treeRoot, 'children', (node: TreeViewItem) => {
      remove(node.children, (childNode: TreeViewItem) => !treeNodesToSkipRemoving.has(childNode));

      // Skip removing node if it has children
      if (node.children && node.children.length) {
        node.children.sort((node1: TreeViewItem, node2: TreeViewItem) => {
          const fuzzyItem1 = fuzzyItemsByOriginal.get(node1);
          const fuzzyItem2 = fuzzyItemsByOriginal.get(node2);

          // If node is missing from fuzzyItemsByOriginal, the node was filtered out by fuzzyService
          if (!fuzzyItem1) {
            return 1;
          }
          if (!fuzzyItem2) {
            return -1;
          }

          return fuzzyItem1.editDistance - fuzzyItem2.editDistance;
        });
        treeNodesToSkipRemoving.add(node);
      }
    });
  }

  /**
   * checkForExpanded
   * @param {TreeViewItem} target
   * @memberof TreeViewComponent
   */
  public checkForExpanded(target: TreeViewItem) {
    this.expandedChange.emit(target);
  }
}
