/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Directive } from '@angular/core';

/**
 * TimelineContentDirective
 * @exports
 * @class TimelineContentDirective
 */
@Directive({
  selector: '[dpaTimelineContent]',
})
export class TimelineContentDirective {}
