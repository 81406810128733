/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { DpaUiCommonModule } from '@dpa/ui-common';
import { TranslateModule } from '@ngx-translate/core';

import { AccordionWizardOverviewPageComponent } from './accordion-wizard-overview-page/accordion-wizard-overview-page.component';
import { AccordionWizardStepComponent } from './accordion-wizard-step/accordion-wizard-step.component';
import { AccordionWizardStepActionsComponent } from './accordion-wizard-step-actions/accordion-wizard-step-actions.component';
import { AccordionWizardComponent } from './accordion-wizard.component';
import { AccordionWizardService } from './services/accordion-wizard.service';

/**
 * AccordionWizardModule
 * @export
 * @class AccordionWizardModule
 */
@NgModule({
  imports: [CommonModule, ClarityModule, TranslateModule, DpaUiCommonModule, RouterModule],
  providers: [AccordionWizardService],
  declarations: [
    AccordionWizardComponent,
    AccordionWizardStepComponent,
    AccordionWizardStepActionsComponent,
    AccordionWizardOverviewPageComponent,
  ],
  exports: [
    AccordionWizardComponent,
    AccordionWizardStepComponent,
    AccordionWizardStepActionsComponent,
    AccordionWizardOverviewPageComponent,
  ],
})
export class AccordionWizardModule {}
