<div
  *ngIf="!readonly; else trendDateRangeDisplay"
  class="date-range-container clr-row"
>
  <div
    [class.clr-col-4]="splitCol"
    class="date-typeahead-container clr-col"
  >
    <dpa-type-ahead
      [selectedItem]="selectedTrendSpanOption"
      [searchableItems]="trendSpanOptions"
      [customFormatter]="typeAheadformatter"
      [labelFormatter]="typeAheadformatter"
      [sortResults]="false"
      [placeholderText]="'COMMON_MESSAGES.DATE_RANGE' | translate"
      (selectedItemChange)="onTrendSpanOptionChange($event)"
      (onQueryChange)="onQueryChange($event)"
    ></dpa-type-ahead>
  </div>
  <ng-container *ngIf="selectedTrendSpanOption === customTrendSpanOption">
    <div
      [class.clr-col-4]="splitCol"
      class="clr-col"
    >
      <div class="date-time-container">
        <dpa-date-time-picker
          [pickerType]="DATE_TIME_PICKER_TYPE.DATE_TIME_RANGE"
          [dates]="defaultCustomDateRange"
          [min]="minStartDate"
          [max]="maxEndDate"
          [maxRangeInMillis]="maxRangeInMillis"
          [inputId]="'date-range-picker'"
          (onSelectDate)="onCustomRangeChange($event)"
          class="date-text-field"
        ></dpa-date-time-picker>
      </div>
    </div>
  </ng-container>
</div>
<div
  *ngIf="customTimePeriodHelpText && selectedTrendSpanOption === customTrendSpanOption"
  class="clr-subtext"
>
  {{ customTimePeriodHelpText }}
</div>
<ng-template #trendDateRangeDisplay>
  <div *ngIf="selectedTrendSpanOption === customTrendSpanOption; else trendOptionDisplay">
    <ng-container *ngTemplateOutlet="readonlyCalendarIcon"></ng-container>
    <span>
      {{ trendDateRange?.startDateMillis | formattedDate }}
      -
    </span>
    <span>
      {{ trendDateRange?.endDateMillis | formattedDate }}
    </span>
  </div>
  <ng-template #trendOptionDisplay>
    <div *ngIf="selectedTrendSpanOption?.label">
      <ng-container *ngTemplateOutlet="readonlyCalendarIcon"></ng-container>
      {{ selectedTrendSpanOption?.label }}
    </div>
  </ng-template>
</ng-template>
<ng-template #readonlyCalendarIcon>
  <cds-icon
    *ngIf="showReadonlyCalendarIcon"
    shape="calendar"
    size="18"
    class="calendar-icon"
  ></cds-icon>
</ng-template>
