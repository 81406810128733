/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { GenericObject } from '@dpa/ui-common';

import { Tag, Template } from '@ws1c/intelligence-models';

/**
 * GenericListComponent
 * A searchable, filterable, single column list with items of a given child template
 * @export
 * @class GenericListComponent
 */
@Component({
  selector: 'dpa-generic-list',
  templateUrl: 'generic-list.component.html',
  styleUrls: ['generic-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GenericListComponent {
  @Input() public items: Template[];
  @Input() public isSearchable?: boolean = false;
  @Input() public isFilterable?: boolean = false;
  @Input() public filterTags?: Tag[];
  @Input() public searchPlaceholder?: string;
  @Input() public showListDivider?: boolean = false;
  @Output() public filterChange: EventEmitter<GenericObject> = new EventEmitter();
  @Output() public searchQueryChange: EventEmitter<string> = new EventEmitter();

  @ContentChild(TemplateRef) public itemTemplate: TemplateRef<any>;
}
