/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject, TrendSpan } from '@dpa/ui-common';

import { ROUTE_NAMES } from '@ws1c/intelligence-models/app.routes.names';
import { ChronoUnit } from '@ws1c/intelligence-models/dashboard/chrono-unit.enum';
import { TrendDateRange } from '@ws1c/intelligence-models/dashboard/trend-date-range.model';
import { UserDetailPageType } from '@ws1c/intelligence-models/dashboard/user-profile-dashboard.enum';
import { UserProfileTabType } from '@ws1c/intelligence-models/dashboard/user-profile-tab-type.enum';

/**
 * UserProfileConfig
 * @export
 * @class UserProfileConfig
 */
export class UserProfileConfig {
  public static readonly UserDetailPageConfig: Record<string, GenericObject> = {
    [UserDetailPageType.USER_PROFILE]: {
      path: ROUTE_NAMES.DASHBOARD.USER_PROFILE,
      tab: UserProfileTabType.OVERVIEW.toLowerCase(),
      trendDateRange: new TrendDateRange({
        trendSpan: new TrendSpan({
          duration: 24,
          unit: ChronoUnit[ChronoUnit.HOURS],
        }),
        samplingFrequency: new TrendSpan({
          duration: 1,
          unit: ChronoUnit[ChronoUnit.HOURS],
        }),
      }),
    },
    [UserDetailPageType.DEEM_USER]: {
      path: ROUTE_NAMES.SOLUTIONS_DEEM_SUMMARY_PHYSICAL_USER,
      trendDateRange: new TrendDateRange({
        trendSpan: new TrendSpan({
          duration: 4,
          unit: ChronoUnit[ChronoUnit.HOURS],
        }),
        samplingFrequency: new TrendSpan({
          duration: 1,
          unit: ChronoUnit[ChronoUnit.HOURS],
        }),
      }),
    },
  };
}
