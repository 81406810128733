/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

import { DataAccessPolicy } from '@ws1c/intelligence-models';

/**
 * Component to data access policy label
 * @export
 * @class DataAccessPolicyLabelComponent
 */
@Component({
  selector: 'dpa-data-access-policy-label',
  templateUrl: 'data-access-policy-label.component.html',
  styleUrls: ['data-access-policy-label.component.scss'],
})
export class DataAccessPolicyLabelComponent {
  @Input() public dataAccessPolicies?: DataAccessPolicy[] = [];
  @Input() public defaultPermitAllSystemId: string;
}
