/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { FuzzyItem, FuzzyService, PagedRequest, PagedResponse, SortOn } from '@dpa/ui-common';
import { each, intersection, isEmpty, map, orderBy } from 'lodash-es';

import {
  Column,
  COLUMN_NAMES,
  DataGridColumn,
  LayoutType,
  LodashSortOrder,
  NameLabel,
  ReportColumnMapper,
  ReportTemplateType,
  Tag,
  TAGS_TYPE,
  TemplateAction,
} from '@ws1c/intelligence-models';

/**
 * Template Search Grid List Component
 * @export
 * @class TemplateSearchGridListComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-template-search-grid-list',
  templateUrl: 'template-search-grid-list.component.html',
  styleUrls: ['template-search-grid-list.component.scss'],
})
export class TemplateSearchGridListComponent implements OnInit, OnChanges {
  @Input() public results: any[];
  @Input() public pageSize?: number = 10;
  @Input() public isLoading = false;
  @Input() public isCardView?: boolean = false;
  @Input() public sortOns?: SortOn[] = [];
  @Input() public filterTags?: Tag[];
  @Input() public selectedTag: string;
  @Input() public searchPlaceholder?: string;
  @Input() public orderByArray?: NameLabel[] = [];
  @Input() public searchOn?: string = COLUMN_NAMES.byName.name;
  @Input() public searchExact?: boolean = false;
  @Input() public customContentTemplate?: TemplateRef<any>;
  @Input() public selectedRows?: any[] = null;
  // For grid view
  @Input() public cardTemplate?: TemplateRef<any>;
  // For List View
  @Input() public cellTemplatesByColumnValue?: Record<string, TemplateRef<any>> = {};
  @Input() public availableColumns?: Column[];
  @Input() public columnWidthByKey?: { [key: string]: number } = {};
  @Input() public actions?: TemplateAction[] = [];
  @Input() public updateUrlLayoutParam?: boolean = false;
  @Input() public tableName?: string;

  public pagedRequest: PagedRequest;
  public selectedLayout: LayoutType;
  public availableLayouts: LayoutType[] = [LayoutType.CARD_VIEW, LayoutType.GRID_VIEW];
  public query: string = '';
  public filter: any = {};
  public response: PagedResponse = {
    size: 0,
    from: 0,
    total: 0,
    results: [],
  };
  public dataGridColumns: DataGridColumn[];
  public showSwitcher: boolean = true;
  public styledStrings: any = {};
  public TAGS_TYPE = TAGS_TYPE;
  public defaultSelectedTag?: Tag;

  /**
   * Creates an instance of TemplateSearchGridListComponent.
   * @param {FuzzyService} fuzzyService
   * @memberof TemplateSearchGridListComponent
   */
  constructor(private fuzzyService: FuzzyService) {
    this.selectedLayout = LayoutType.CARD_VIEW;
  }

  /**
   * ngOnInit
   * @memberof TemplateSearchGridListComponent
   */
  public ngOnInit() {
    if (!this.cardTemplate) {
      this.isCardView = false;
      this.showSwitcher = false;
    }
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof TemplateSearchGridListComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.availableColumns && this.availableColumns) {
      this.dataGridColumns = ReportColumnMapper.getDataGridColumns(this.availableColumns);
      if (isEmpty(this.availableColumns)) {
        this.isCardView = true;
        this.showSwitcher = false;
      }
    }
    // only for the first call.
    // handling this here since applyAll requires pagedRequest and results will be available during the first call
    if (changes.pageSize && !this.pagedRequest) {
      this.pagedRequest = new PagedRequest({
        from: 0,
        size: this.pageSize,
      });
    }
    if (changes.results && this.results) {
      this.applyAll();
    }

    if (changes.selectedTag || changes.filterTags) {
      this.defaultSelectedTag = this.selectedTag ? this.filterTags?.find((tag: Tag) => tag.name === this.selectedTag) : undefined;
    }
  }

  /**
   * onPageChange
   * @param {PagedRequest} pagedRequest
   * @memberof TemplateSearchGridListComponent
   */
  public onPageChange(pagedRequest: PagedRequest) {
    this.pagedRequest = pagedRequest;
    this.applyAll();
  }

  /**
   * onOrderByChange
   * @param {NameLabel} order
   * @memberof TemplateSearchGridListComponent
   */
  public onOrderByChange(order?: NameLabel) {
    if (order) {
      this.onSortChange([
        new SortOn({
          by: order.name,
          reverse: false,
        }),
      ]);
    } else {
      this.onSortChange([]);
    }
  }

  /**
   * orderByFormatter
   * @param {NameLabel} order
   * @returns {string}
   * @memberof TemplateSearchGridListComponent
   */
  public orderByFormatter(order: NameLabel): string {
    return order.label;
  }

  /**
   * onSortChange
   * @param {SortOn[]} sortOns
   * @memberof TemplateSearchGridListComponent
   */
  public onSortChange(sortOns: SortOn[]) {
    this.sortOns = sortOns;
    this.applyAll();
  }

  /**
   * onQueryChanged
   * @param {string} query
   * @memberof TemplateSearchGridListComponent
   */
  public onQueryChanged(query: string) {
    this.query = query;
    this.applyAll();
  }

  /**
   * onFilterChange
   * @param {any} filter
   * @memberof TemplateSearchGridListComponent
   */
  public onFilterChange(filter: any) {
    this.filter = filter;
    this.applyAll();
  }

  /**
   * onLayoutChange
   * @param {LayoutType} selectedViewLayout
   * @memberof TemplateSearchGridListComponent
   */
  public onLayoutChange(selectedViewLayout: LayoutType) {
    this.isCardView = selectedViewLayout === LayoutType.CARD_VIEW;
    this.selectedLayout = selectedViewLayout;
  }

  /**
   * Search -> Filter -> Sort -> Paginate on results to prepare the response
   * @memberof TemplateSearchGridListComponent
   */
  private applyAll() {
    // Apply search query
    const fuzzyItems: FuzzyItem[] = this.searchExact
      ? this.fuzzyService.filterSort(this.query, this.results, (result: any) => result[this.searchOn])
      : this.fuzzyService.filter(this.query, this.results, (result: any) => result[this.searchOn]);
    this.styledStrings = fuzzyItems.reduce((acc: any, fuzzyItem: FuzzyItem) => {
      return {
        ...acc,
        [fuzzyItem.subject]: fuzzyItem.styledString,
      };
    }, {});
    let resultsForDisplay = map(fuzzyItems, 'original');
    // Apply filter
    each(this.filter, (filter, key) => {
      resultsForDisplay = resultsForDisplay.filter((result) => {
        switch (filter.type) {
          case TAGS_TYPE.INTEGRATION:
            return filter.selectedSubTags.includes(result.integration);
          case TAGS_TYPE.BOOLEAN:
            return result[filter.name];
          case TAGS_TYPE.CUSTOM_TEMPLATE:
            return result[filter.name] === ReportTemplateType.CUSTOM;
          default:
            return result.tags && result.tags[key] && intersection(result.tags[key], filter.selectedSubTags).length > 0;
        }
      });
    });
    // Adjust page number
    if (resultsForDisplay.length <= this.pagedRequest.from) {
      this.pagedRequest.from = 0;
    }
    // Sort
    if (!isEmpty(this.sortOns)) {
      this.sortOns.forEach((sortOn: SortOn) => {
        resultsForDisplay = orderBy(resultsForDisplay, [sortOn.by], sortOn.reverse ? LodashSortOrder.DESC : LodashSortOrder.ASC);
      });
    }

    // Paginate
    this.response = {
      from: this.pagedRequest.from,
      size: this.pagedRequest.size,
      results: resultsForDisplay.slice(this.pagedRequest.from, this.pagedRequest.from + this.pagedRequest.size),
      total: resultsForDisplay.length,
    };
  }
}
