/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { DpaUiCommonModule } from '@dpa/ui-common';
import { TranslateModule } from '@ngx-translate/core';
import { QuillModule } from 'ngx-quill';

import { AccordionWizardModule } from '@ws1c/intelligence-common/accordion-wizard/accordion-wizard.module';
import { INTELLIGENCE_COMMON_COMPONENTS } from './components';
import { INTELLIGENCE_COMMON_DIRECTIVES } from './directives';
import { GraphQLModule } from './graphql.module';
import { PipesModule } from './pipes/pipes.module';
import { INTELLIGENCE_COMMON_PROVIDERS } from './services';

/**
 * IntelligenceCommonModule
 * @export
 * @class IntelligenceCommonModule
 */
@NgModule({
  declarations: [...INTELLIGENCE_COMMON_COMPONENTS, ...INTELLIGENCE_COMMON_DIRECTIVES],
  imports: [
    AccordionWizardModule,
    CommonModule,
    ClarityModule,
    DpaUiCommonModule,
    DragDropModule,
    GraphQLModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    QuillModule,
  ],
  exports: [
    AccordionWizardModule,
    CommonModule,
    ClarityModule,
    DpaUiCommonModule,
    DragDropModule,
    FormsModule,
    PipesModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    QuillModule,
    ...INTELLIGENCE_COMMON_COMPONENTS,
    ...INTELLIGENCE_COMMON_DIRECTIVES,
  ],
  providers: [INTELLIGENCE_COMMON_PROVIDERS],
})
export class IntelligenceCommonModule {}
