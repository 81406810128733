<clr-dropdown class="category-dropdown">
  <button
    clrDropdownTrigger
    class="btn btn-link"
  >
    <ng-container *ngIf="selectedCategory">
      {{ selectedCategory.fullLabel }}
    </ng-container>
    <ng-container *ngIf="!selectedCategory">
      {{ 'COMMON_MESSAGES.CATEGORY' | translate }}
    </ng-container>
    <cds-icon
      shape="angle"
      direction="down"
    ></cds-icon>
  </button>
  <ng-template
    [clrIfOpen]="isMainMenuOpen"
    (clrIfOpenChange)="onMainMenuOpenChange($event)"
  >
    <clr-dropdown-menu [clrPosition]="CLARITY_TOOLTIP_POSITION.RIGHT_TOP">
      <clr-dropdown *ngFor="let categoryGroup of integrationCategories">
        <button
          clrDropdownTrigger
          [class.parent-active]="isIntegrationHighlighted(categoryGroup.integration)"
        >
          {{ categoryGroup.integration.label }}
        </button>
        <ng-template
          [clrIfOpen]="isSubMenuOpen(categoryGroup.integration)"
          (clrIfOpenChange)="onSubMenuOpenChange($event, categoryGroup.integration)"
        >
          <clr-dropdown-menu>
            <button
              *ngFor="let category of categoryGroup.categories"
              clrDropdownItem
              [class.active]="category.isSameAs(selectedCategory)"
              (click)="onSelectedCategoryChange(category)"
            >
              {{ category.entity.label }}
            </button>
          </clr-dropdown-menu>
        </ng-template>
      </clr-dropdown>
    </clr-dropdown-menu>
  </ng-template>
</clr-dropdown>
