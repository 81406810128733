<div class="filters-row pv-x2">
  <ng-container *ngFor="let tag of filterTags">
    <ng-container *ngIf="isTagWithoutDropdown(tag.type); else filterDropdown">
      <button
        [ngClass]="filter[tag.name] ? 'label label-info' : 'no-selection'"
        [disabled]="tagsDisabled"
        [hidden]="tag.isHidden"
        (click)="toggleBooleanFilter(tag)"
        type="button"
        class="btn btn-sm btn-outline-primary btn-filter mv-0 mr-x1"
      >
        {{ tag.label | translate }}
      </button>
    </ng-container>
    <ng-template #filterDropdown>
      <clr-dropdown
        [clrCloseMenuOnItemClick]="false"
        [class.divider]="hasDivider"
        [hidden]="tag.isHidden"
      >
        <button
          *ngIf="filter[tag.name]?.selectedSubTags?.length as selectedCount; else filterButton"
          clrDropdownTrigger
          [disabled]="tagsDisabled"
          type="button"
          class="btn btn-sm btn-outline-primary btn-filter label label-info selected-label"
        >
          {{ tag.label | translate }}: {{ 'COMMON_MESSAGES.COUNT_SELECTED' | translate: { count: selectedCount } }}
          <a
            (click)="clearFilter(tag.name)"
            (keydown.enter)="clearFilter(tag.name)"
            class="ml-x1"
          >
            <cds-icon shape="window-close"></cds-icon>
          </a>
        </button>
        <ng-template #filterButton>
          <button
            clrDropdownTrigger
            [disabled]="tagsDisabled"
            type="button"
            class="btn btn-sm btn-outline-primary btn-filter no-selection"
          >
            {{ tag.label | translate }}
            <cds-icon
              shape="angle"
              direction="down"
            ></cds-icon>
          </button>
        </ng-template>
        <clr-dropdown-menu
          *clrIfOpen
          class="pb-0"
        >
          <div class="dropdown-subtag-wrapper">
            <ng-container *ngFor="let subtag of tag.subtags">
              <button
                *ngIf="filter[tag.name] && filter[tag.name].selectedSubTags.includes(subtag.name); else unselectedTag"
                class="label label-light-blue quick-filters label-info"
              >
                {{ subtag.label }}
                <a
                  clrDropdownItem
                  (click)="toggleFilter(subtag.name, tag)"
                  (keyup.enter)="toggleFilter(subtag.name, tag)"
                  class="ml-x1 drop-down-option-clear clickable"
                >
                  <cds-icon
                    shape="window-close"
                    size="12"
                  ></cds-icon>
                </a>
              </button>
              <ng-template #unselectedTag>
                <button
                  clrDropdownItem
                  (click)="toggleFilter(subtag.name, tag)"
                  class="label label-light-blue quick-filters clickable"
                >
                  {{ subtag.label }}
                </button>
              </ng-template>
            </ng-container>
          </div>
          <div class="dropdown-buttons">
            <button
              clrDropdownItem
              (click)="selectAllSubTags(tag)"
              class="btn btn-link"
            >
              {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_SELECT_ALL' | translate }}
            </button>
            <button
              clrDropdownItem
              [clrDisabled]="!filter[tag.name]"
              (click)="clearFilter(tag.name)"
              class="btn btn-link"
            >
              {{ 'REPORT_CUSTOMIZE.REPORT_FILTERS_CLEAR_ALL' | translate }}
            </button>
            <button
              clrDropdownTrigger
              class="btn btn-link"
            >
              {{ 'COMMON_ACTIONS.CLOSE' | translate }}
            </button>
          </div>
        </clr-dropdown-menu>
      </clr-dropdown>
    </ng-template>
  </ng-container>
</div>
