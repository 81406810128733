/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * CardBodyComponent
 * @export
 * @class CardBodyComponent
 */
@Component({
  selector: 'dpa-card-body',
  templateUrl: 'card-body.component.html',
})
export class CardBodyComponent {}
