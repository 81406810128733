/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { get } from 'lodash-es';

import { UrlAttributes } from '@ws1c/intelligence-common/models/url-attributes.interface';
import { FilterRule, RuleGroup } from '@ws1c/intelligence-models';

/**
 * Returns attributes of specified url
 * @export
 * @param {string} url - Url value from which attributes needs to be extracted
 * @returns {UrlAttributes}
 */
export function getURLAttributes(url: string): UrlAttributes {
  let urlObj = null;
  // Use try/catch block to catch TypeError caused by passing invalid URL like
  // empty string or missing protocol to URL constructor
  try {
    urlObj = new URL(url);
  } catch (exception) {
    if (!(exception instanceof TypeError)) {
      throw exception;
    }
  }
  const hostName: string = get(urlObj, 'hostname', '');
  const domainNameIndex: number = hostName.indexOf('.');
  const domainName: string = domainNameIndex > -1 ? hostName.slice(domainNameIndex) : '';
  const subDomainName: string = domainNameIndex > -1 ? hostName.slice(0, domainNameIndex) : '';
  const protocol: string = urlObj ? `${urlObj.protocol}//` : '';
  return {
    domainName,
    hostName,
    protocol,
    port: get(urlObj, 'port', ''),
    subDomainName,
  };
}

/**
 * getRouterURLWithoutParameters
 * @export
 * @param {string} url
 * @returns {string}
 */
export function getRouterURLWithoutParameters(url: string): string {
  return url?.split('?')[0];
}

/**
 * filterEmptyRules
 * @export
 * @param {RuleGroup} filterRuleGroup
 */
export function filterEmptyRules(filterRuleGroup: RuleGroup) {
  filterRuleGroup.rules = filterRuleGroup.rules.filter((rule: FilterRule) => {
    return !!rule?.data?.length;
  });
}

/**
 * Checks if url is an external link. Assumes url specifying the protocol points to an external resource.
 * @param {string} url
 * @returns {boolean}
 */
export function isExternalLink(url?: string): boolean {
  return /^https?:\/\//.test(url);
}
