/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { AbstractControl, UntypedFormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import isURL from 'validator/es/lib/isURL';

import { AppConstants } from '@ws1c/intelligence-models';

/**
 * Class to add all the custom validators.
 * @export
 * @class CustomValidators
 */
export class CustomValidators {
  /**
   * Matches the value of 2 form fields.
   * @param {string} otherControlName
   * @returns {(control: UntypedFormControl) => {matchOther: boolean}}
   * @memberof CustomValidators
   */
  public static matchValueValidator(otherControlName: string) {
    let lhs: UntypedFormControl;
    let rhs: UntypedFormControl;

    return function matchValueValidate(control: UntypedFormControl) {
      if (!control.parent) {
        return null;
      }

      if (!lhs) {
        lhs = control;
        rhs = control.parent.get(otherControlName) as UntypedFormControl;
        if (!rhs) {
          throw new Error('Other control is not found.');
        }
        rhs.valueChanges.subscribe(() => {
          lhs.updateValueAndValidity();
        });
      }

      if (!rhs) {
        return null;
      }

      if (rhs.value !== lhs.value) {
        return {
          matchOther: true,
        };
      }
      return null;
    };
  }

  /**
   * Validates if entered input text is unique
   * @param {string[]} textList - Holds list of already available text
   * @param {boolean} isCaseSensitive - Holds flag to indicate if comparison needs to be case sensitive
   * @returns {ValidatorFn}
   * @memberof CustomValidators
   */
  public static uniqueTextValidator(textList: string[], isCaseSensitive: boolean = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputText: string = control.value;
      if (!inputText || !textList.length) {
        return null;
      }
      const iteratorFn: (text: string) => boolean = isCaseSensitive
        ? (text: string) => {
            return text === inputText;
          }
        : (text: string) => {
            return text.trim().toLowerCase() === inputText.trim().toLowerCase();
          };
      return textList.some(iteratorFn) ? { redundantText: true } : null;
    };
  }

  /**
   * Validates if entered input url is valid
   * @static
   * @returns {ValidatorFn}
   * @memberof CustomValidators
   */
  public static urlValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputUrl: string = control.value;

      if (!inputUrl) {
        return null;
      }

      return isURL(inputUrl) ? null : { invalidUrl: true };
    };
  }
  /**
   * Validates if entered IFrame code is valid
   * @static
   * @returns {ValidatorFn}
   * @memberof CustomValidators
   */
  public static iframeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const iframeCode: string = control.value as string;

      if (!iframeCode) {
        return null;
      }
      try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(iframeCode, 'text/html');
        const iframes = doc.querySelectorAll(`iframe[src^="${AppConstants.HTTPS_PREFIX}"]`);
        if (iframes.length > 0) {
          return null;
        } else {
          return { invalidIframe: true };
        }
      } catch (error) {
        return { invalidIframe: true };
      }
    };
  }

  /**
   * stepperValidator - validate if input value is a valid increment within a range
   * @static
   * @param {number} [incrementalAmount=0]
   * @param {number} [lowerBound=0]
   * @returns {ValidatorFn}
   * @memberof CustomValidators
   */
  public static stepperValidator(incrementalAmount: number = 0, lowerBound: number = 0): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // need to multiply these values by 100 in case inputs are decimals to avoid JS floating point issues with modulo.
      const updatedLowerBound = lowerBound * 100;
      const updatedIncrementalAmount = incrementalAmount * 100;
      const updatedValue = control.value * 100;
      const updatedDiff = Number((updatedValue - updatedLowerBound).toFixed(2));
      return !(updatedDiff % updatedIncrementalAmount) ? null : { invalidStep: true };
    };
  }
}
