<dpa-tooltip
  *ngIf="!tooltipText; else noTooltipTemplate"
  [attr.aria-label]="labelText + ', ' + tooltipText"
  [tooltipText]="tooltipText"
  role="tooltip"
>
  <div class="label info-badge">
    <cds-icon
      [attr.shape]="iconShape"
      [attr.size]="iconSize"
    ></cds-icon>
    {{ labelText }}
  </div>
</dpa-tooltip>
<ng-template #noTooltipTemplate>
  <div class="label">
    {{ labelText }}
  </div>
</ng-template>
