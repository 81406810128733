/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GenericObject } from '@dpa/ui-common';

/**
 * List Items Overflow
 *
 * @export
 * @class ListItemsOverflowComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-list-items-overflow',
  templateUrl: 'list-items-overflow.component.html',
  styleUrls: ['list-items-overflow.component.scss'],
})
export class ListItemsOverflowComponent implements OnChanges {
  @Input() public count: number = 1;
  @Input() public tooltipText: string;
  @Input() public values: GenericObject[] | string[];
  @Output() public showRemainingValues: EventEmitter<void> = new EventEmitter<void>();

  public displayValue: string;
  public remainingValuesButtonLabel: string;

  @Input() public labelFormatter: (item: any) => string = (i: string) => i;

  /**
   * Sets cell display value and remaining values button label
   * based on count, values and label formatter input property changes
   * @param {SimpleChanges} changes - Holds map of changed input property name to value
   * @memberof ListItemsOverflowComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (!(changes.count || changes.values || changes.labelFormatter)) {
      return;
    }
    const values: GenericObject[] | string[] = this.values || [];
    this.displayValue = values
      .slice(0, this.count)
      .map((item: GenericObject | string) => {
        return this.labelFormatter(item);
      })
      .join(', ');
    const remainingItemsCount: number = values.length - this.count;
    this.remainingValuesButtonLabel = remainingItemsCount > 0 ? `...(+${remainingItemsCount})` : null;
  }

  /**
   * Emits showRemainingValues event on button click
   * @memberof ListItemsOverflowComponent
   */
  public onRemainingValuesBtnClick() {
    this.showRemainingValues.emit();
  }
}
