<div *ngIf="webError">
  <clr-alert
    *ngIf="!webError.errors?.length"
    [clrAlertType]="'danger'"
    [clrAlertSizeSmall]="true"
  >
    <clr-alert-item>
      <span class="alert-text">
        {{ 'COMMON_MESSAGES.UNKNOWN_ERROR' | translate }}
      </span>
    </clr-alert-item>
  </clr-alert>
  <clr-alert
    *ngFor="let webErrorMessage of webError.errors"
    [clrAlertType]="'danger'"
    [clrAlertSizeSmall]="true"
  >
    <clr-alert-item>
      <span class="alert-text">
        <span
          *ngIf="webErrorMessage.code"
          class="web-error-code"
        >
          {{ webErrorMessage.code }}:
        </span>
        <span>{{ webErrorMessage.message }}</span>
      </span>
    </clr-alert-item>
  </clr-alert>
</div>
