/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { cloneDeep, isEqual } from 'lodash-es';
import { debounceTime, distinctUntilChanged, map, Subject } from 'rxjs';

import { NameLabel, Tag, TAGS_TYPE } from '@ws1c/intelligence-models';

/**
 * FilterTagsComponent
 * @export
 * @class FilterTagsComponent
 * @implements {OnChanges}
 * @implements {OnInit}
 */
@Component({
  selector: 'dpa-filter-tags',
  templateUrl: 'filter-tags.component.html',
  styleUrls: ['filter-tags.component.scss'],
})
export class FilterTagsComponent implements OnChanges, OnInit {
  @Input() public filterTags: Tag[];
  @Input() public hasDivider?: boolean = false;
  @Input() public defaultSelectedTags?: Tag[];
  @Input() public tagsDisabled?: boolean = false;
  @Output() public onFilterChange = new EventEmitter();

  public filter: Record<string, Tag> = {};
  public filter$: Subject<Record<string, Tag>> = new Subject();
  public TAGS_TYPE = TAGS_TYPE;

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof FilterTagsComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (!changes.defaultSelectedTags) {
      return;
    }
    const defaultSelectedTags: Tag[] = changes.defaultSelectedTags?.currentValue;
    setTimeout(() => {
      this.filter = {};
      let hasValidDefaultTags: boolean = false;
      defaultSelectedTags?.forEach((defaultTag: Tag) => {
        if (this.isTagWithoutDropdown(defaultTag?.type)) {
          this.toggleBooleanFilter(defaultTag);
          hasValidDefaultTags = true;
        } else if (defaultTag) {
          this.selectAllSubTags(defaultTag);
          hasValidDefaultTags = true;
        }
      });
      if (!hasValidDefaultTags) {
        this.clearAllFilter();
      }
    }, 0);
  }

  /**
   * ngOnInit
   * @memberof FilterTagsComponent
   */
  public ngOnInit() {
    this.filter$
      .pipe(
        debounceTime(100),
        map((filter) => cloneDeep(filter)),
        distinctUntilChanged(isEqual),
      )
      .subscribe((filter: Record<string, Tag>) => {
        this.onFilterChange.emit(filter);
      });
  }

  /**
   * toggleBooleanFilter
   * @param {Tag} tag
   * @memberof FilterTagsComponent
   */
  public toggleBooleanFilter(tag: Tag) {
    if (this.filter[tag.name]) {
      delete this.filter[tag.name]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
    } else {
      this.filter[tag.name] = tag;
    }
    this.filter$.next(this.filter);
  }

  /**
   * toggleFilter
   * @param {string} subtag
   * @param {Tag} tag
   * @memberof FilterTagsComponent
   */
  public toggleFilter(subtag: string, tag: Tag) {
    if (this.filter[tag.name]) {
      const categories = this.filter[tag.name].selectedSubTags;
      if (categories.includes(subtag)) {
        categories.splice(categories.indexOf(subtag), 1);
        if (categories.length === 0) {
          delete this.filter[tag.name]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
        }
      } else {
        categories.push(subtag);
      }
    } else {
      this.filter[tag.name] = {
        ...tag,
        selectedSubTags: [subtag],
      };
    }
    this.filter$.next(this.filter);
  }

  /**
   * selectAllSubTags
   * @param {Tag} tag
   * @memberof FilterTagsComponent
   */
  public selectAllSubTags(tag: Tag) {
    this.filter[tag.name] = {
      ...tag,
      selectedSubTags: (tag.subtags || []).map((tg: NameLabel) => tg.name),
    };
    this.filter$.next(this.filter);
  }

  /**
   * clearFilter
   * @param {string} tagName
   * @memberof FilterTagsComponent
   */
  public clearFilter(tagName: string) {
    delete this.filter[tagName]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
    this.filter$.next(this.filter);
  }

  /**
   * clearAllFilter
   * @memberof FilterTagsComponent
   */
  public clearAllFilter() {
    this.filter = {};
    this.filter$.next(this.filter);
  }

  /**
   * isTagWithoutDropdown
   *
   * @param {TAGS_TYPE} tagType
   * @returns {boolean}
   * @memberof FilterTagsComponent
   */
  public isTagWithoutDropdown(tagType: TAGS_TYPE): boolean {
    return [TAGS_TYPE.BOOLEAN, TAGS_TYPE.CUSTOM_TEMPLATE].includes(tagType);
  }
}
