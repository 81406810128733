<div class="card">
  <div class="card-block">
    <div class="leaderboards-container">
      <dpa-leaderboard
        *ngFor="let leaderboard of leaderboards"
        [leaderboard]="leaderboard"
        [showBars]="true"
        class="p-x3"
      ></dpa-leaderboard>
    </div>
  </div>
</div>
