<dpa-tooltip [tooltipText]="getTooltipText() | translate">
  <div [dpaInnerHtmlLight]="label"></div>
  <cds-icon
    *ngIf="detail?.error"
    status="danger"
    size="md"
    shape="exclamation-circle"
  ></cds-icon>
</dpa-tooltip>
<div
  *ngIf="detail?.isNormalized"
  class="label"
>
  {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
</div>
