/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';

import { AccordionWizardService } from './accordion-wizard.service';

/**
 * AccordionWizardStepService
 * @export
 * @class AccordionWizardStepService
 */
@Injectable()
export class AccordionWizardStepService {
  public stepIndex = undefined;

  /**
   * constructor
   * @param {AccordionWizardService} accordionWizardService
   * @memberof AccordionWizardStepService
   */
  constructor(private accordionWizardService: AccordionWizardService) {}

  /**
   * continue
   * @memberof AccordionWizardStepService
   */
  public continue() {
    this.accordionWizardService.continueStepIndex(this.stepIndex);
  }
}
