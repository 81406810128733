/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

/**
 * ACCORDION_WIZARD_CONFIG_STATE
 * @export
 * @enum {string}
 */
export enum ACCORDION_WIZARD_CONFIG_STATE {
  COMPLETED = 'COMPLETED',
  NOT_STARTED = 'NOT_STARTED',
}
