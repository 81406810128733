/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeFormat } from '@dpa/ui-common';
import moment from 'moment';

import { Column, FilterRule, OrgTreeNode, QueryBuilder, RuleGroup } from '@ws1c/intelligence-models';

/**
 *
 * Convert filterCondition object to localized rules.
 * Usage:
 *   filterCondition | localizedRules:field
 */
@Pipe({ name: 'localizedRules' })
export class LocalizedRulesPipe implements PipeTransform {
  private orgHierachy: OrgTreeNode = new OrgTreeNode();

  /**
   * Converts RuleGroup object to array of rules
   *
   * @param {any} filterCondition
   * @param {OrgTreeNode} orgHierachy
   * @param {Column[]} columns
   * @returns {RuleGroup}
   * @memberof LocalizedRulesPipe
   */
  public transform(filterCondition: any, orgHierachy: OrgTreeNode, columns: Column[] = []): RuleGroup {
    if (!filterCondition || !columns.length) {
      return new RuleGroup();
    }
    this.orgHierachy = orgHierachy;
    const ruleGroup = QueryBuilder.parseRuleDefinitionTree(filterCondition);

    return this.transformRules(ruleGroup, columns) as RuleGroup;
  }

  /**
   * transformRules
   *
   * @param {RuleGroup} ruleGroup
   * @param {Column[]} columns
   * @returns {RuleGroup | FilterRule }
   * @memberof LocalizedRulesPipe
   */
  private transformRules(ruleGroup: RuleGroup | FilterRule, columns: Column[]): RuleGroup | FilterRule {
    if (RuleGroup.isRuleGroup(ruleGroup)) {
      return new RuleGroup(
        ruleGroup.rules.map((rule) => {
          return this.transformRules(rule, columns);
        }),
        ruleGroup.operator,
      ) as any;
    } else {
      const rule = ruleGroup as any;
      const fieldMetaData = columns.find((column: any) => column.attributeName === rule.attribute);
      const selectedOperator =
        fieldMetaData &&
        fieldMetaData.supportedOperators.find((operator: any) => {
          return operator.value === rule.condition;
        });
      if (!fieldMetaData || !selectedOperator) {
        return rule;
      }

      return {
        label: fieldMetaData.label,
        condition: selectedOperator.label,
        data: this.getRuleValue(rule.data, fieldMetaData, selectedOperator.name),
      } as FilterRule;
    }
  }

  /**
   * Convert rule values to readable format
   *
   * @private
   * @param {any} data
   * @param {Column} field
   * @param {string} operator
   * @returns {any}
   * @memberof LocalizedRulesPipe
   */
  private getRuleValue(data: any, field: Column, operator: string) {
    let formattedData: any = data;
    if (Array.isArray(data)) {
      if (field.isDateTimeField()) {
        formattedData = data.map((time) => moment(time).format(DateTimeFormat.MOMENT_DATE_FORMAT));
      } else if (field.isTreeField()) {
        formattedData = data.map((orgId) => this.orgHierachy.getTextByValue(orgId));
      }
      if (QueryBuilder.isMultiSelect(operator) || QueryBuilder.isRange(operator)) {
        formattedData = `(${formattedData.join(', ')})`;
      } else {
        formattedData = formattedData.join(', ');
      }
    } else {
      if (field.isDateTimeField()) {
        formattedData = moment(data).format(DateTimeFormat.MOMENT_DATE_FORMAT);
      } else if (field.isTreeField()) {
        formattedData = this.orgHierachy.getTextByValue(data);
      }
    }
    return formattedData;
  }
}
