/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Directive, HostBinding } from '@angular/core';

/**
 * AnchorTabEventDirective
 * An anchor tag having click events and without a href attribute will not be considered for
 * focus in tab order. This directive is added to add tabindex to all anchor tags which are
 * having click event binding but don't have any href or tabindex already present. The directive
 * will also add role attribute as button since these anchors without href are not links.
 *
 * @export
 * @class AnchorTabEventDirective
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'a[click]:not([href]):not([tabindex])',
})
export class AnchorTabEventDirective {
  @HostBinding('attr.role') public role = 'button';
  @HostBinding('attr.tabindex') public tabindex = '0';
}
