/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

/**
 * ChartTypeComponent
 * @export
 * @class ChartTypeComponent
 */
@Component({
  selector: 'dpa-chart-type',
  templateUrl: 'chart-type.component.html',
  styleUrls: ['chart-type.component.scss'],
})
export class ChartTypeComponent {
  @Input() public chartType: string;
  @Input() public trendMode: string;
}
