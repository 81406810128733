<div class="btn-group">
  <div
    *ngFor="let layoutType of availableLayouts"
    class="radio btn"
  >
    <ng-container *ngIf="layoutSwitches[layoutType] as layoutSwitch">
      <input
        [id]="layoutSwitch.id"
        (change)="changeLayout(layoutType)"
        type="radio"
        name="multi-layout-switch"
      />
      <label [for]="layoutSwitch.id">
        <cds-icon
          [attr.shape]="layoutSwitch.icon"
          [class.selected]="layoutSwitch.id === selectedLayout"
          class="dpa-icon"
        ></cds-icon>
      </label>
    </ng-container>
  </div>
</div>
