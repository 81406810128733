/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';
import { GenericObject, WindowService } from '@dpa/ui-common';
import { saveAs } from 'file-saver';
import { snakeCase } from 'lodash-es';

import { AppConfig } from '@ws1c/intelligence-common/constants/app-config';
import { CookiesService } from './cookies.service';

/**
 * DownloadService
 * @export
 * @class DownloadService
 */
@Injectable()
export class DownloadService {
  /**
   * constructor
   * @param {WindowService} windowService
   * @param {CookiesService} cookiesService
   * @memberof DownloadService
   */
  constructor(
    private windowService: WindowService,
    private cookiesService: CookiesService,
  ) {}

  /**
   * downloadAsCsv
   * @param {string[][]} csvData
   * @param {string} fileName
   * @memberof DownloadService
   */
  public downloadAsCsv(csvData: string[][], fileName: string) {
    const rows = csvData.map((rowData: string[]) => {
      return rowData.join(',');
    });
    const fullText = rows.join('\r\n');
    this.downloadTextFile(fullText, `${snakeCase(fileName)}.csv`);
  }

  /**
   * downloadTextFile
   * @param {string} fileContent
   * @param {string} fileName
   * @memberof DownloadService
   */
  public downloadTextFile(fileContent: string, fileName: string) {
    saveAs(new Blob([fileContent], { type: 'text/plain' }), fileName);
  }

  /**
   * Downloads json object as a file.
   *
   * @param {GenericObject} jsonObject
   * @param {string} fileName
   * @memberof DownloadService
   */
  public downloadJsonAsFile(jsonObject: GenericObject, fileName: string) {
    const jsonAsString: string = JSON.stringify(jsonObject, undefined, 4);
    saveAs(new Blob([jsonAsString], { type: 'json' }), fileName);
  }

  /**
   * Downloads json string as a file.
   *
   * @param {string} jsonAsString
   * @param {string} fileName
   * @memberof DownloadService
   */
  public downloadJsonStringAsFile(jsonAsString: string, fileName: string) {
    saveAs(new Blob([jsonAsString], { type: 'json' }), fileName);
  }

  /**
   * download
   * @param {string} downloadRelPath
   */
  public download(downloadRelPath: string) {
    const downloadUrl = (this.cookiesService.getCookie(AppConfig.API_URL) || '') + downloadRelPath;
    this.windowService.open(downloadUrl, '_blank');
  }

  /**
   * downloadCodeInGivenFileFormat
   * @param {string[]} data
   * @param {string} fileName
   * @param {string} fileFormat
   * @memberof DownloadService
   */
  public downloadCodeInGivenFileFormat(data: string[], fileName: string, fileFormat: string) {
    const fullText = data.join('\n');
    this.downloadTextFile(fullText, `${snakeCase(fileName)}.${fileFormat.toLowerCase()}`);
  }
}
