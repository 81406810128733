/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { getIconClassByPlatform } from '@ws1c/intelligence-common/utils/icon';

/**
 * Pipe to get platform icon class
 *
 * @export
 * @class PlatformIconPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'platformIcon' })
export class PlatformIconPipe implements PipeTransform {
  /**
   * transform
   * @param {string} platform
   * @returns {string}
   * @memberof PlatformIconPipe
   */
  public transform(platform: string): string {
    return getIconClassByPlatform(platform);
  }
}
