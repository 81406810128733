/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ReportType, TrendMode } from '@ws1c/intelligence-models';

/**
 * EntityDataTypeSelectorComponent
 * @export
 * @class EntityDataTypeSelectorComponent
 */
@Component({
  selector: 'dpa-entity-data-type-selector',
  templateUrl: 'entity-data-type-selector.component.html',
  styleUrls: ['entity-data-type-selector.component.scss'],
})
export class EntityDataTypeSelectorComponent {
  @Input() public snapshotChartOptionVisible: boolean = false;
  @Input() public historicalChartOptionVisible: boolean = false;
  @Input() public selectedDataType: TrendMode | ReportType;
  @Output() public dataTypeChange: EventEmitter<TrendMode | ReportType> = new EventEmitter<TrendMode | ReportType>();

  public readonly ENTITY_DATA_TYPE = TrendMode;

  /**
   * selectDataType
   *
   * @param {TrendMode} entityDataType
   * @memberof EntityDataTypeSelectorComponent
   */
  public selectDataType(entityDataType: TrendMode | ReportType) {
    this.dataTypeChange.emit(entityDataType);
  }
}
