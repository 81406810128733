/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { AppConfig } from './app-config';

/**
 * V3 Endpoints
 *
 * @export
 * @class EndpointV3
 */
export class EndpointV3 {
  public static readonly DASHBOARD_ROOT = `${AppConfig.API_V3_ROOT}/dashboards`;
  public static STANDARD_DASHBOARD_BY_TYPE = (dashboardType: string) => `${EndpointV3.DASHBOARD_ROOT}/standard/${dashboardType}`;
}
