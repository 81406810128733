<div>
  <dpa-local-search
    *ngIf="displaySearch"
    [placeHolderText]="'COMMON_ACTIONS.SEARCH' | translate"
    (onSearch)="onSearchAvailableColumns($event)"
    iconClass="search"
  ></dpa-local-search>
  <div *ngIf="treeObj?.length > 0; else noItems">
    <clr-tree>
      <dpa-tree-node
        [treeViewItems]="treeObj"
        [selectOnlyLeafNode]="selectOnlyLeafNode"
        [treeType]="treeType"
        (selectedChange)="checkForChanges($event)"
        (expandedChange)="checkForExpanded($event)"
      ></dpa-tree-node>
    </clr-tree>
  </div>
  <ng-template #noItems>
    <div
      class="no-items"
      aria-live="polite"
    >
      {{ 'COMMON_MESSAGES.NO_ITEMS_FOUND' | translate }}
    </div>
  </ng-template>
</div>
