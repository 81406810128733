/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SortOn } from '@dpa/ui-common';

/**
 * Display List of options as a dropdown so that a user can choose the value he wants to sort on.
 *
 * @export
 * @class SortByComponent
 */
@Component({
  selector: 'dpa-sort-by',
  templateUrl: 'sort-by.component.html',
  styleUrls: ['sort-by.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortByComponent implements OnInit {
  @Input() public sortByOptions: SortOn[];
  @Input() public defaultSortBy: SortOn;
  @Input() public screenReaderLabelText?: string;
  @Output() public sortByChanged = new EventEmitter<SortOn[]>();
  public selectedSortBy: SortOn;

  /**
   * ngOnInit
   * @memberof SortByComponent
   */
  public ngOnInit() {
    if (this.sortByOptions && this.sortByOptions.length > 0) {
      this.selectedSortBy = this.defaultSortBy || this.sortByOptions[0];
    }
  }

  /**
   * getLabel
   * @param  {SortOn} sortOn
   * @returns {string}
   * @memberof SortByComponent
   */
  public getLabel(sortOn: SortOn): string {
    return sortOn.label || sortOn.by;
  }

  /**
   * getItemKey
   * @param  {SortOn} sortOn
   * @returns {string}
   * @memberof SortByComponent
   */
  public getItemKey(sortOn: SortOn): string {
    // to ensure that a unique value is returned, else keyboard navigation would skip duplicate entries
    return sortOn.by + '_' + sortOn.reverse;
  }

  /**
   * onChange
   * @param {SortOn} selectedSortBy
   * @memberof SortByComponent
   */
  public onChange(selectedSortBy: SortOn) {
    const sortOn: SortOn = Object.assign(new SortOn(), selectedSortBy);
    this.sortByChanged.emit([sortOn]);
  }
}
