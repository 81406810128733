/*
 * Copyright 2018 VMware, Inc.
 * All rights reserved.
 */

import { Directive, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

/**
 * Scroll to end directive - Scrolls the element to either ends (top or bottom)
 * default is bottom.
 */

/**
 * ScrollToDirective
 * @export
 * @class ScrollToDirective
 * @implements {OnChanges}
 */
@Directive({
  selector: '[dpaScrollTo]',
})
export class ScrollToDirective implements OnChanges {
  @Input() public triggerScroll: boolean = false;
  @Input() public direction?: 'top' | 'bottom' = 'bottom';
  @Output() public onScrollComplete: EventEmitter<any> = new EventEmitter();
  private element: ElementRef;

  /**
   * constructor
   * @param {ElementRef} el
   * @memberof ScrollToDirective
   */
  constructor(el: ElementRef) {
    this.element = el;
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof ScrollToDirective
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.triggerScroll.currentValue) {
      if (this.direction === 'bottom') {
        this.element.nativeElement.scrollTop = this.element.nativeElement.scrollHeight;
      } else {
        this.element.nativeElement.scrollTop = 0;
      }
      this.onScrollComplete.emit();
    }
  }
}
