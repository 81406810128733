/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';

import { UserTouchDetailsModule } from '@ws1c/intelligence-common/components/user-touch-details/user-touch-details.module';
import { IntelligenceCommonModule } from '@ws1c/intelligence-common/intelligence-common.module';
import { SOLUTION_COMPONENTS } from './index';

/**
 * SolutionModule
 * @exports SolutionModule
 */
@NgModule({
  declarations: [...SOLUTION_COMPONENTS],
  imports: [UserTouchDetailsModule, IntelligenceCommonModule],
  exports: [...SOLUTION_COMPONENTS],
})
export class SolutionModule {}
