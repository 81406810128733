/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

/**
 * Returns 0 to keep original order for
 * keyvalue iteration within template
 * @export
 * @returns {number}
 */
export const originalOrder = (): number => {
  return 0;
};
