/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { APOLLO_NAMED_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

import { Endpoint } from './constants/endpoint';

/**
 * GraphQLModule
 * @export
 * @class GraphQLModule
 */
@NgModule({
  imports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          _default: {
            cache: new InMemoryCache(),
            link: httpLink.create({
              uri: Endpoint.GRAPHQL,
            }),
          },
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
