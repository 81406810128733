/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { WebError } from '@dpa/ui-common';

import { I18NService } from '@ws1c/intelligence-common/services';

/**
 * FileUploaderComponent
 * @export
 * @class FileUploaderComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-file-uploader',
  templateUrl: 'file-uploader.component.html',
  styleUrls: ['file-uploader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnChanges {
  @Input() public isUploading?: boolean;
  @Input() public isUploadButtonDisabled: boolean;
  @Input() public allowMultiple?: boolean = true;
  @Input() public helperString?: string;
  @Input() public allowedFileType?: string;
  @Input() public styleClasses?: string = '';
  @Input() public showBgImage?: boolean = false;
  @Input() public webError?: WebError;
  @Output() public onSelectFiles = new EventEmitter<File[]>();
  @Output() public onUploadFiles = new EventEmitter<File[]>();

  @ViewChild('fileInput', { static: true }) public fileInput;
  public selectedFiles: File[] = [];
  public warningMessage: string = '';

  /**
   * constructor
   * @param {I18NService} i18nService
   * @memberof FileUploaderComponent
   */
  constructor(private i18nService: I18NService) {}

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.isUploading && !changes.isUploading.currentValue && changes.isUploading.previousValue) {
      this.clearFiles();
    }
  }

  /**
   * onFileChange
   * @param {any} fileChangeEvent
   * @memberof FileUploaderComponent
   */
  public onFileChange(fileChangeEvent: any) {
    const files: File[] = fileChangeEvent.target.files;
    this.addFiles(files);
  }

  /**
   * onTooMany
   * @memberof FileUploaderComponent
   */
  public onTooMany() {
    this.warningMessage = this.i18nService.translate('CUSTOM_CONNECTION.DRAG_SINGLE_FILE');
  }

  /**
   * onWrongType
   * @memberof FileUploaderComponent
   */
  public onWrongType() {
    this.warningMessage = this.i18nService.translate('COMMON_MESSAGES.INVALID_FILE_TYPE');
  }

  /**
   * addFiles
   * @param {File[]} files
   * @memberof FileUploaderComponent
   */
  public addFiles(files: File[]) {
    this.warningMessage = '';
    this.selectedFiles = [...this.selectedFiles, ...files];
    this.onSelectFiles.emit(this.selectedFiles);
  }

  /**
   * uploadSelectedFiles
   * @memberof FileUploaderComponent
   */
  public uploadSelectedFiles() {
    this.onUploadFiles.emit(this.selectedFiles);
  }

  /**
   * clearFiles
   * @memberof FileUploaderComponent
   */
  public clearFiles() {
    this.selectedFiles = [];
    this.fileInput.nativeElement.value = null;
  }

  /**
   * getIsUploadButtonDisabled
   * @returns {boolean}
   * @memberof FileUploaderComponent
   */
  public getIsUploadButtonDisabled(): boolean {
    return this.isUploadButtonDisabled || this.isUploading || !this.selectedFiles.length;
  }
}
