<div
  *ngIf="leaderboard; else loading"
  class="leaderboard-container"
>
  <div class="title pb-x2">
    {{ leaderboard.titleKey | translate }}
    <dpa-tooltip
      *ngIf="leaderboard.tooltipKey"
      [tooltipText]="leaderboard.tooltipKey | translate"
    >
      <cds-icon
        shape="info-circle"
        size="24"
      ></cds-icon>
    </dpa-tooltip>
  </div>

  <div
    *ngIf="!leaderboard.rows.length"
    class="no-data-label"
  >
    {{ 'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate }}
  </div>

  <div
    *ngIf="leaderboard.rows.length"
    class="leaderboard-col-container"
  >
    <div
      *ngFor="let col of leaderboard.cols; let i = index"
      class="leaderboard-col"
    >
      <div
        *ngIf="leaderboard.columnTitleKeys"
        class="leaderboard-col-title leaderboard-cell"
      >
        {{ leaderboard.columnTitleKeys[i] | translate }}
      </div>
      <div
        *ngFor="let cell of col"
        class="leaderboard-cell"
      >
        <ng-container [ngSwitch]="dataUnit[i]">
          <span *ngSwitchCase="DATA_UNIT.MILLISECONDS">
            <dpa-decorated-value
              [value]="cell"
              [valueDecorator]="DATA_DECORATOR.TIME_DURATION"
            ></dpa-decorated-value>
          </span>
          <span *ngSwitchCase="DATA_UNIT.SECONDS">
            <dpa-decorated-value
              [value]="cell * 1000"
              [valueDecorator]="DATA_DECORATOR.TIME_DURATION_IN_SECONDS"
            ></dpa-decorated-value>
          </span>
          <span *ngSwitchCase="DATA_UNIT.MINUTES">
            <dpa-decorated-value
              [value]="cell * 60000"
              [valueDecorator]="DATA_DECORATOR.TIME_DURATION_IN_MINUTES"
            ></dpa-decorated-value>
          </span>
          <span *ngSwitchCase="DATA_UNIT.HOURS">
            <dpa-decorated-value
              [value]="cell * 3600000"
              [valueDecorator]="DATA_DECORATOR.TIME_DURATION_IN_HOURS"
            ></dpa-decorated-value>
          </span>
          <span *ngSwitchCase="DATA_UNIT.PERCENT">
            <dpa-decorated-value
              [value]="cell"
              [valueDecorator]="DATA_DECORATOR.STRING_PERCENT"
            ></dpa-decorated-value>
          </span>
          <span *ngSwitchDefault>{{ cell?.toLocaleString() }}</span>
        </ng-container>
      </div>
    </div>

    <div
      *ngIf="showBars"
      class="bar-col"
    >
      <div
        *ngIf="leaderboard.columnTitleKeys"
        class="leaderboard-col-title leaderboard-cell"
      ></div>
      <div *ngFor="let row of leaderboard.rows">
        <dpa-ratio-indicator
          [ratios]="getRatiosByColorSet(row)"
          [total]="leaderboard.maxRowTotal"
        ></dpa-ratio-indicator>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="spinner-container">
    <div class="spinner spinner-inline"></div>
  </div>
</ng-template>
