/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * CardBlockComponent
 * @export
 * @class CardBlockComponent
 */
@Component({
  selector: 'dpa-card-block',
  templateUrl: 'card-block.component.html',
})
export class CardBlockComponent {}
