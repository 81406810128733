/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params } from '@angular/router';

/**
 * SolutionConfigSuccessComponent
 * @export
 * @class SolutionConfigSuccessComponent
 */
@Component({
  selector: 'dpa-solution-config-success',
  templateUrl: 'solution-config-success.component.html',
  styleUrls: ['solution-config-success.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionConfigSuccessComponent {
  @Input() public isFullScreen: boolean = true;
  @Input() public enabledText: string;
  @Input() public enabledDescription: string;
  @Input() public btnRouterLink: string;
  @Input() public btnRouterQueryParams: Params;
}
