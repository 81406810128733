<ng-container *ngIf="treeType === TREE_TYPE.SELECT; else basicTree">
  <div *ngFor="let treeViewItem of treeViewItems">
    <div *ngIf="treeViewItem.children">
      <clr-tree-node
        *ngIf="selectOnlyLeafNode; else selectAllNodes"
        [(clrSelected)]="treeViewItem.selected"
      >
        <div
          [dpaInnerHtmlLight]="treeViewItem.styledString || treeViewItem.label"
          class="matching-text"
        ></div>
        <ng-template
          [clrIfExpanded]="treeViewItem.isExpanded"
          (clrIfExpandedChange)="setExpanded(treeViewItem, $event)"
        >
          <dpa-tree-node
            [treeViewItems]="treeViewItem.children"
            [selectOnlyLeafNode]="selectOnlyLeafNode"
            (selectedChange)="selectItem($event)"
            (expandedChange)="changeExpanded($event)"
          ></dpa-tree-node>
        </ng-template>
      </clr-tree-node>
      <ng-template #selectAllNodes>
        <clr-tree-node
          [(clrSelected)]="treeViewItem.selected"
          (clrSelectedChange)="selectItem(treeViewItem)"
        >
          <div
            [dpaInnerHtmlLight]="treeViewItem.styledString || treeViewItem.label"
            class="matching-text"
          ></div>
          <ng-template
            [clrIfExpanded]="treeViewItem.isExpanded"
            (clrIfExpandedChange)="setExpanded(treeViewItem, $event)"
          >
            <dpa-tree-node
              [treeViewItems]="treeViewItem.children"
              [selectOnlyLeafNode]="selectOnlyLeafNode"
              (selectedChange)="selectItem($event)"
              (expandedChange)="changeExpanded($event)"
            ></dpa-tree-node>
          </ng-template>
        </clr-tree-node>
      </ng-template>
    </div>
    <div *ngIf="!treeViewItem.children">
      <clr-tree-node
        [(clrSelected)]="treeViewItem.selected"
        (clrSelectedChange)="selectItem(treeViewItem)"
      >
        <span
          [dpaInnerHtmlLight]="treeViewItem.styledString || treeViewItem.label"
          class="matching-text"
        ></span>
        <span
          *ngIf="treeViewItem.isNormalized"
          class="label"
        >
          {{ 'COMMON_MESSAGES.NORMALIZED' | translate }}
        </span>
      </clr-tree-node>
    </div>
  </div>
</ng-container>

<ng-template #basicTree>
  <div *ngFor="let treeViewItem of treeViewItems">
    <div *ngIf="treeViewItem.children?.length > 0; else basicLeafNode">
      <clr-tree-node>
        <div>
          {{ treeViewItem.label }}
        </div>
        <ng-template
          [clrIfExpanded]="treeViewItem.isExpanded"
          (clrIfExpandedChange)="setExpanded(treeViewItem, $event)"
        >
          <dpa-tree-node
            [treeViewItems]="treeViewItem.children"
            [treeType]="treeType"
          ></dpa-tree-node>
        </ng-template>
      </clr-tree-node>
    </div>
    <ng-template #basicLeafNode>
      <clr-tree-node>
        {{ treeViewItem.label }}
      </clr-tree-node>
    </ng-template>
  </div>
</ng-template>
