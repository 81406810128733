/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable } from '@angular/core';

/**
 * Service to provide access to document cookies
 * Clone from angular/packages/platform-browser/src/browser/browser_adapter.ts
 * @export
 * @class CookiesService
 */
@Injectable({ providedIn: 'root' })
export class CookiesService {
  /**
   *
   * @param {string} name
   * @returns {(string | null)}
   * @memberof CookiesService
   */
  public getCookie(name: string): string | null {
    // eslint-disable-next-line
    return this.parseCookieValue(document.cookie, name);
  }

  /**
   *
   * @param {string} name
   * @param {*} value
   * @memberof CookiesService
   */
  public setCookie(name: string, value: any) {
    // document.cookie is magical, assigning into it assigns/overrides one cookie value, but does
    // not clear other cookies.
    // eslint-disable-next-line
    document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);
  }

  /**
   *
   * @private
   * @param {string} cookieStr
   * @param {string} name
   * @returns {(string | null)}
   * @memberof CookiesService
   */
  // eslint-disable-next-line class-methods-use-this
  private parseCookieValue(cookieStr: string, name: string): string | null {
    name = encodeURIComponent(name);
    for (const cookie of cookieStr.split(';')) {
      const eqIndex = cookie.indexOf('=');
      const [cookieName, cookieValue]: string[] = eqIndex === -1 ? [cookie, ''] : [cookie.slice(0, eqIndex), cookie.slice(eqIndex + 1)];
      if (cookieName.trim() === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }
}
