/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { AccessLevelLabelComponent } from './access-level-label/access-level-label.component';
import { CARD_COMPONENTS } from './card';
import { CategorySelectorComponent } from './category-selector/category-selector.component';
import { CategorySelectorDropdownComponent } from './category-selector-dropdown/category-selector-dropdown.component';
import { CodePreviewComponent } from './code-preview/code-preview.component';
import { CustomTagComponent } from './custom-tag/custom-tag.component';
import { DataAccessPolicyLabelComponent } from './data-access-policy-label/data-access-policy-label.component';
import { EmptyStateComponent } from './empty-state/empty-state.component';
import { EntityDataTypeSelectorComponent } from './entity-data-type-selector/entity-data-type-selector.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { FilterTagsComponent } from './filter-tags/filter-tags.component';
import { InfinityTableComponent } from './infinity-table/infinity-table.component';
import { InfoTooltipComponent } from './info-tooltip/info-tooltip.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { ListItemComponent } from './list-item/list-item.component';
import { ListItemsOverflowComponent } from './list-items-overflow/list-items-overflow.component';
import { GenericListComponent, GroupedListComponent } from './lists';
import { LocalDataGridComponent } from './local-data-grid/local-data-grid.component';
import { MARKETPLACE_COMPONENTS } from './marketplace';
import { MultiLayoutSwitcherComponent } from './multi-layout-switcher/multi-layout-switcher.component';
import { OwnerInfoTagComponent } from './owner-info-tag/owner-info-tag.component';
import { PageChangerComponent } from './page-changer/page-changer.component';
import { PanelComponent } from './panel/panel.component';
import { PanelsComponent } from './panels/panels.component';
import { RoleIconsComponent } from './role-icons/role-icons.component';
import { RoleListItemsOverflowComponent } from './role-list-items-overflow/role-list-items-overflow.component';
import { SchedulerComponent } from './scheduler/scheduler.component';
import { SelectTemplateComponent } from './select-template/select-template.component';
import { SeverityIndicatorComponent } from './severity-indicator/severity-indicator.component';
import { SortByComponent } from './sort-by/sort-by.component';
import { SplitViewListItemLoadingComponent } from './split-view/split-view-list-item-loading/split-view-list-item-loading.component';
import { TagFilterComponent } from './tag-filter/tag-filter.component';
import { TEMPLATE_COMPONENTS } from './template';
import { TimeUnitSelectorComponent } from './time-unit-selector/time-unit-selector.component';
import { TimelineContentDirective } from './timeline/timeline-content.directive';
import { TimelineComponent } from './timeline/timeline.component';
import { TooltipTagComponent } from './tooltip-tag/tooltip-tag.component';
import { TooltipViewerComponent } from './tooltip-viewer/tooltip-viewer.component';
import { TreeNodeComponent, TreeViewComponent } from './tree-view';
import { TrendDateRangeComponent } from './trend-date-range/trend-date-range.component';
import { WebErrorComponent } from './web-error/web-error.component';
import { WidgetLeaderboardsCardComponent } from './widget-leaderboards-card/widget-leaderboards-card.component';

export const INTELLIGENCE_COMMON_COMPONENTS: any[] = [
  AccessLevelLabelComponent,
  ...CARD_COMPONENTS,
  CategorySelectorComponent,
  CategorySelectorDropdownComponent,
  CodePreviewComponent,
  CustomTagComponent,
  DataAccessPolicyLabelComponent,
  EmptyStateComponent,
  EntityDataTypeSelectorComponent,
  FileUploaderComponent,
  FilterTagsComponent,
  InfinityTableComponent,
  InfoTooltipComponent,
  GroupedListComponent,
  GenericListComponent,
  LeaderboardComponent,
  ListItemComponent,
  ListItemsOverflowComponent,
  LocalDataGridComponent,
  ...MARKETPLACE_COMPONENTS,
  MultiLayoutSwitcherComponent,
  OwnerInfoTagComponent,
  PageChangerComponent,
  PanelComponent,
  PanelsComponent,
  RoleIconsComponent,
  RoleListItemsOverflowComponent,
  SchedulerComponent,
  SelectTemplateComponent,
  SeverityIndicatorComponent,
  SortByComponent,
  SplitViewListItemLoadingComponent,
  TagFilterComponent,
  ...TEMPLATE_COMPONENTS,
  TimeUnitSelectorComponent,
  TimelineComponent,
  TimelineContentDirective,
  TooltipTagComponent,
  TooltipViewerComponent,
  TreeNodeComponent,
  TreeViewComponent,
  TrendDateRangeComponent,
  WebErrorComponent,
  WidgetLeaderboardsCardComponent,
];

export * from './access-level-label/access-level-label.component';
export * from './card';
export * from './category-selector/category-selector.component';
export * from './category-selector-dropdown/category-selector-dropdown.component';
export * from './code-preview/code-preview.component';
export * from './column-selector-detail/column-selector-detail.component';
export * from './custom-tag/custom-tag.component';
export * from './list-items-overflow/list-items-overflow.component';
export * from './empty-state/empty-state.component';
export * from './file-uploader/file-uploader.component';
export * from './filter-tags/filter-tags.component';
export * from './infinity-table/infinity-table.component';
export * from './info-tooltip/info-tooltip.component';
export * from './leaderboard/leaderboard.component';
export * from './lists';
export * from './list-item/list-item.component';
export * from './local-data-grid/local-data-grid.component';
export * from './marketplace';
export * from './multi-layout-switcher/multi-layout-switcher.component';
export * from './owner-info-tag/owner-info-tag.component';
export * from './panel/panel.component';
export * from './panels/panels.component';
export * from './page-changer/page-changer.component';
export * from './solution';
export * from './role-icons/role-icons.component';
export * from './role-list-items-overflow/role-list-items-overflow.component';
export * from './scheduler/scheduler.component';
export * from './select-template/select-template.component';
export * from './severity-indicator/severity-indicator.component';
export * from './sort-by/sort-by.component';
export * from './split-view/split-view-list-item-loading/split-view-list-item-loading.component';
export * from './tag-filter/tag-filter.component';
export * from './template/template-search';
export * from './time-unit-selector/time-unit-selector.component';
export * from './timeline/timeline-content.directive';
export * from './timeline/timeline.component';
export * from './tooltip-tag/tooltip-tag.component';
export * from './tooltip-viewer/tooltip-viewer.component';
export * from './tree-view';
export * from './trend-date-range/trend-date-range.component';
export * from './web-error/web-error.component';
export * from './widget-leaderboards-card/widget-leaderboards-card.component';
