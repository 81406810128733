/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * CardBlockRowComponent
 * @export
 * @class CardBlockRowComponent
 */
@Component({
  selector: 'dpa-card-block-row',
  templateUrl: 'card-block-row.component.html',
  styleUrls: ['card-block-row.component.scss'],
})
export class CardBlockRowComponent {}
