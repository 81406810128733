/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { Injectable, Injector } from '@angular/core';
import { getFailureReason, Lang, SUPPORTED_LANGUAGES, WebError } from '@dpa/ui-common';
import { TranslateService } from '@ngx-translate/core';
import { escape } from 'lodash-es';

/**
 * Handle localization settings
 * @export
 * @class I18NService
 */
@Injectable({ providedIn: 'root' })
export class I18NService {
  /**
   * List of support languages from AW Console
   * @static
   * @type {Lang[]}
   * @memberof I18NService
   */
  public static SUPPORTED_LANGUAGES: Lang[] = SUPPORTED_LANGUAGES;

  /**
   * Creates an instance of I18NService.
   * @param {TranslateService} translateService
   * @param {Injector} injector
   * @memberof I18NService
   */
  constructor(
    private translateService: TranslateService,
    private injector: Injector,
  ) {}

  /**
   * Set current language for translateService
   *
   * @param {string} locale
   * @returns {Promise<object>}
   * @memberof I18NService
   */
  public setLanguage(locale: string): Promise<object> {
    // Must set <html lang="xx"> to render some characters properly in browser
    document.documentElement.setAttribute('lang', locale.substr(0, 2));

    // set locale$ to browser selected value in order to load the translation of ws1 broker web element
    const locale$ = this.injector.get('ACTIVE_LOCALE');
    locale$.next(locale);

    // returns a Promise; this is required so that APP_INITIALIZER in app.module would work
    return this.translateService.use(locale).toPromise();
  }

  /**
   * Gets the translated value of a key (or an array of keys) or the key if the value was not found
   * @param {string | string[]} key
   * @param {any} [interpolateParams]
   * @returns {string | any}
   * @memberof I18NService
   */
  public translate(key: string | string[], interpolateParams?: any): string | any {
    const escapedParams = {};
    if (interpolateParams) {
      Object.keys(interpolateParams).forEach((paramName: string) => {
        escapedParams[paramName] = escape(interpolateParams[paramName]);
      });
    }
    return this.translateService.instant(key, escapedParams);
  }

  /**
   * Gets current locale
   * @returns {string} TranslateService.currentLang
   * @memberof I18NService
   */
  public getCurrentLocale(): string {
    return this.translateService.currentLang.replace('_', '-');
  }

  /**
   * Gets Localized error message for the key along with the failure reason
   * @param {string} key
   * @param {WebError} error
   * @returns {string}
   * @memberof I18NService
   */
  public getLocalizedError(key: string, error: WebError): string {
    return this.translateService.instant(key, {
      reason: escape(getFailureReason(error)),
    });
  }

  /**
   * checkTranslation
   * Returns true/false based on existance of translation for the passed key
   *
   * @param {string} key
   * @returns {boolean}
   * @memberof I18NService
   */
  public checkTranslation(key: string): boolean {
    return key !== this.translate(key);
  }
}
