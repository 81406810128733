/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { I18NService } from '@ws1c/intelligence-common/services/i18n.service';

/**
 * Pipe to show initials for a given string
 * if input has two words and required length is not 1, the initialsPipe returns the first character of first and second word
 * else returns the first character from the string
 * @export
 * @class InitialsPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'initials' })
export class InitialsPipe implements PipeTransform {
  /**
   * Creates an instance of InitialsPipe.
   * @param {I18NService} i18nService
   * @memberof InitialsPipe
   */
  constructor(private i18nService: I18NService) {}

  /**
   * transform
   * @param {string} [value='']
   * @param {number} [length=2]
   * @returns {string}
   * @memberof InitialsPipe
   */
  public transform(value: string = '', length: number = 2): string {
    if (!value) {
      return this.i18nService.translate('COMMON_MESSAGES.UNKNOWN_VALUE');
    }
    const userDetails: string[] = value.split(' ');
    return userDetails.length > 1 && length === 2
      ? (userDetails[0].charAt(0) + userDetails[1].charAt(0)).toUpperCase()
      : userDetails[0].charAt(0).toUpperCase();
  }
}
