/*
 * Copyright 2017 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DateTimePickerType, TrendSpan, TrendSpanOption } from '@dpa/ui-common';
import moment from 'moment';

import { I18NService } from '@ws1c/intelligence-common/services';
import { AppConstants, ChronoUnit, ReportDateRange, TrendDateRange } from '@ws1c/intelligence-models';

/**
 * TrendDateRangeComponent
 * @export
 * @class TrendDateRangeComponent
 */
@Component({
  selector: 'dpa-trend-date-range',
  templateUrl: 'trend-date-range.component.html',
  styleUrls: ['trend-date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrendDateRangeComponent implements OnInit, OnChanges {
  @Input() public trendDateRange: TrendDateRange | ReportDateRange;
  @Input() public isCustomTrendSpanOptionRequired?: boolean = true;
  @Input() public customTimePeriodHelpText?: string;
  @Input() public splitCol?: boolean = false;
  @Input() public readonly?: boolean = false;
  @Input() public maxCustomRange?: number;
  @Input() public minStartDate?: Date;
  @Input() public maxEndDate?: Date;
  @Input() public showReadonlyCalendarIcon?: boolean = false;
  @Input() public overrideDefaultTrendSpanOptions?: TrendSpanOption[] = [];
  @Output() public trendDateRangeUpdated = new EventEmitter<TrendDateRange | ReportDateRange>();
  public startDate: Date;
  public endDate: Date;
  public defaultCustomDateRange: Date[] = [];
  public selectedTrendSpanOption: TrendSpanOption;
  public DATE_TIME_PICKER_TYPE = DateTimePickerType;
  public maxRangeInMillis: number;

  public customTrendSpanOption: TrendSpanOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_CUSTOM_RANGE'),
    trendSpan: undefined,
    defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.DAYS] }),
  };
  public lastFourWeeksOption: TrendSpanOption = {
    label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_LAST_28_DAYS'),
    trendSpan: new TrendSpan({ duration: 28, unit: ChronoUnit[ChronoUnit.DAYS] }),
    defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.DAYS] }),
  };
  public trendSpanOptions: TrendSpanOption[];
  public defaultTrendSpanOptions: TrendSpanOption[] = [
    {
      label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_LAST_4_HOURS'),
      trendSpan: new TrendSpan({ duration: 4, unit: ChronoUnit[ChronoUnit.HOURS] }),
      defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.HOURS] }),
    },
    {
      label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_LAST_12_HOURS'),
      trendSpan: new TrendSpan({ duration: 12, unit: ChronoUnit[ChronoUnit.HOURS] }),
      defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.HOURS] }),
    },
    {
      label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_LAST_24_HOURS'),
      trendSpan: new TrendSpan({ duration: 24, unit: ChronoUnit[ChronoUnit.HOURS] }),
      defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.HOURS] }),
    },
    {
      label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_LAST_7_DAYS'),
      trendSpan: new TrendSpan({ duration: 7, unit: ChronoUnit[ChronoUnit.DAYS] }),
      defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.DAYS] }),
    },
    {
      label: this.i18nService.translate('WIDGET_CUSTOMIZE.WIDGET_CHART_TREND_LAST_14_DAYS'),
      trendSpan: new TrendSpan({ duration: 14, unit: ChronoUnit[ChronoUnit.DAYS] }),
      defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.DAYS] }),
    },
    this.lastFourWeeksOption,
    this.customTrendSpanOption,
  ];

  /**
   * constructor
   * @param {I18NService} i18nService
   * @memberof TrendDateRangeComponent
   */
  constructor(private i18nService: I18NService) {}

  /**
   * ngOnInit
   * @memberof TrendDateRangeComponent
   */
  public ngOnInit() {
    this.trendSpanOptions = this.getFilteredTrendSpanOptions();
    // Converting days into millis and subtracting one minute to make sure user is not able to select extra day
    this.maxRangeInMillis = this.maxCustomRange
      ? this.maxCustomRange * AppConstants.ONE_DAY_IN_MILLIS - AppConstants.ONE_MINUTE_IN_MILLIS
      : undefined;
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes [description]
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (this.overrideDefaultTrendSpanOptions.length) {
      this.defaultTrendSpanOptions = this.overrideDefaultTrendSpanOptions;
    }
    // Set validation for Custom trendSpan on initial load
    if (this.trendDateRange && this.trendDateRange.trendSpan === undefined) {
      this.startDate = new Date(this.trendDateRange.startDateMillis);
      this.endDate = new Date(this.trendDateRange.endDateMillis);
      this.defaultCustomDateRange = [this.startDate, this.endDate];
    }
    if (changes.maxCustomRange && this.maxCustomRange) {
      this.maxRangeInMillis = this.maxCustomRange * AppConstants.ONE_DAY_IN_MILLIS;
    }
    if (changes.trendDateRange) {
      // cloning prevents modifying readonly store value
      this.trendDateRange = this.cloneTrendDateRange(changes.trendDateRange.currentValue);

      const matchingTrendSpanOption = this.findTrendSpanOption(this.trendDateRange.trendSpan);
      if (matchingTrendSpanOption) {
        this.setSelectedTrendSpanOption(matchingTrendSpanOption);
      } else if (this.trendDateRange.startDateMillis) {
        this.setSelectedTrendSpanOption(this.customTrendSpanOption);
      } else {
        this.selectedTrendSpanOption = undefined;
      }
    }
  }

  /**
   * getFilteredTrendSpanOptions
   * removes the customTrendSpanOption if isCustomTrendSpanOptionRequired=false
   * @returns {TrendSpanOption[]}
   * @memberof TrendDateRangeComponent
   */
  public getFilteredTrendSpanOptions(): TrendSpanOption[] {
    if (this.isCustomTrendSpanOptionRequired) {
      return this.defaultTrendSpanOptions;
    }

    return this.defaultTrendSpanOptions.filter((trendSpanOption: TrendSpanOption) => trendSpanOption !== this.customTrendSpanOption);
  }

  /**
   * onQueryChange
   * @param {string} query
   * @memberof TrendDateRangeComponent
   */
  public onQueryChange(query: string) {
    const parsed = parseInt(query?.match(/(\d+)/)?.[0], 10);
    this.trendSpanOptions = !Number.isInteger(parsed) ? this.getFilteredTrendSpanOptions() : this.getOptions(parsed);
  }

  /**
   * getOptions
   * @param {number} queryNum
   * @returns {TrendSpanOption[]}
   * @memberof TrendDateRangeComponent
   */
  public getOptions(queryNum: number): TrendSpanOption[] {
    return [
      {
        label: this.i18nService.translate('COMMON_MESSAGES.LAST_COUNT_HOUR_S', { count: queryNum }),
        trendSpan: new TrendSpan({ duration: queryNum, unit: ChronoUnit[ChronoUnit.HOURS] }),
        defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.HOURS] }),
      },
      {
        label: this.i18nService.translate('COMMON_MESSAGES.LAST_COUNT_DAY_S', { count: queryNum }),
        trendSpan: new TrendSpan({ duration: queryNum, unit: ChronoUnit[ChronoUnit.DAYS] }),
        defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.DAYS] }),
      },
      {
        label: this.i18nService.translate('COMMON_MESSAGES.LAST_COUNT_WEEK_S', { count: queryNum }),
        trendSpan: new TrendSpan({ duration: queryNum, unit: ChronoUnit[ChronoUnit.WEEKS] }),
        defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.WEEKS] }),
      },
      {
        label: this.i18nService.translate('COMMON_MESSAGES.LAST_COUNT_MONTH_S', { count: queryNum }),
        trendSpan: new TrendSpan({ duration: queryNum, unit: ChronoUnit[ChronoUnit.MONTHS] }),
        defaultSamplingFrequency: new TrendSpan({ duration: 1, unit: ChronoUnit[ChronoUnit.MONTHS] }),
      },
    ];
  }

  /**
   * cloneTrendDateRange
   * @param {TrendDateRange} trendDateRange
   * @returns {TrendDateRange}
   * @memberof TrendDateRangeComponent
   */
  public cloneTrendDateRange(trendDateRange: TrendDateRange): TrendDateRange {
    return Object.assign(
      new TrendDateRange(),
      trendDateRange,
      trendDateRange && {
        samplingFrequency: trendDateRange.samplingFrequency && Object.assign(new TrendSpan(), trendDateRange.samplingFrequency),
        trendSpan: trendDateRange.trendSpan && Object.assign(new TrendSpan(), trendDateRange.trendSpan),
      },
    );
  }

  /**
   * findTrendSpanOption
   * @param {TrendSpan} trendSpan
   * @returns {TrendSpanOption}
   * @memberof TrendDateRangeComponent
   */
  public findTrendSpanOption(trendSpan: TrendSpan): TrendSpanOption {
    if (!trendSpan?.duration) {
      return;
    }
    const defaultOption = this.searchOptions(this.defaultTrendSpanOptions, trendSpan);
    if (defaultOption) {
      return defaultOption;
    }
    return this.searchOptions(this.getOptions(trendSpan.duration), trendSpan);
  }

  /**
   * searchOptions
   * @param {any[]} options
   * @param {TrendSpan} trendSpan
   * @returns {TrendSpanOption}
   * @memberof TrendDateRangeComponent
   */
  public searchOptions(options: any[], trendSpan: TrendSpan): TrendSpanOption {
    return options.find((trendSpanOption: any) => {
      return (
        trendSpan &&
        trendSpanOption.trendSpan &&
        trendSpanOption.trendSpan.duration === trendSpan.duration &&
        trendSpanOption.trendSpan.unit === trendSpan.unit
      );
    });
  }

  /**
   * setSelectedTrendSpanOption
   * @param {TrendSpanOption} trendSpanOption
   * @memberof TrendDateRangeComponent
   */
  public setSelectedTrendSpanOption(trendSpanOption: TrendSpanOption) {
    this.selectedTrendSpanOption = trendSpanOption;
    this.trendDateRange.trendSpan = trendSpanOption.trendSpan;
    if (this.trendDateRange instanceof TrendDateRange) {
      this.trendDateRange.samplingFrequency = Object.assign(new TrendSpan(), trendSpanOption.defaultSamplingFrequency);
    }
    if (trendSpanOption === this.customTrendSpanOption) {
      this.trendDateRange.startDateMillis = this.trendDateRange.startDateMillis || this.sevenDaysAgo();
      this.trendDateRange.endDateMillis = this.trendDateRange.endDateMillis || this.getEndOfTodayTimestamp();

      this.defaultCustomDateRange = [
        this.getDateFromTimestamp(this.trendDateRange?.startDateMillis),
        this.getDateFromTimestamp(this.trendDateRange?.endDateMillis),
      ];
      if (this.trendDateRange instanceof TrendDateRange) {
        this.trendDateRange.useGeneratedSamplingFrequency();
      }
    }
  }

  /**
   * onTrendSpanOptionChange
   * @param {TrendSpanOption} trendSpanOption
   * @memberof TrendDateRangeComponent
   */
  public onTrendSpanOptionChange(trendSpanOption: TrendSpanOption) {
    if (!trendSpanOption) {
      return;
    }

    this.setSelectedTrendSpanOption(trendSpanOption);
    this.emitTrendDate();
  }

  /**
   * onCustomRangeChange
   * @param {Date[]} dates
   * @memberof TrendDateRangeComponent
   */
  public onCustomRangeChange(dates: Date | Date[]) {
    this.startDate = dates[0];
    this.endDate = dates[1];
    this.trendDateRange.startDateMillis = this.startDate.getTime();
    this.trendDateRange.endDateMillis = this.endDate.getTime();
    if (this.trendDateRange instanceof TrendDateRange) {
      this.trendDateRange.useGeneratedSamplingFrequency();
    }
    this.emitTrendDate();
  }

  /**
   * emitTrendDate
   * @memberof TrendDateRangeComponent
   */
  public emitTrendDate() {
    if (!this.trendDateRange.trendSpan && !(this.trendDateRange.startDateMillis && this.trendDateRange.endDateMillis)) {
      return;
    }
    if (this.trendDateRange.trendSpan) {
      this.trendDateRange.startDateMillis = undefined;
      this.trendDateRange.endDateMillis = undefined;
    }
    this.trendDateRangeUpdated.emit(this.trendDateRange);
  }

  /**
   * getEndOfTodayTimestamp
   * @memberof TrendDateRangeComponent
   * @returns {number}
   */
  public getEndOfTodayTimestamp(): number {
    return +moment().endOf('day');
  }

  /**
   * sevenDaysAgo
   * @memberof TrendDateRangeComponent
   * @returns {number}
   */
  public sevenDaysAgo(): number {
    return +moment().startOf('day').subtract(7, 'd');
  }

  /**
   * typeAheadformatter
   * @param {any} result
   * @returns {string}
   * @memberof TrendDateRangeComponent
   */
  public typeAheadformatter(result: any): string {
    return result.label || '';
  }

  /**
   * getDateFromTimestamp
   *
   * @param {number} timestamp
   * @returns {Date}
   * @memberof TrendDateRangeComponent
   */
  public getDateFromTimestamp(timestamp: number): Date {
    return new Date(timestamp);
  }
}
