/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

import { I18NService } from '@ws1c/intelligence-common/services/i18n.service';
import { DashboardConfig } from '@ws1c/intelligence-models';

/**
 * WidgetSubtypeNamePipe
 * @export
 * @class WidgetSubtypeNamePipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'widgetSubtypeName' })
export class WidgetSubtypeNamePipe implements PipeTransform {
  /**
   * Creates an instance of WidgetSubtypeNamePipe.
   * @param {I18NService} i18nService
   * @memberof WidgetSubtypeNamePipe
   */
  constructor(private i18nService: I18NService) {}

  /**
   * transform
   * @param {string} subtype
   * @returns {string}
   * @memberof WidgetSubtypeNamePipe
   */
  public transform(subtype: string): string {
    const subtypeKey = DashboardConfig.subtypeLabelKeysBySubtype[subtype];
    return subtypeKey ? this.i18nService.translate(subtypeKey) : subtype;
  }
}
