/*
 * Copyright 2022 VMware, Inc.
 * All rights reserved.
 */

import { GenericObject } from '@dpa/ui-common';

/**
 * Replaces template variables in an object with given params
 *
 * @param {GenericObject} data
 * @param {GenericObject} params
 * @returns {GenericObject}
 */
export const replaceTemplateParams = (data: GenericObject, params: GenericObject): GenericObject => {
  let dataStr = JSON.stringify(data);
  Object.entries(params).forEach(([key, value]: string[]) => (dataStr = dataStr.split(`\${${key}}`).join(value)));
  return JSON.parse(dataStr);
};
