/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { AccountRole } from '@ws1c/intelligence-models';

/**
 * RoleListItemsOverflowComponent
 * @export
 * @class RoleListItemsOverflowComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-role-list-items-overflow',
  templateUrl: 'role-list-items-overflow.component.html',
  styleUrls: ['role-list-items-overflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleListItemsOverflowComponent implements OnChanges {
  @Input() public roles: AccountRole[] = [];

  public overflowTooltip: string;

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof RoleListItemsOverflowComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.roles) {
      this.overflowTooltip = changes.roles?.currentValue?.map((role: AccountRole) => role.label).join(', ');
    }
  }

  /**
   * labelFormatter
   * @param {AccountRole} accountRole
   * @returns {string}
   * @memberof RoleListItemsOverflowComponent
   */
  public labelFormatter(accountRole: AccountRole): string {
    return accountRole.label;
  }
}
