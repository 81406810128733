/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { ScoreUnit } from '@ws1c/intelligence-models';

export const UNIT_DECORATOR_CONFIG = {
  [ScoreUnit.PERCENTAGE]: '%',
  [ScoreUnit.SECONDS]: 's',
  [ScoreUnit.MILLIS]: 'ms',
  [ScoreUnit.MBPS]: 'Mbps',
  [ScoreUnit.FAHRENHEIT]: '\u00B0F',
  [ScoreUnit.CELSIUS]: '\u00B0C',
};
