/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component } from '@angular/core';

/**
 * CardFooterButtonComponent
 * @export
 * @class CardFooterButtonComponent
 */
@Component({
  selector: 'dpa-card-footer-button',
  templateUrl: 'card-footer-button.component.html',
})
export class CardFooterButtonComponent {}
