/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { AppConfig } from '@ws1c/intelligence-common/constants';
import { Comment, UserDetails } from '@ws1c/intelligence-models';

/**
 * SolutionCommentComponent
 * @export
 * @class SolutionCommentComponent
 * @implements {OnChanges}
 */
@Component({
  selector: 'dpa-solution-comment',
  templateUrl: 'solution-comment.component.html',
  styleUrls: ['solution-comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SolutionCommentComponent implements OnChanges {
  public APP_CONFIG = AppConfig;
  @Input() public avatarUrl: string;
  @Input() public commentId: string;
  @Input() public enableActions: boolean = false;
  @Input() public lastUpdatedAt: number;
  @Input() public text: string;
  @Input() public userDetails: UserDetails;
  @Input() public showEditDeleteConfirmModal: boolean = true;
  @Input() public isEditingComment: boolean = false;
  @Input() public isDeletingComment: boolean = false;
  @Input() public editingComment: Comment;
  @Input() public deletingComment: Comment;
  @Input() public isSavingEditComment: boolean = false;
  @Output() public editClick: EventEmitter<string> = new EventEmitter();
  @Output() public deleteClick: EventEmitter<string> = new EventEmitter();
  @Output() public userClick: EventEmitter<UserDetails> = new EventEmitter();
  @Output() public cancelDeleteCommentClick: EventEmitter<any> = new EventEmitter();
  @Output() public confirmDeleteCommentClick: EventEmitter<any> = new EventEmitter();
  @Output() public cancelEditCommentClick: EventEmitter<any> = new EventEmitter();
  @Output() public saveEditCommentClick: EventEmitter<any> = new EventEmitter();

  public editCommentForm: UntypedFormGroup;

  /**
   * Creates an instance of SolutionCommentComponent.
   * @param {UntypedFormBuilder} fb
   * @memberof SolutionCommentComponent
   */
  constructor(private fb: UntypedFormBuilder) {
    this.editCommentForm = this.fb.group({
      text: [{ value: '', required: true }],
    });
  }

  /**
   * ngOnChanges
   * @param {SimpleChanges} changes
   * @memberof SolutionCommentComponent
   */
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.editingComment?.currentValue?.text) {
      this.editCommentForm.patchValue({
        text: changes.editingComment.currentValue.text,
      });
    }
  }

  /**
   * onEditClick
   * @memberof SolutionCommentComponent
   */
  public onEditClick() {
    if (!this.enableActions) {
      return;
    }
    this.editClick.emit(this.commentId);
  }

  /**
   * onDeleteClick
   * @memberof SolutionCommentComponent
   */
  public onDeleteClick() {
    if (!this.enableActions) {
      return;
    }
    this.deleteClick.emit(this.commentId);
  }

  /**
   * onUserClick
   * @memberof SolutionCommentComponent
   */
  public onUserClick() {
    this.userClick.emit(this.userDetails);
  }

  /**
   * onCancelDelete
   * @memberof SolutionCommentComponent
   */
  public onCancelDelete() {
    this.cancelDeleteCommentClick.emit();
  }

  /**
   * onConfirmDelete
   * @memberof SolutionCommentComponent
   */
  public onConfirmDelete() {
    this.confirmDeleteCommentClick.emit();
  }

  /**
   * onCancelEdit
   * @memberof SolutionCommentComponent
   */
  public onCancelEdit() {
    this.cancelEditCommentClick.emit();
  }

  /**
   * onSaveEdit
   * @memberof SolutionCommentComponent
   */
  public onSaveEdit() {
    const payload = this.editCommentForm.getRawValue();
    this.saveEditCommentClick.emit(payload);
  }

  /**
   * isActionButtonsVisible
   * @returns {boolean}
   * @memberof SolutionCommentComponent
   */
  public isActionButtonsVisible(): boolean {
    if (!this.enableActions) {
      return false;
    }
    if (this.isDeletingComment && this.deletingComment.id === this.commentId) {
      return false;
    }
    if (this.isEditingComment && this.editingComment.id === this.commentId) {
      return false;
    }
    return true;
  }
}
