/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { Component, Input } from '@angular/core';

import { CLARITY_TOOLTIP_POSITION, DropdownOption } from '@ws1c/intelligence-models';

/**
 * CardFooterDropdownComponent
 * @export
 * @class CardFooterDropdownComponent
 */
@Component({
  selector: 'dpa-card-footer-dropdown',
  templateUrl: 'card-footer-dropdown.component.html',
})
export class CardFooterDropdownComponent {
  @Input() public dropdownOptions?: DropdownOption[];

  public readonly CLARITY_TOOLTIP_POSITION = CLARITY_TOOLTIP_POSITION;
}
