/*
 * Copyright 2021 VMware, Inc.
 * All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';

/**
 * UrlHostPipe
 * @export
 * @class UrlHostPipe
 * @implements {PipeTransform}
 */
@Pipe({ name: 'urlHost' })
export class UrlHostPipe implements PipeTransform {
  /**
   * transform
   * @param {string} url
   * @returns {string}
   */
  public transform(url: string): string {
    if (!url) {
      return '';
    }
    const urlObject: URL = new URL(url);
    return urlObject.origin;
  }
}
