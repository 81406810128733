/*
 * Copyright 2019 VMware, Inc.
 * All rights reserved.
 */

import { CardBlockRowComponent } from './card-block/card-block-row/card-block-row.component';
import { CardBlockComponent } from './card-block/card-block.component';
import { CardBlockHeaderComponent } from './card-block-header/card-block-header.component';
import { CardBodyComponent } from './card-body/card-body.component';
import { CardFooterButtonComponent } from './card-footer/card-footer-button/card-footer-button.component';
import { CardFooterDropdownComponent } from './card-footer/card-footer-dropdown/card-footer-dropdown.component';
import { CardFooterComponent } from './card-footer/card-footer.component';
import { CardIconComponent } from './card-icon/card-icon.component';
import { CardComponent } from './card.component';

export const CARD_COMPONENTS: any[] = [
  CardComponent,
  CardBlockComponent,
  CardBlockRowComponent,
  CardBodyComponent,
  CardFooterComponent,
  CardFooterButtonComponent,
  CardFooterDropdownComponent,
  CardIconComponent,
  CardBlockHeaderComponent,
];

export * from './card-block-header/card-block-header.component';
export * from './card-block/card-block-row/card-block-row.component';
export * from './card-block/card-block.component';
export * from './card-body/card-body.component';
export * from './card-footer/card-footer-button/card-footer-button.component';
export * from './card-footer/card-footer-dropdown/card-footer-dropdown.component';
export * from './card-footer/card-footer.component';
export * from './card-icon/card-icon.component';
export * from './card.component';
