<div *ngIf="dataAccessPolicies?.length > 0">
  <ng-container *ngLet="dataAccessPolicies[0].isDefaultPolicyAssigned(defaultPermitAllSystemId) as isDefaultPolicyAssigned">
    <span [class.gray-out]="isDefaultPolicyAssigned">
      {{ isDefaultPolicyAssigned ? ('ADMINISTRATOR.PERMIT_ALL' | translate) : dataAccessPolicies[0].name }}
    </span>
    <dpa-tooltip
      *ngIf="isDefaultPolicyAssigned"
      [tooltipText]="'ADMINISTRATOR.PERMIT_ALL_INFO' | translate"
      class="ml-x1"
    >
      <cds-icon
        shape="info-circle"
        size="20"
      ></cds-icon>
    </dpa-tooltip>
  </ng-container>
</div>
