/*
 * Copyright 2023 VMware, Inc.
 * All rights reserved.
 */

import { MarketplaceFilterTagsComponent } from './marketplace-filter-tags/marketplace-filter-tags.component';

export const MARKETPLACE_COMPONENTS: any[] = [MarketplaceFilterTagsComponent];

export * from './marketplace-filter-tags/marketplace-filter-tags.component';
