/*
 * Copyright 2020 VMware, Inc.
 * All rights reserved.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DateTimeFormat } from '@dpa/ui-common';

import { UserDetails } from '@ws1c/intelligence-models';

/**
 * OwnerInfoTagComponent
 * @export
 * @class OwnerInfoTagComponent
 */
@Component({
  selector: 'dpa-owner-info-tag',
  templateUrl: 'owner-info-tag.component.html',
  styleUrls: ['owner-info-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OwnerInfoTagComponent {
  @Input() public createdByDetails: UserDetails;
  @Input() public entityType: string;
  @Input() public entityName: string;
  @Input() public showOwnerText?: boolean = false;
  @Input() public createdAt?: number;

  public readonly DateTimeFormat = DateTimeFormat;
}
